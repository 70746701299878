import React from "react"
import { useDirtyContext } from "../../../../packages/editing/DirtyContext"
import { useEditableContext } from "../../../../packages/editing/EditableContext"
import { EditableSections } from "../../../../packages/editing/EditableSections"
import { colors } from "../colors"
import { usePageContext } from "../Page"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Section } from "../../../../packages/editing/Section"

Section(VenueNews)
function VenueNews(section: {}) {
    const page = usePageContext()
    const { venue } = page
    const localize = useLocalize()
    const { editing } = useEditableContext()
    const { setDirty } = useDirtyContext()

    return (
        <div
            style={
                editing
                    ? { padding: 16, margin: 16, border: `1px dashed ${colors.darkPurple}` }
                    : undefined
            }
        >
            {editing ? (
                <div style={{ fontSize: 10, color: colors.purple }}>
                    {venue ? (
                        <>Venue sections for {localize(venue.name)}</>
                    ) : (
                        <>Landing page sections</>
                    )}
                </div>
            ) : undefined}
            {editing ? (
                <button
                    onClick={() => {
                        page.contextSections = []
                        setDirty()
                    }}
                >
                    Add news sections for {venue ? localize(venue.name) : "landing page"}
                </button>
            ) : undefined}

            {page.contextSections ? (
                <EditableSections sections={page.contextSections} />
            ) : undefined}
        </div>
    )
}
