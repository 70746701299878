import React from "react"
import { Link } from "react-router-dom"
import { EditableText } from "../../../../packages/editing/EditableText"
import { Localized } from "../../../../packages/localization/Localized"
import { FileToUrl, Image, ImageToUrl, Video } from "../../../../reactor/Types/File"
import { colors } from "../colors"
import { usePageContext } from "../Page"
import { CallToAction } from "./TextAndCallToAction"
import { Section } from "../../../../packages/editing/Section"

Section(MediaAndCallToAction)
function MediaAndCallToAction(section: {
    video?: Video
    image?: Image
    videoSource?: "Venue promo video"
    minHeight?: number
    title: Localized<string>
    callToAction: Localized<string>
    callToActionLink: string
}) {
    const { venue, locale } = usePageContext()

    return (
        <div
            style={{
                backgroundColor: colors.darkPurple,
                minHeight: section.minHeight ?? 500,
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 2,
                }}
            >
                <h1
                    style={{
                        color: "white",
                        maxWidth: "75%",
                        textShadow: "0px 1px 4px rgba(0, 0, 0, 0.35)",
                        textAlign: "center",
                    }}
                >
                    <EditableText obj={section} prop="title" defaultText="Say something here" />
                </h1>

                <Link
                    to={
                        section.callToActionLink.startsWith("https://")
                            ? section.callToActionLink
                            : `/${locale}/${venue ? venue.slug + "/" : ""}${
                                  section.callToActionLink
                              }`
                    }
                >
                    {section.callToAction && (
                        <CallToAction
                            align="flex-start"
                            section={section as { callToAction: Localized<string> }}
                            prop="callToAction"
                        />
                    )}
                </Link>
            </div>

            {section.image && (
                <img
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        objectFit: "cover",
                    }}
                    src={ImageToUrl(section.image, { width: 1440 })}
                />
            )}

            {section.video && (
                <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        objectFit: "cover",
                    }}
                    src={
                        section.videoSource === "Venue promo video" && venue && venue.promoVideo
                            ? FileToUrl(venue.promoVideo as any)
                            : FileToUrl(section.video as any)
                    }
                />
            )}
        </div>
    )
}
