export type TypeValidator<T> = (value: any) => T

/** Registers a type validator function.
 *
 *  See `docs/Type validators.md`
 */
export function TypeValidator<T>(func: TypeValidator<T>) {
    TypeValidator.functions.push(func)
}
TypeValidator.functions = [] as TypeValidator<any>[]
