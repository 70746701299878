import { IsNode } from "../../reactor/AssertNode"
import { SSRHeader } from "./SSRHeader"

export function useCanonicalLink(url?: string) {
    if (!url) return
    if (IsNode()) {
        SSRHeader("canonical", `<link rel="canonical" href="${url}">`)
    } else {
        // Get link rel="canonical" tag from the document's head
        const canonicalLink = document.head.querySelectorAll('link[rel="canonical"]')[0]
        if (canonicalLink) {
            // Check if we need to make any updates
            const href = canonicalLink.getAttribute("href")

            if (href !== url) {
                canonicalLink.setAttribute("href", url)
            }
        }
    }
}
