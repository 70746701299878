import { IsNode } from "../../reactor/AssertNode"
import { SSRHeader } from "./SSRHeader"

/**
 * Sets the page title in the HTML body.
 *
 * In browser mode, this sets the document title.
 *
 * This should only be called once per page, at the appropriate level in the
 * component tree, typically the `<Page />` component or equivalent.
 */
export function usePageTitle(title: string) {
    if (IsNode()) {
        if (!ssrTransaction.macros) {
            throw new Error("No SSR transaction active - unable to add structured data.")
        }
        SSRHeader("page-title", `<title>${title}</title>`)
    } else {
        document.title = title
    }
}
