import { RangedNumber } from "../Opaque"
import { TypeValidator } from "./TypeValidator"

export type Percent<
    minValue extends number | undefined = undefined,
    maxValue extends number | undefined = undefined,
> = RangedNumber<"Percent", minValue, maxValue>
TypeValidator(Percent)
export function Percent<minValue extends number | undefined, maxValue extends number | undefined>(
    pct: number,
    minValue?: minValue,
    maxValue?: maxValue
): Percent<minValue, maxValue> {
    return pct as any
}
