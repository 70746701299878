import { OpaqueString } from "../Opaque"
import { TypeValidator } from "./TypeValidator"

/** Represents string that may not contain any newline, tab or carriage return characters.
 *
 *  Analogous to XSD tokens: http://books.xmlschemata.org/relaxng/ch19-77319.html
 */
export type Token = OpaqueString<"Token">

TypeValidator(Token)
/** Converts a string to a Token.
 *
 *  Replaces sequences of newlines, tabs carriage returns with a single space.
 */
export function Token(token: string): Token {
    let res = ""
    let run = false

    for (const c of token) {
        if (c === "\t" || c === "\n" || c === "\r") {
            if (!run) {
                run = true
                res += " "
            }
        } else {
            run = false
            res += c
        }
    }

    return res as any
}
