import React from "react"
import { EditableText } from "../../../../../packages/editing/EditableText"
import { colors } from "../../colors"
import { DatePicker } from "./DatePicker"
import { usePartyContext } from "./PartyContext"
import { usePageContext } from "../../Page"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { VenuePicker } from "../Menu"
import { CallToAction } from "../TextAndCallToAction"
import { useNavigate } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { Breadcrumbs } from "./Breadcrumbs"

export function StartPage() {
    const { page, venue } = usePageContext()
    const localize = useLocalize<string>()
    const { section, venues, minimumGuests, guestCount, setGuestCount, date, setDate } =
        usePartyContext()
    const navigate = useNavigate()

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflowX: "clip",
                marginBottom: 64,
            }}
        >
            <div
                style={{
                    width: "100%",
                    maxWidth: 1024,
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 32,
                    marginRight: 32,
                    position: "relative",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyItems: "center",
                        alignItems: "center",
                        marginTop: 32,
                    }}
                >
                    <h1 style={{ marginBottom: 16, textAlign: "center" }}>
                        <EditableText
                            obj={section}
                            prop="headerText"
                            defaultText="It's Party Time!"
                            isLocalized={true}
                        />
                    </h1>
                    <div style={{ textAlign: "center" }}>
                        <EditableText
                            obj={section}
                            prop="headerSubtext"
                            defaultText="We look forward to welcoming you!"
                            isLocalized={true}
                        />
                    </div>
                    <Breadcrumbs page={"start"} />
                </div>
                <Row>
                    <Col style={{ paddingTop: 16, maxWidth: 500 }}>
                        <div
                            style={{
                                margin: 32,
                                paddingLeft: 64,
                                paddingRight: 64,
                                paddingTop: 16,
                                paddingBottom: 16,
                                borderRadius: 32,
                                backgroundColor: colors.darkPurple,
                                color: "white",
                                display: "flex",
                                flexDirection: "column",
                                justifyItems: "center",
                                alignItems: "center",
                            }}
                        >
                            <VenuePicker
                                section={section}
                                prefix={localize(section.buttonPrefix)}
                                slug={page.slugs[0] ?? "party"}
                                options={venues}
                                current={venues.find((v) => v.id === venue?.id)}
                            />
                        </div>
                        {venue && (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        position: "relative",
                                        marginLeft: 32,
                                        marginRight: 32,
                                        flexDirection: "column",
                                    }}
                                >
                                    <Row style={{ marginTop: 32 }}>
                                        <Col style={{ maxWidth: 100 }}>
                                            <h3>
                                                <EditableText
                                                    obj={section}
                                                    prop="guestsText"
                                                    defaultText="Guests"
                                                    isLocalized={true}
                                                />
                                            </h3>
                                        </Col>
                                        <Col>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    color: colors.grey5,
                                                    justifyContent: "flex-end",
                                                    flexDirection: "column",
                                                    height: "100%",
                                                    paddingBottom: 14,
                                                }}
                                            >
                                                <EditableText
                                                    obj={section}
                                                    prop="guestsExplanation"
                                                    defaultText="Number of children"
                                                    isLocalized={true}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div
                                    style={{
                                        backgroundColor: "white",
                                        textAlign: "center",
                                        fontSize: 28,
                                        marginLeft: 32,
                                        marginRight: 32,
                                        borderRadius: 64,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        onClick={() =>
                                            setGuestCount(Math.max(guestCount - 1, minimumGuests))
                                        }
                                        style={{
                                            width: 48,
                                            height: 48,
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                            borderRadius: 48,
                                            backgroundColor:
                                                guestCount === minimumGuests
                                                    ? colors.grey5
                                                    : colors.purple,
                                            color: "white",
                                            cursor: "pointer",
                                        }}
                                    >
                                        -
                                    </div>
                                    <div className="bold" style={{ flex: 1 }}>
                                        {guestCount}
                                    </div>
                                    <div
                                        onClick={() => setGuestCount(guestCount + 1)}
                                        style={{
                                            width: 48,
                                            height: 48,
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                            borderRadius: 48,
                                            backgroundColor: colors.purple,
                                            color: "white",
                                            cursor: "pointer",
                                        }}
                                    >
                                        +
                                    </div>
                                </div>
                                <div style={{ alignSelf: "center", marginTop: 40 }}>
                                    <CallToAction
                                        disabled={!date}
                                        disabledReason={
                                            !date ? "Please select a date first" : undefined
                                        }
                                        section={section}
                                        prop="callToActionOne"
                                        onClick={() => navigate("./room-and-time")}
                                        textStyle={{
                                            width: "86%",
                                            textAlign: "center",
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </Col>
                    <Col>{venue && <DatePicker date={date} setDate={setDate} />}</Col>
                </Row>
            </div>
        </div>
    )
}
