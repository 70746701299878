import moment from "moment"
import { OpaqueString } from "../Opaque"
import { TypeValidator } from "./TypeValidator"

/**
 * A time string of the form `HH:mm` (24-hour), representing a time of day in
 * the local time zone.
 */
export type LocalTime = OpaqueString<"LocalTime">

TypeValidator(LocalTime)
/**
 * Verifies that the provided string is a valid LocalTime.
 */
export function LocalTime(time: string): LocalTime {
    if (!/^\d{2}:\d{2}$/.test(time)) {
        throw new Error(`Expected local time in HH:mm format`)
    }
    const [hours, minutes] = time.split(":").map(Number)
    if (hours < 0 || hours > 23) {
        throw new Error(`Hours must be 0-24, got: ${hours}`)
    }
    if (minutes < 0 || minutes > 59) {
        throw new Error(`Minutes must be 0-59, got: ${minutes}`)
    }
    return time as any
}

LocalTime.fromLocalDateTime = function (localDateTime: LocalDateTime): LocalTime {
    const validated = LocalDateTime(localDateTime.valueOf())
    return LocalTime(validated.split("T")[1])
}

/**
 * A date string of the form `YYYY-MM-DD`, representing a date in the local
 * time zone.
 */
export type LocalDate = OpaqueString<"LocalDate">

TypeValidator(LocalDate)
/**
 * Verifies that the provided string is a valid LocalDate.
 */
export function LocalDate(date: string | moment.Moment): LocalDate {
    if (typeof date !== "string") {
        date = date.format("YYYY-MM-DD")
    }

    function isDateValid(y: number, m: number, d: number): boolean {
        const dateObj = new Date(y, m - 1, d)
        const isValid =
            dateObj.getFullYear() === y && dateObj.getMonth() === m - 1 && dateObj.getDate() === d
        return isValid
    }

    if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) {
        throw new Error(`Expected local time in YYYY-MM-DD format`)
    }
    if (isNaN(Date.parse(date))) {
        throw new Error(`Invalid date`)
    }
    const [year, month, day] = date.split("-").map(Number)
    if (!isDateValid(year, month, day)) {
        throw new Error(`Invalid date`)
    }
    return date as any
}

LocalDate.fromDate = function (date: Date, timeZone: string): LocalDate {
    // Formats it as YYYY-MM-DD
    return LocalDate(moment(date, timeZone).format("YYYY-MM-DD"))
}

LocalDate.fromLocalDateTime = function (localDateTime: LocalDateTime): LocalDate {
    const validated = LocalDateTime(localDateTime.valueOf())
    return LocalDate(validated.split("T")[0])
}

export type LocalDateRange = {
    from: LocalDate
    to: LocalDate
}

TypeValidator(LocalDateRange)
/**
 * Verifies that the provided LocalDateRange is valid.
 *
 * The 'to' date must be after the 'from' date.
 */
export function LocalDateRange(ldr: LocalDateRange) {
    if (ldr.from > ldr.to) {
        throw new Error(`'to' must be after 'from'`)
    }
    return ldr
}

/**
 * A date string of the form `YYYY-MM-DDTHH:mm`, representing a date in a local
 * time zone understood from the context.
 */
export type LocalDateTime = OpaqueString<"LocalDateTime">

export function LocalDateTime(date: string): LocalDateTime {
    if (!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(date)) {
        throw new Error(`Expected local time in YYYY-MM-DDTHH:mm format`)
    }
    if (isNaN(Date.parse(date))) {
        throw new Error(`Invalid date`)
    }
    return date as any
}
