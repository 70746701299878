import { createContext, useContext } from "react"
import { LocaleKey } from "../../../../../packages/localization/Locale"
import { Localized } from "../../../../../packages/localization/Localized"
import { Email, PhoneNumber } from "../../../../../reactor/Types/Primitives"
import { DateString } from "../../../../../reactor/Types/Primitives/DateTime"
import { Uuid } from "../../../../../reactor/Types/Primitives/Uuid"
import { PartyExtra } from "../../../model/PartyExtra"
import { MinuteOfDay } from "../../../primitives/TimeSlot"
import type { PartyRoomByVenue, PartyMeal, PartyPackageByVenue } from "../../client"
import { PartyBooker } from "../PartyBooker"

export type PartyVenue = {
    name: Localized<string>
    country: string
    id: Uuid<"Venue">
    slug: string
    locale: LocaleKey
    link: string
}

export type Party = {
    /** The webpage section containing the party booker configuration */
    section: PartyBooker
    minimumGuests: number
    guestCount: number
    setGuestCount: (guestCount: number) => void
    date?: Date
    setDate: (date: Date | undefined) => void
    partyPackage?: PartyPackageByVenue
    setPartyPackage: (partyPackage: PartyPackageByVenue, mealOption?: PartyMeal) => void
    mealOption?: PartyMeal
    venues: PartyVenue[]
    room?: PartyRoomByVenue
    roomName?: string
    time?: MinuteOfDay
    /** How many of each extra (by ID) has been added to the order. */
    extras?: { [id: string]: number }
    setExtra(id: PartyExtra["id"], quantity: number): void
    setExtras(extras: { [id: string]: number } | undefined): void
    setRoomAndTime(room: PartyRoomByVenue, roomName: string, time: MinuteOfDay): void

    birthDate: DateString | undefined
    setBirthDate: (date: DateString | undefined) => void
    setNameOfTheBirthdayChild: (name: string) => void
    nameOfTheBirthdayChild: string

    firstName: string | undefined
    setFirstName: (name: string | undefined) => void
    lastName: string | undefined
    setLastName: (name: string | undefined) => void
    email: Email | undefined
    setEmail: (email: Email | undefined) => void
    phoneNumber: PhoneNumber | undefined
    setPhoneNumber: (phoneNumber: PhoneNumber | undefined) => void
    notes: string | undefined
    setNotes: (notes: string | undefined) => void

    acceptedTOC: boolean
    setAcceptedTOC: (accepted: boolean) => void
}
export const PartyContext = createContext<Party | undefined>(undefined)

export function usePartyContext() {
    const party = useContext(PartyContext)
    if (!party) {
        throw new Error("PartyContext not set")
    }
    return party
}
