import { Localized } from "../Localized"
import { Locale, LocaleKey } from "../Locale"
import { getStudioLocale } from "./useStudioLocale"
import { lowercaseFirstLetter } from "../../../reactor"

export const Strings = Dictionary({
    Edit: { no: "Rediger", sv: "Redigera" },
    "Done editing": { no: "Ferdig med redigering", sv: "Klar med redigering" },
    Show: { no: "Vis", sv: "Visa" },
    Hide: { no: "Skjul", sv: "Dölj" },
    More: { no: "Mer", sv: "Mer" },
    Less: { no: "Mindre", sv: "Mindre" },
    Previous: { no: "Forrige", sv: "Föregående" },
    Next: { no: "Neste", sv: "Nästa" },
    Search: { no: "Søk", sv: "Sök" },
    matching: { no: "matcher", sv: "matchar" },
    "Order by": { no: "Sorter etter", sv: "Sortera efter" },
    ascending: { no: "stigende", sv: "stigande" },
    descending: { no: "synkende", sv: "synkande" },
    Ascending: { no: "Stigende", sv: "Stigande" },
    Descending: { no: "Synkende", sv: "Synkande" },
    "per page": { no: "per side", sv: "per sida" },
    "Last modified date": { no: "Sist endret", sv: "Senast ändrad" },
    "Created date": { no: "Opprettet", sv: "Skapad" },
    Download: { no: "Last ned", sv: "Ladda ner" },
    "Download as .JSON file": { no: "Last ned som .JSON-fil", sv: "Ladda ner som .JSON-fil" },
    "Download as .CSV file": { no: "Last ned som .CSV-fil", sv: "Ladda ner som .CSV-fil" },
    "Please enter a search string...": {
        no: "Skriv inn søkestreng...",
        sv: "Skriv in söksträng...",
    },
    "Please specify": {
        no: "Vennligst spesifiser",
        sv: "Vänligen specificera",
    },
    Replace: {
        no: "Erstatt",
        sv: "Ersätt",
    },
    Cancel: {
        no: "Avbryt",
        sv: "Avbryt",
    },
    Delete: {
        no: "Slett",
        sv: "Ta bort",
    },
    Duplicate: {
        no: "Dupliser",
        sv: "Duplicera",
    },
    Export: {
        no: "Eksporter",
        sv: "Exportera",
    },
    "Are you sure you want to continue?": {
        no: "Er du sikker på at du vil fortsette?",
        sv: "Är du säker på att du vill fortsätta?",
    },
    "Ignore and continue": {
        no: "Ignorer og fortsett",
        sv: "Ignorera och fortsätt",
    },
})

/** Translation functions for non-trivial words and phrases */
export const Translations = {
    Open() {
        switch (getStudioLocale()) {
            case "no":
                return "Åpne"
            case "sv":
                return "Öppna"
            default:
                return "Open"
        }
    },
    DeleteThis(x: Localized<string>) {
        switch (getStudioLocale()) {
            case "no":
                switch (x["no:genus"]) {
                    case "neuter":
                        return `Slett dette ${lowercaseFirstLetter(ClientSideLocalize(x))}et`
                    default:
                        return `Slett denne ${lowercaseFirstLetter(ClientSideLocalize(x))}en`
                }
            case "sv":
                switch (x["sv:genus"]) {
                    case "neuter":
                        return `Radera detta ${lowercaseFirstLetter(ClientSideLocalize(x))}`
                    default:
                        return `Radera denna ${lowercaseFirstLetter(ClientSideLocalize(x))}`
                }
            default:
                return `Delete this ${lowercaseFirstLetter(ClientSideLocalize(x))}`
        }
    },
    SelectX(x: Localized<string>) {
        const lx = lowercaseFirstLetter(ClientSideLocalize(x))
        switch (getStudioLocale()) {
            case "no":
                switch (x["no:genus"]) {
                    case "neuter":
                        return `Velg et ${lx}`
                    default:
                        return `Velg en ${lx}`
                }
            case "sv":
                switch (x["sv:genus"]) {
                    case "neuter":
                        return `Välj et ${lx}`
                    default:
                        return `Välj en ${lx}`
                }
            default:
                return `Select a ${lx}`
        }
    },
    SelectXToRepalceY(x: Localized<string>, y: string) {
        const lx = lowercaseFirstLetter(ClientSideLocalize(x))
        switch (getStudioLocale()) {
            case "no":
                switch (x["no:genus"]) {
                    case "neuter":
                        return `Velg et ${lx} for å erstatte ${y}`
                    default:
                        return `Velg en ${lx} for å erstatte ${y}`
                }
            case "sv":
                switch (x["sv:genus"]) {
                    case "neuter":
                        return `Välj ett ${lx} för att ersätta ${y}`
                    default:
                        return `Välj en ${lx} för att ersätta ${y}`
                }
            default:
                return `Please specify a ${lx} to replace ${y}`
        }
    },
    DragAndDropAFileHereToInsert() {
        switch (getStudioLocale()) {
            case "no":
                return "Dra og slipp en fil her for å sette inn"
            case "sv":
                return "Dra och släpp en fil här för att sätta in"
            default:
                return "Drag and drop a file here to insert"
        }
    },
    LockedField() {
        switch (getStudioLocale()) {
            case "no":
                return "Låst felt"
            case "sv":
                return "Låst fält"
            default:
                return "Locked field"
        }
    },
    Add() {
        switch (getStudioLocale()) {
            case "no":
                return "Legg til"
            case "sv":
                return "Lägg till"
            default:
                return "Add"
        }
    },
    Change() {
        switch (getStudioLocale()) {
            case "no":
                return "Endre"
            case "sv":
                return "Ändra"
            default:
                return "Change"
        }
    },
    Specify() {
        switch (getStudioLocale()) {
            case "no":
                return "Velg"
            case "sv":
                return "Välj"
            default:
                return "Choose"
        }
    },
    Incomplete() {
        switch (getStudioLocale()) {
            case "no":
                return "Mangler utfylling"
            case "sv":
                return "Saknar ifyllnad"
            default:
                return "Incomplete"
        }
    },
    New(noun: Localized<any>, locale: LocaleKey | string = getStudioLocale()) {
        switch (locale) {
            case "no":
                switch (noun["no:genus"]) {
                    case "neuter":
                        return "Nytt"
                    default:
                        return "Ny"
                }
            case "sv":
                switch (noun["sv:genus"]) {
                    case "neuter":
                        return "Nytt"
                    default:
                        return "Ny"
                }
            default:
                return "New"
        }
    },
    Create(noun: Localized<any>, locale: LocaleKey | string = getStudioLocale()) {
        switch (locale) {
            case "no":
                return "Opprett"
            case "sv":
                return "Skapa"
            case "en":
                return "Create"
        }
    },
    Blank(noun: Localized<any>) {
        switch (getStudioLocale()) {
            case "no":
                switch (noun["no:genus"]) {
                    case "neuter":
                        return "Tomt"
                    default:
                        return "Tom"
                }
            case "sv":
                switch (noun["sv:genus"]) {
                    case "neuter":
                        return "Blankt"
                    default:
                        return "Blank"
                }
            case "da":
                switch (noun["da:genus"]) {
                    case "neuter":
                        return "Blankt"
                    default:
                        return "Blank"
                }
        }
        return "Blank"
    },
    ReplaceOnlyThisReference() {
        switch (getStudioLocale()) {
            case "no":
                return "Erstatt bare denne referansen"
            case "sv":
                return "Ersätt bara denna referens"
            default:
                return "Replace only this reference"
        }
    },
    ReplaceAllReferencesInDocument(old: string) {
        switch (getStudioLocale()) {
            case "no":
                return `Erstatt alle referanser til ${old} i dokumentet`
            case "sv":
                return `Ersätt alla referenser till ${old} i dokumentet`
            default:
                return `Replace all references to ${old} in document`
        }
    },
    ReplacedXReferences(count: number) {
        switch (getStudioLocale()) {
            case "no":
                return `Erstattet ${count} referanser`
            case "sv":
                return `Ersatte ${count} referenser`
            default:
                return `Replaced ${count} references`
        }
    },
    NoItemMatchesTheSearch(noun: string) {
        switch (getStudioLocale()) {
            case "no":
                return `Ingen ${noun} matcher søket`
            case "sv":
                return `Ingen ${noun} matchar söket`
            case "en":
                return `No ${noun} matches the search`
        }
    },

    NoItems(noun: string) {
        switch (getStudioLocale()) {
            case "no":
                return `Ingen ${noun}e${noun.endsWith("r") ? "" : "r"}`
            case "sv":
                return `Ingen ${noun}ar`
            case "en":
                return `No ${noun}s`
        }
    },

    ShowingXtoYofZ(x: number, y: number | undefined, z: number) {
        switch (getStudioLocale()) {
            case "no":
                return `Viser ${x} ${y !== undefined ? "- " + y : ""} av ${z}`
            case "sv":
                return `Visar ${x} ${y !== undefined ? "- " + y : ""} av ${z}`
            default:
                return `Showing ${x} ${y !== undefined ? "- " + y : ""} of ${z}`
        }
    },

    AreYouSureYouWantToDeleteThis(noun: Localized<string>) {
        switch (getStudioLocale()) {
            case "no":
                switch (noun["no:genus"]) {
                    case "neuter":
                        return `Er du sikker på at du vil slette dette ${ClientSideLocalize(
                            noun
                        )}et?`
                    default:
                        return `Er du sikker på at du vil slette denne ${ClientSideLocalize(
                            noun
                        )}en?`
                }
            case "sv":
                switch (noun["sv:genus"]) {
                    case "neuter":
                        return `Är du säker på att du vill ta bort detta ${ClientSideLocalize(
                            noun
                        )}?`
                    default:
                        return `Är du säker på att du vill ta bort denna ${ClientSideLocalize(
                            noun
                        )}?`
                }
            default:
                return `Are you sure you want to delete this ${ClientSideLocalize(noun)}?`
        }
    },

    AreYouSureYouWantToDeleteThisHistoryWillBeKept(noun: Localized<string>) {
        switch (getStudioLocale()) {
            case "no":
                switch (noun["no:genus"]) {
                    case "neuter":
                        return `Er du sikker på at du vil slette dette ${ClientSideLocalize(
                            noun
                        )}et, men beholde historikken? Dersom dokumentet inneholder persondata, bør du heller slette dokumentet permanent.`
                    default:
                        return `Er du sikker på at du vil slette denne ${ClientSideLocalize(
                            noun
                        )}en? Dersom dokumentet inneholder persondata, bør du heller slette dokumentet permanent.`
                }
            case "sv":
                switch (noun["sv:genus"]) {
                    case "neuter":
                        return `Är du säker på att du vill ta bort detta ${ClientSideLocalize(
                            noun
                        )}et, men behålla historiken? Om dokumentet innehåller personuppgifter bör du istället radera dokumentet permanent.`
                    default:
                        return `Är du säker på att du vill ta bort denna ${ClientSideLocalize(
                            noun
                        )}? Om dokumentet innehåller personuppgifter bör du istället radera dokumentet permanent.`
                }

            default:
                return `Are you sure you want to delete this ${ClientSideLocalize(
                    noun
                )}, but keep its event history? If the document contains personal data, you should permanently delete the document instead.`
        }
    },

    CanNotDeleteThis(noun: Localized<string>) {
        switch (getStudioLocale()) {
            case "no":
                switch (noun["no:genus"]) {
                    case "neuter":
                        return `Kan ikke slette dette ${ClientSideLocalize(noun)}et`
                    default:
                        return `Kan ikke slette denne ${ClientSideLocalize(noun)}en`
                }
            case "sv":
                switch (noun["sv:genus"]) {
                    case "neuter":
                        return `Kan inte ta bort detta ${ClientSideLocalize(noun)}`
                    default:
                        return `Kan inte ta bort denna ${ClientSideLocalize(noun)}`
                }
            default:
                return `Can not delete this ${ClientSideLocalize(noun)}`
        }
    },
    ShowAll() {
        switch (getStudioLocale()) {
            case "no":
                return "Vis alle"
            case "sv":
                return "Visa alla"
            default:
                return "Show all"
        }
    },
    Save() {
        switch (getStudioLocale()) {
            case "no":
                return "Lagre"
            case "sv":
                return "Spara"
            default:
                return "Save"
        }
    },
    Saved() {
        switch (getStudioLocale()) {
            case "no":
                return "Lagret"
            case "sv":
                return "Sparad"
            default:
                return "Saved"
        }
    },
    SaveAsTemplate() {
        switch (getStudioLocale()) {
            case "no":
                return "Lagre som mal"
            case "sv":
                return "Spara som mall"
            default:
                return "Save as template"
        }
    },
    Discard() {
        switch (getStudioLocale()) {
            case "no":
                return "Forkast"
            case "sv":
                return "Släng"
            default:
                return "Discard"
        }
    },
    SaveChanges() {
        switch (getStudioLocale()) {
            case "no":
                return "Lagre endringer"
            case "sv":
                return "Spara ändringar"
            default:
                return "Save changes"
        }
    },
    DiscardChanges() {
        switch (getStudioLocale()) {
            case "no":
                return "Forkast endringer"
            case "sv":
                return "Släng ändringar"
            default:
                return "Discard changes"
        }
    },
}

export function Dictionary<T extends { [key: string]: Localized<string> }>(
    strings: T
): { [key in keyof T]: string } {
    const res: any = {}
    for (const key in strings) {
        const value: any = strings[key]
        Object.defineProperty(res, key, {
            get() {
                return value[getStudioLocale()] ?? key
            },
        })
    }

    return res
}
/** The available locales for client-side localization. */
Dictionary.locales = [
    {
        flag: "🇬🇧",
        key: LocaleKey.en,
        displayName: { en: "English", no: "Engelsk", sv: "Engelska" },
    },
    {
        flag: "🇳🇴",
        key: LocaleKey.no,
        displayName: { en: "Norwegian", no: "Norsk", sv: "Norska" },
    },
    {
        flag: "🇸🇪",
        key: LocaleKey.sv,
        displayName: { en: "Swedish", no: "Svensk", sv: "Svenska" },
    },
] as Locale[]

Dictionary.chooseLocale = function <T>(
    value: Localized<T> | undefined,
    locale?: LocaleKey
): LocaleKey | undefined {
    locale ??= LocaleKey(getStudioLocale())
    if (value === undefined) return undefined
    if (value[locale as any]) {
        return locale
    }
    if (value.en) {
        return LocaleKey.en
    }
}

/** A client-side version of Localize(). */
export function ClientSideLocalize<T>(value: Localized<T>, locale?: LocaleKey): T
export function ClientSideLocalize<T>(
    value: Localized<T> | undefined,
    locale?: LocaleKey
): T | undefined
export function ClientSideLocalize<T>(value: Localized<T> | undefined, locale?: LocaleKey) {
    if (value === undefined) return undefined
    locale = Dictionary.chooseLocale(value, locale)
    if (locale) return value[locale.valueOf()]

    const keys = Object.keys(value)
        // Filter grammar metadata
        .filter((x) => !x.includes(":"))

    return value[keys[0]]
}
