import { OpaqueString } from "./Types/Opaque"

const handlePrefix = "©#"
function isHandle(s: unknown) {
    return typeof s === "string" && s.startsWith(handlePrefix)
}

export type SerializedGraph<T> = OpaqueString<"SerializedGraph">

export function SerializeGraph<T>(root: unknown): SerializedGraph<T> {
    const refCount = new Map<object | string, number>()

    function countRefs(obj: any) {
        if (obj === undefined) return
        if (obj === null) return

        if (typeof obj === "object") {
            const o = obj as object

            const ref = refCount.get(o)
            if (ref === undefined) {
                refCount.set(o, 1)

                if (obj instanceof Array) {
                    obj.forEach(countRefs)
                } else {
                    Object.keys(obj).forEach((key) => countRefs(obj[key]))
                }
            } else {
                refCount.set(o, ref + 1)
            }
        }
        // Internalize long strings
        if (typeof obj === "string" && obj.length > 7) {
            const ref = refCount.get(obj)
            if (ref === undefined) {
                refCount.set(obj, 1)
            } else {
                refCount.set(obj, ref + 1)
            }
        }
    }

    countRefs(root)

    const nodes = Array.from(refCount)
        .filter((x, i) => i === 0 || x[1] > 1)
        .map((x) => x[0])
    const ids = new Map<object | string, number>()
    nodes.forEach((x, i) => ids.set(x, i))

    function encode(this: any, key: any, value: any) {
        if (this === nodes) return value

        const id = ids.get(value)
        if (id !== undefined) {
            return handlePrefix + id
        }

        return value
    }

    return JSON.stringify(nodes, encode) as any as SerializedGraph<T>
}

export function DeserializeGraph<T>(graph: SerializedGraph<T>): T {
    const nodes = JSON.parse(graph.toString()) as any[]

    function revive(obj: any) {
        if (isHandle(obj)) {
            const index = parseInt(obj.substr(handlePrefix.length))
            return nodes[index]
        }
        if (obj instanceof Array) {
            for (let i = 0; i < obj.length; i++) {
                obj[i] = revive(obj[i])
            }
        } else if (obj instanceof Object) {
            for (const key of Object.keys(obj)) {
                obj[key] = revive(obj[key])
            }
        }
        return obj
    }

    nodes.forEach(revive)

    return nodes[0]
}

export function CloneGraph<T>(graph: T): T {
    if (typeof graph !== "object") return graph
    return DeserializeGraph(SerializeGraph(graph))
}
