import { ClientSideLocalize } from "./Dictionary"
import { LocaleKey } from "../Locale"
import { Localized } from "../Localized"
import { useContext } from "react"
import { CurrentLocaleContext } from "./CurrentLocaleContext"
import { Markdown } from "../../../reactor/Types/Primitives/Markdown"

export function useCurrentLocale() {
    return useContext(CurrentLocaleContext).locale
}

export function useLocalize<T = string | Markdown>() {
    const locale = useCurrentLocale()
    return <TValue = T>(value: Localized<TValue>, overrideLocale?: LocaleKey) =>
        ClientSideLocalize(value, overrideLocale ?? locale)
}
