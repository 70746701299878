// Adyen React Native App environments
// source: https://github.com/Adyen/adyen-react-native/blob/develop/src/Core/configuration.ts#L5
// Used as default Adyen environment
export type AppEnv = "test" | "live-eu" | "live-us" | "live-au" | "live-apse" | "live-in"

// Adyen Web environments
// source: https://github.com/Adyen/adyen-web/blob/main/packages/lib/src/core/types.ts#L21
export type WebEnv = "test" | "live" | "live-us" | "live-au" | "live-apse" | "live-in"

// Adyen API client environments
// source: https://github.com/Adyen/adyen-node-api-library/blob/main/src/typings/enums/environment.ts#L20
export type ApiClientEnv = "TEST" | "LIVE"

export function appEnvToWebEnv(environment: AppEnv): WebEnv {
    if ((environment ?? "").toLowerCase() === "live-eu") return "live"
    else if ((environment ?? "").toLowerCase().startsWith("live")) return environment as WebEnv
    return "test"
}

export function appEnvToApiClientEnv(environment: AppEnv): ApiClientEnv {
    if ((environment ?? "").toLowerCase().startsWith("live")) return "LIVE"
    return "TEST"
}
