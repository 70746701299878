import type { Tag } from "doctrine"
import type { Localized } from "../Localized"
import {
    IsBooleanType,
    IsNumberType,
    IsStringType,
    Type,
    IsUnionType,
    MatchesType,
    TypeDiagnosticToString,
} from "../../../reactor/Types/Type"
import { Uuid } from "../../../reactor/Types/Primitives/Uuid"

export function ParseLocalizedTag(
    tags: Tag[] | undefined,
    tagName: string
): Localized<any> | undefined {
    const defaultStrings = tags?.filter((t) => t.title === tagName).map((t) => t.description)

    if (defaultStrings?.length) {
        const res: Localized<any> = {}
        for (let s of defaultStrings) {
            if (s) {
                let locale: string | undefined

                const i = s.indexOf(" ")
                if (i !== -1) {
                    locale = s.slice(0, i)
                    s = s.slice(i + 1)
                } else throw new Error("Invalid tag: Must specify locale key first")

                // Extract genus hints
                if (locale.includes(":")) {
                    const [key, genus] = locale.split(":")
                    locale = key
                    res[locale + ":genus"] = genus
                }
                // Replaces all new lines in s with escaped new lines
                s = s.replace(/\n/g, "\\n")

                if (s.startsWith('"') && s.endsWith('"')) {
                    s = s.slice(1, -1)
                }

                res[locale] = s
            }
        }
        return res
    }
}

export function ParseTagValue(tagName: string, s: string, type: Type) {
    function isCompatible(type: Type, pred: (t: Type) => boolean): boolean {
        return pred(type) || (IsUnionType(type) && type.union.every((t) => isCompatible(t, pred)))
    }

    let value: any
    if (isCompatible(type, IsStringType)) value = s.startsWith('"') ? JSON.parse(s) : s
    else if (isCompatible(type, IsNumberType)) value = parseFloat(s)
    else if (isCompatible(type, IsBooleanType)) value = s === "true"
    else
        try {
            if (s.startsWith("'")) s = s.slice(1)
            if (s.endsWith("'")) s = s.slice(0, -1)

            while (s.includes("%UUID%")) {
                s = s.replace("%UUID%", Uuid().valueOf())
            }

            value = s ? JSON.parse(s) : null

            const match = MatchesType(value, type)
            if (match !== true) {
                throw new Error(
                    `Type mismatch for @${tagName} value: ${TypeDiagnosticToString(match)}`
                )
            }
        } catch (e: any) {
            throw new Error(`Unable to parse JSON for @${tagName} tag: ${e.message}, value: ${s}`)
        }

    return value
}
