import { css } from "@emotion/react"
import { EditableSections } from "../../../../packages/editing/EditableSections"
import { colors } from "../colors"
import { Sticker, Stickers } from "./Stickers"
import { Section } from "../../../../packages/editing/Section"

Section(JagSection)
function JagSection(section: {
    /**
     * @default '[{ "type": "Placeholder", "id": "%UUID%" }]'
     */
    sections: Section[]

    stickers: Sticker[]
}) {
    const color = colors.darkPurple
    return (
        <div
            className="py-5"
            style={{ width: "100%", position: "relative" }}
            css={css`
                /** Fallback to hidden for older browsers not supporting clip */
                overflow-x: hidden;
                overflow-x: clip;
            `}
        >
            {topJag(color)}
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: "50%",
                    right: 0,
                    bottom: 0,
                    width: 10,
                    height: 10,
                }}
            >
                <div style={{ position: "relative" }}>
                    <Stickers stickers={section.stickers} />
                </div>
            </div>

            {section.sections && (
                <div
                    style={{
                        marginTop: -1,
                        marginBottom: -1,
                        backgroundColor: color,
                        padding: "2rem",
                        minHeight: 300,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <EditableSections sections={section.sections} />
                </div>
            )}

            {bottomJag(color)}
        </div>
    )
}

const topJag = (color: string) => (
    <svg
        style={{ display: "block" }}
        width="100%"
        viewBox="0 0 2173 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2173 2.73438V66H0V65.1621L619.799 0V55.3516L1146.19 0V55.3516L1672.61 0V55.3516L2173 2.73438Z"
            fill={color}
        />
    </svg>
)

const bottomJag = (color: string) => (
    <svg
        style={{ display: "block" }}
        width="100%"
        viewBox="50 0 2123 87"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2173 23.2386V0H20.7426L0 53.4815V85.634L513.424 31.6827V87L1039.81 31.6827V87L1566.24 31.6827V87L2173 23.2386Z"
            fill={color}
        />
    </svg>
)
