import React, { useMemo, useState } from "react"
import { useSharedWidget } from "../../studio/client"
import { useParam } from "../ssr/useParam"
import { WidgetContext } from "../widgets/WidgetContext"
import { WidgetView } from "../widgets/WidgetView"

export function SharedWidgetView() {
    const urlSecret = useParam("urlSecret")
    const [state, setState] = useState({})
    const body = useMemo(() => ({ state }), [state])
    const { data, refresh } = useSharedWidget((urlSecret as any) ?? null, body)

    return (
        <WidgetContext.Provider
            value={{
                root: data?.content,
                async performAction() {
                    alert("Not implemented")
                },
                refresh,
                state: { ...data?.defaultState, ...state },
                async setState(newState) {
                    setState((state) => ({ ...state, ...newState }))
                },
            }}
        >
            <WidgetView value={data?.content} />
        </WidgetContext.Provider>
    )
}
