import React from "react"
import { useHover } from "../../../../../packages/hooks/useHover"
import { JagFrame4x1 } from "../../assets/JagFrames"
import { usePartyRoomsByVenue } from "../../client"
import { colors } from "../../colors"
import { usePartyContext } from "./PartyContext"
import { DateString } from "../../../../../reactor/Types/Primitives/DateTime"
import { usePageContext } from "../../Page"
import { useNavigate } from "react-router-dom"
import { NonNegativeInteger } from "../../../../../reactor/Types/Primitives/NonNegativeNumber"
import { ImageToUrl } from "../../../../../reactor/Types/File"
import { Breadcrumbs } from "./Breadcrumbs"
import { Footer } from "./Footer"

export function RoomAndTimePage() {
    const { venue } = usePageContext()
    const { guestCount, date, setRoomAndTime, room, time } = usePartyContext()
    const rooms = usePartyRoomsByVenue(
        venue?.id ?? null,
        date ? DateString(date) : null,
        NonNegativeInteger(guestCount)
    )
    const navigate = useNavigate()
    if (rooms.error) return <div>Unable to fetch available rooms</div>
    if (!rooms.data) return <div></div>

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflowX: "clip",
            }}
        >
            <div
                style={{
                    width: "100%",
                    maxWidth: 1200,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: 32,
                }}
            >
                <h2 style={{ marginBottom: 16, textAlign: "center" }}>Room & time</h2>
                <div style={{ color: colors.grey5, textAlign: "center" }}>
                    Available times and rooms for {guestCount} guests
                </div>
                <Breadcrumbs page={"room-time"} />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center",
                    }}
                >
                    {rooms.data?.rooms.length === 0 ? (
                        <div>Oh snap, no rooms available at this date!</div>
                    ) : null}
                    {rooms.data?.rooms.map((r, i) => {
                        return (
                            <div
                                key={i}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    maxWidth: 400,
                                    marginBottom: 32,
                                }}
                            >
                                <div
                                    style={{
                                        position: "relative",
                                        width: 350,
                                        height: 180,
                                        display: "flex",
                                        alignItems: "flex-start",
                                        justifyContent: "center",
                                        marginLeft: 16,
                                        marginRight: 16,
                                        marginTop: 32,
                                        marginBottom: 128,
                                    }}
                                >
                                    <img
                                        style={{
                                            width: 320,
                                            height: 240,
                                            borderRadius: 16,
                                            zIndex: 1,
                                            objectFit: "cover",
                                        }}
                                        src={
                                            r.image
                                                ? ImageToUrl(r.image, {
                                                      width: 320,
                                                      format: "jpeg",
                                                  })
                                                : undefined
                                        }
                                    />
                                    <div
                                        className="bold"
                                        style={{
                                            position: "absolute",
                                            color: "white",
                                            backgroundImage: `url("${JagFrame4x1(
                                                colors[r.themeColor ?? "purple"]
                                            )}")`,
                                            backgroundPosition: "center",
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                            padding: 32,
                                            fontSize: 30,

                                            bottom: -108,
                                            left: 0,
                                            right: 0,
                                            margin: "auto",
                                            textAlign: "center",
                                            zIndex: 2,
                                        }}
                                    >
                                        {r.name}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        justifyContent: "center",
                                    }}
                                >
                                    {r.timeSlots.map((t, i) => (
                                        <TimeSlotButton
                                            key={i}
                                            text={t.time}
                                            price={t.lowestPrice?.valueOf() ?? 0}
                                            selected={t.time === time && r.id === room?.id}
                                            disabled={!t.available}
                                            onClick={() => {
                                                setRoomAndTime(r, r.name, t.time)
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Footer
                disabled={!date || !room || !time}
                onClick={() => {
                    navigate("../package")
                }}
            />
        </div>
    )
}

function TimeSlotButton({
    text,
    price,
    disabled,
    selected,
    onClick,
}: {
    text: string
    price: number
    disabled: boolean
    selected: boolean
    onClick: () => void
}) {
    const { hover, hoverProps } = useHover()

    return (
        <div
            style={{
                flexDirection: "column",
                textAlign: "center",
                marginTop: 8,
                marginBottom: 8,
                marginLeft: 5,
                marginRight: 5,
                width: 100,
            }}
        >
            <div
                {...hoverProps}
                onClick={disabled ? undefined : onClick}
                className="bold"
                style={{
                    cursor: "pointer",
                    paddingTop: 4,
                    paddingBottom: 4,
                    backgroundColor: disabled
                        ? undefined
                        : hover || selected
                          ? colors.pinkFlirt
                          : colors.darkPurple,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    color: disabled ? colors.grey2 : hover || selected ? "white" : "white",
                    transition: "backgroundColor 0.2s",
                    fontSize: 18,
                    fontFamily: "GT-Haptik-Black",
                }}
            >
                {text}
            </div>
            <div
                {...hoverProps}
                onClick={disabled ? undefined : onClick}
                className="bold"
                style={{
                    cursor: "pointer",
                    paddingTop: 4,
                    paddingBottom: 4,
                    backgroundColor: disabled
                        ? undefined
                        : hover || selected
                          ? colors.accentDimples
                          : colors.white,
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                    color: disabled ? colors.white : hover || selected ? "white" : undefined,
                    transition: "backgroundColor 0.2s",
                    fontSize: 18,
                    fontWeight: 400,
                }}
            >
                {price}
            </div>
        </div>
    )
}
