import { RangedInteger, RangedNumber } from "../Opaque"

/**
 * @shared
 */
export type NonNegativeNumber = RangedNumber<"NonNegativeNumber", 0, undefined>
export function NonNegativeNumber(nonNegativeNumber: number) {
    if (nonNegativeNumber >= 0) return nonNegativeNumber as any as NonNegativeNumber
    throw new Error("NonNegativeNumber must be 0 or a positive number")
}

/**
 * @shared
 */
export type NonNegativeInteger = RangedInteger<"NonNegativeInteger", 0, undefined>
export function NonNegativeInteger(nonNegativeInteger: number) {
    if (Number.isInteger(nonNegativeInteger) && nonNegativeInteger >= 0)
        return nonNegativeInteger as any as NonNegativeInteger
    throw new Error("NonNegativeInteger must be 0 or a positive integer")
}
