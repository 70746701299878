import React from "react"
import ReactDOM from "react-dom/client"
import createCache from "@emotion/cache"
import { CacheProvider } from "@emotion/react"
const cache = createCache({ key: "emotion-cache" })
/** Initializes a web app, making sure it works well in both production and HMR mode. */
export function InitWebApp(
    RootComponent: React.FunctionComponent,
    /** ID of the DOM root element */
    rootId = "root"
) {
    const rootEl = document.getElementById(rootId)

    if (rootEl) {
        ReactDOM.hydrateRoot(
            rootEl,
            <CacheProvider value={cache}>
                <RootComponent />
            </CacheProvider>
        )
    }
}
