export type StudioMenu = { [label: string]: StudioMenuItem }

export type StudioMenuItem = {
    /** The FontAwesome icon name, e.g. `"camera"` */
    icon?: string
    isDefault?: boolean
    /** The slug of the target page or name of collection */
    target?: string
    menu?: StudioMenu
    dock?: "bottom"
    /** If specified, this overrides the index of this menu item in the menu order */
    index?: number
    /** If true, this menu item will have some extra space before it next bottom neighbor. */
    spacingBottom?: boolean
}
