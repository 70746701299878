import { Link } from "react-router-dom"
import { css } from "@emotion/react"
import { type Markdown } from "../../../../reactor"
import { EditableText } from "../../../../packages/editing/EditableText"
import { Localized } from "../../../../packages/localization/Localized"
import { FileToUrl, type Image } from "../../../../reactor/Types/File"
import { Section } from "../../../../packages/editing/Section"
import { usePageContext } from "../Page"
import { CallToAction } from "./TextAndCallToAction"
import { responsiveCss } from "../css"
import { SectionWrapper } from "../views/SectionWrappper"

interface TextAndSticker extends SectionWrapper {
    /**
     * @default '{"en": "Welcome!", "no": "Velkommen!"}'
     */
    title: Localized<string>

    /**
     * @default '{"en": "A paragraph or more about this section.", "no": "Et eller flere avsnitt om denne seksjonen."}'
     */
    text: Localized<Markdown>

    /**
     * How to align the text. Defaults to center.
     * @default "center"
     */
    textAlign: "left" | "center" | "right"

    /**
     * An sticker to display on top of this section.
     */
    sticker?: Image

    callToAction?: { text: Localized<string>; link: string }
}

Section(TextAndSticker)
function TextAndSticker(section: TextAndSticker) {
    const { venue, locale } = usePageContext()

    return (
        <SectionWrapper section={section}>
            <div
                css={css({
                    width: 800,
                    maxWidth: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: section.textAlign,
                })}
            >
                {section.sticker && (
                    <img
                        src={FileToUrl(section.sticker as any)}
                        css={css(
                            {
                                height: "80px",
                                width: "80px",
                                filter: "drop-shadow(0px 33px 66px rgba(42, 19, 78, 0.2))",
                                marginBottom: 16,
                            },
                            responsiveCss("min", "sm", { marginBottom: 24 }),
                            responsiveCss("min", "sm", { height: "120px", width: "120px" })
                        )}
                    />
                )}
                {!!section.title && (
                    <h1
                        css={css({
                            width: "100%",
                            marginTop: 0,
                            marginBottom: 32,
                        })}
                    >
                        <EditableText obj={section} prop="title" defaultText="Say something here" />
                    </h1>
                )}
                <div
                    css={css({
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                    })}
                >
                    <EditableText
                        obj={section}
                        prop="text"
                        defaultText="A paragraph or more about this section."
                        isMarkdown
                    />
                    {section.callToAction && section.callToAction.link && (
                        <Link
                            css={css(
                                {
                                    display: "inline-block",
                                    marginTop: "48px",
                                },
                                responsiveCss("min", "sm", { marginTop: "60px" })
                            )}
                            to={
                                section.callToAction.link.startsWith("https://")
                                    ? section.callToAction.link
                                    : `/${locale}/${venue ? venue.slug + "/" : ""}${
                                          section.callToAction.link
                                      }`
                            }
                        >
                            {section.callToAction && (
                                <CallToAction
                                    align={
                                        section.textAlign === "left"
                                            ? "flex-start"
                                            : section.textAlign === "right"
                                              ? "flex-end"
                                              : "center"
                                    }
                                    section={{ callToAction: section.callToAction.text }}
                                    prop="callToAction"
                                />
                            )}
                        </Link>
                    )}
                </div>
            </div>
        </SectionWrapper>
    )
}
