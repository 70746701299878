import { useState } from "react"

export function useHover() {
    const [hover, setHover] = useState(false)
    return {
        hover,
        setHover,
        hoverProps: {
            onMouseEnter() {
                setHover(true)
            },
            onMouseLeave() {
                setHover(false)
            },
        },
    }
}
