import React, { useContext, useState } from "react"
import { useHover } from "../../../../../packages/hooks/useHover"
import { DateString } from "../../../../../reactor/Types/Primitives/DateTime"
import { NonNegativeInteger } from "../../../../../reactor/Types/Primitives/NonNegativeNumber"
import { ChevronLeft, ChevronRight } from "../../assets/Chevrons"
import { usePartyCalendarByVenue } from "../../client"
import { colors } from "../../colors"
import { usePageContext } from "../../Page"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { PartyContext } from "./PartyContext"
import moment from "moment"

export function DatePicker({
    date: selectedDate,
    setDate: setSelectedDate,
}: {
    date: Date | undefined
    setDate: (date: Date) => void
}) {
    const today = new Date()
    const [month, setMonth] = useState(today.getMonth())
    const [year, setYear] = useState(today.getFullYear())
    const localize = useLocalize()
    const { venue } = usePageContext()
    const party = useContext(PartyContext)

    const calendarMonth = new Date()
    calendarMonth.setFullYear(year)
    calendarMonth.setDate(1)
    calendarMonth.setMonth(month, 1)
    calendarMonth.setHours(12, 0, 0, 0)

    const { data } = usePartyCalendarByVenue(
        venue?.id ?? null,
        DateString(calendarMonth),
        party ? NonNegativeInteger(party.guestCount) : null
    )

    if (month < 0) {
        setMonth(11)
        setYear(year - 1)
    }
    if (month > 11) {
        setMonth(0)
        setYear(year + 1)
    }
    function focus(date: Date) {
        if (date) {
            if (date.getMonth() != month) {
                setMonth(date.getMonth())
            }
            if (date.getFullYear() != year) {
                setYear(date.getFullYear())
            }
        }
    }

    let d = new Date()
    d.setFullYear(year)
    d.setMonth(month, 1)
    // Go to start of month
    while (d.getDate() !== 1) {
        d = new Date(d.getTime() - 24 * 60 * 60 * 1000)
    }
    // Go to start of week
    while (d.getDay() !== 1) {
        d = new Date(d.getTime() - 24 * 60 * 60 * 1000)
    }

    const weeks: Date[][] = []
    for (let w = 0; w < 6; w++) {
        const week: Date[] = []
        for (let i = 0; i < 7; i++) {
            week.push(d)
            d = new Date(d.getTime() + 24 * 60 * 60 * 1000)
        }
        weeks.push(week)
    }

    return (
        <div
            style={{
                margin: 32,
                backgroundColor: "white",
                paddingLeft: 32,
                paddingRight: 32,
                paddingTop: 8,
                paddingBottom: 24,
                borderRadius: 8,
            }}
        >
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div
                    style={{ padding: 16, paddingTop: 24, cursor: "pointer" }}
                    onClick={() => setMonth(month - 1)}
                >
                    <ChevronLeft />
                </div>
                <div
                    className="black"
                    style={{
                        flex: 1,
                        textAlign: "center",
                        fontSize: 30,
                        marginTop: 5,
                        marginBottom: -5,
                    }}
                >
                    {localize(monthNames[month])} {year.toString()}
                </div>
                <div
                    style={{ padding: 16, paddingTop: 24, cursor: "pointer" }}
                    onClick={() => setMonth(month + 1)}
                >
                    <ChevronRight />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: 8,
                    marginBottom: 12,
                }}
            >
                {weekdays.map((w, i) => (
                    <div
                        key={i}
                        style={{ width: 54, textAlign: "center", fontSize: 14, color: "#A89FB7" }}
                    >
                        {localize(w).toLocaleUpperCase()}
                    </div>
                ))}
            </div>

            {weeks.map((w, i) => (
                <div
                    key={i}
                    style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                >
                    {w.map((day, i2) => {
                        const date = data?.partyCalendar?.find(
                            (d) => parseInt(d.date) === day.getDate()
                        )
                        const available = party === undefined ? false : !!date?.available
                        const disabled = party === undefined ? false : !available
                        const price = party === undefined ? undefined : date?.lowestPrice?.valueOf()
                        return (
                            <DayCell
                                key={i2}
                                day={day}
                                text={available ? `${price ?? 0}` : "Closed"}
                                disabled={disabled}
                                displayMonth={month}
                                displayYear={year}
                                selectedDate={selectedDate}
                                setSelectedDate={(d) => {
                                    setSelectedDate(d)
                                    focus(d)
                                }}
                            />
                        )
                    })}
                </div>
            ))}
        </div>
    )
}

function DayCell({
    day,
    text,
    disabled,
    displayMonth,
    displayYear,
    selectedDate,
    setSelectedDate,
}: {
    day: Date
    text: string
    disabled: boolean
    displayMonth: number
    displayYear: number
    selectedDate: Date | undefined
    setSelectedDate: (d: Date) => void
}) {
    const { hover, hoverProps } = useHover()
    const dayYear = day.getFullYear()
    const dayMonth = day.getMonth()
    const dayDate = day.getDate()
    const selected = dayDate === selectedDate?.getDate() && dayMonth === selectedDate.getMonth()
    const inThePast = moment(day).isBefore(Date.now(), "day")
    const inOtherMonth = dayMonth !== displayMonth || dayYear !== displayYear

    return (
        <div
            {...hoverProps}
            onClick={() => {
                const isNextMonth = day.getFullYear() > displayYear || day.getMonth() > displayMonth
                if (disabled && !isNextMonth) {
                    alert("This date is not available for online booking.")
                    return
                }
                if (!inThePast) setSelectedDate(day)
                else alert("You can't select a date in the past")
            }}
            className={dayMonth === displayMonth ? "bold" : undefined}
            style={{
                userSelect: "none",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                width: 50,
                margin: 2,
                height: 50,
                transform: hover && !inThePast && !disabled ? "scale(1.5)" : undefined,
                transition: "all 0.2s ease",
                transformOrigin: "center",
                backgroundColor: selected ? colors.pinkFlirt : undefined,
                borderRadius: 4,
                color: selected
                    ? "white"
                    : inThePast || inOtherMonth
                      ? colors.grey6
                      : disabled
                        ? colors.grey3
                        : undefined,
            }}
        >
            {dayDate}
            <span style={{ fontSize: 12, fontWeight: "normal", marginTop: -4 }}>{text}</span>
        </div>
    )
}

const weekdays = [
    { en: "Mon", no: "Man", sv: "Mån", da: "Man", de: "Mo" },
    { en: "Tue", no: "Tir", sv: "Tis", da: "Tir", de: "Di" },
    { en: "Wed", no: "Ons", sv: "Ons", da: "Ons", de: "Mi" },
    { en: "Thu", no: "Tor", sv: "Tor", da: "Tor", de: "Do" },
    { en: "Fri", no: "Fre", sv: "Fre", da: "Fre", de: "Fr" },
    { en: "Sat", no: "Lør", sv: "Lör", da: "Lør", de: "Sa" },
    { en: "Sun", no: "Søn", sv: "Sön", da: "Søn", de: "So" },
]

const monthNames = [
    { en: "January", no: "Januar", sv: "Januari", da: "Januar", de: "Januar" },
    { en: "February", no: "Februar", sv: "Februari", da: "Februar", de: "Februar" },
    { en: "March", no: "Mars", sv: "Mars", da: "Marts", de: "März" },
    { en: "April", no: "April", sv: "April", da: "April", de: "April" },
    { en: "May", no: "Mai", sv: "Maj", da: "Maj", de: "Mai" },
    { en: "June", no: "Juni", sv: "Juni", da: "Juni", de: "Juni" },
    { en: "July", no: "Juli", sv: "Juli", da: "Juli", de: "Juli" },
    { en: "August", no: "August", sv: "Augusti", da: "August", de: "August" },
    { en: "September", no: "September", sv: "September", da: "September", de: "September" },
    { en: "October", no: "Oktober", sv: "Oktober", da: "Oktober", de: "Oktober" },
    { en: "November", no: "November", sv: "November", da: "November", de: "November" },
    { en: "December", no: "Desember", sv: "December", da: "December", de: "Dezember" },
]

export function DateBox({
    date,
    dateChanged,
}: {
    date: DateString | undefined
    dateChanged: (date: DateString | undefined) => void
}) {
    const localize = useLocalize()
    const d = date ? new Date(date.valueOf()) : new Date()
    const month = d.getMonth()
    const year = d.getFullYear()
    const day = d.getDate()

    const daysInMonth = new Date(year, month + 1, 0).getDate()

    function pad(n: number) {
        return n < 10 ? "0" + n : n
    }

    const yearOptions = new Array(20)
        .fill(0)
        .map((x, i) => (new Date().getFullYear() - i).toString())

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
            }}
        >
            <div style={{ flex: 1, margin: 8, marginLeft: 0 }}>
                <SelectBox
                    options={yearOptions}
                    value={yearOptions.indexOf(year.toString()).toString()}
                    onChange={(value, index) => {
                        dateChanged(DateString(`${value}-${pad(month + 1)}-${pad(day)}`))
                    }}
                />
            </div>
            <div style={{ flex: 1, margin: 8 }}>
                <SelectBox
                    options={monthNames.map((m) => localize(m) ?? "??")}
                    value={month.toString()}
                    onChange={(value, index) => {
                        dateChanged(DateString(`${year}-${pad(index + 1)}-${pad(day)}`))
                    }}
                />
            </div>
            <div style={{ flex: 1, margin: 8, marginRight: 0 }}>
                <SelectBox
                    options={new Array(daysInMonth).fill(0).map((x, i) => (i + 1).toString())}
                    value={(day - 1).toString()}
                    onChange={(value, index) => {
                        dateChanged(DateString(`${year}-${pad(month + 1)}-${pad(index + 1)}`))
                    }}
                />
            </div>
        </div>
    )
}

function SelectBox({
    options,
    value,
    onChange,
}: {
    options: string[]
    value: string
    onChange: (value: string, index: number) => void
}) {
    return (
        <select
            style={{ border: "none", padding: 8, width: "100%", borderRadius: 8 }}
            value={value}
            onChange={(e) => onChange(options[parseInt(e.target.value)], parseInt(e.target.value))}
        >
            {options.map((opt, i) => (
                <option key={i} value={i}>
                    {opt}
                </option>
            ))}
        </select>
    )
}
