import type { LazyWidgetMap, WidgetMap, Widget } from "./Widget"
import type { Item } from "./Item"
import { Empty } from "./Empty"
import { WidgetAction } from "./WidgetAction"
import type { Chip } from "./Chip"
import { IsButton, IsItem, IsModal, IsTable, IsTableRow, IsWidgetBase } from "./WidgetTypeHelpers"

/** Converts any `LazyWidgetMap` to a serializeable `WidgetMap` by evaluating any function properties.
 *
 *  Returns a new object with all properties evaluated.
 */
export function EvaluateWidgetMap(row: LazyWidgetMap): WidgetMap
export function EvaluateWidgetMap<T extends Widget>(row: T): T
export function EvaluateWidgetMap(row: Widget | LazyWidgetMap): Widget
export function EvaluateWidgetMap(row: Widget | LazyWidgetMap) {
    if (row instanceof Promise) return row
    if (IsTableRow(row)) {
        const res: WidgetMap = {}
        let promises: Promise<any>[] | undefined
        for (const key of Object.keys(row)) {
            try {
                const fn = (row as LazyWidgetMap)[key]
                if (fn instanceof Function) res[key] = fn()
                else res[key] = fn

                const widget = res[key]
                if (widget instanceof Promise) {
                    promises ??= []
                    promises.push(widget)
                    // Avoid global unhandled promise rejection (which would
                    // crash the entire server). Instead we catch the error
                    // and display the error message in place of the widget
                    widget.catch((e) => {
                        res[key] = Empty(e.message, "Error")
                    })
                }
            } catch (e: any) {
                res[key] = ["This tab failed to render because of an error", e.message].concat(
                    e.stack.split("\n")
                )
            }
        }
        if (promises) return Promise.all(promises).then(() => res)

        return res
    }
    return row
}

function isWidget(obj: any): obj is Widget & { widgetKey: string } {
    return (
        !!obj &&
        typeof obj === "object" &&
        "type" in obj &&
        "widgetKey" in obj &&
        typeof obj.widgetKey === "string"
    )
}

/**
 * Finds all widgets in the subtree with a widget key.
 */
export function FindAllWidgets(
    root: any,
    res: Widget & { widgetKey: string }[] = [],
    seen: Set<any> = new Set<any>()
): (Widget & { widgetKey: string })[] {
    if (seen.has(root)) return res
    seen.add(root)
    if (isWidget(root)) {
        res.push(root)
    }
    if (typeof root === "object") {
        for (const prop in root) {
            FindAllWidgets(root[prop], res, seen)
        }
    }
    return res as any
}

export function EnsureWidgetKeysUnique(root: Widget) {
    const widgets = FindAllWidgets(root)
    const keys = new Set<string>()
    for (const widget of widgets) {
        if (keys.has(widget.widgetKey)) {
            throw new Error(`Duplicate widgetKey '${widget.widgetKey}'`)
        }
        keys.add(widget.widgetKey)
    }
}

/** Finds the widget uniquely identified by the provided key.
 *
 *  If multiple widgets matches the key, an error is thrown.
 */
export function FindWidgetByKey(root: Widget, key: string): Widget | undefined {
    const res: Widget[] = []
    FindMultipleWidgetsByKey(root, key, res)
    if (res.length > 1)
        throw new Error(
            `Multiple widgets with key '${key}' - please provide unique widgetKey to disambiguate`
        )
    if (res.length === 0) return undefined
    return res[0]
}

function FindMultipleWidgetsByKey(root: Widget, key: string, result: Widget[]) {
    if (IsWidgetBase(root) && root.widgetKey === key) {
        result.push(root)
    }
    if (root instanceof Array) {
        for (const child of root) {
            FindMultipleWidgetsByKey(child, key, result)
        }
        return
    }
    if (IsButton(root)) {
        if (root.dropdown) {
            for (const subButton of root.dropdown) {
                FindMultipleWidgetsByKey(subButton, key, result)
            }
            return
        }
    }
    if (IsModal(root)) {
        FindMultipleWidgetsByKey(root.widget, key, result)
        return
    }

    if (IsTable(root)) {
        FindMultipleWidgetsByKey(root.buttons, key, result)
        FindMultipleWidgetsByKey(root.widget, key, result)
        for (const row of root.rows) {
            for (const column of Object.keys(row)) {
                const cell = row[column]
                FindMultipleWidgetsByKey(cell, key, result)
            }
        }
        return
    }

    // Table row case
    if (root !== null && typeof root === "object") {
        for (const field in root) {
            FindMultipleWidgetsByKey((root as any)[field], key, result)
        }
    }
}
export function FindWidgetActionByKey(
    root: Widget,
    key: string,
    method = "action"
): WidgetAction | undefined {
    const widget = FindWidgetByKey(root, key)
    return widget &&
        typeof widget === "object" &&
        method in widget &&
        (widget as any)[method] instanceof Function
        ? (widget as any)[method]
        : undefined
}

/** Extracts the item widget from a more complex widget. */
export function ExtractPrimaryItemFromWidget(view: Widget): Item | Empty {
    if (view === undefined) {
        return Empty("Not specified")
    }
    if (IsItem(view)) return view

    if (view && typeof view === "object") {
        for (const col of Object.keys(view)) {
            const w = (view as any)[col]
            if (IsItem(w)) return w
        }
    }
    const text = ExtractPropFromWidget(view, "primary") ?? "(no text)"
    return { type: "Item", primary: text }
}

/** Extracts the most significant string to display from a widget. */
export function ExtractPropFromWidget(
    view: Widget,
    field: "primary" | "secondary"
): string | undefined
export function ExtractPropFromWidget(view: Widget, field: "chips"): Chip | Chip[] | undefined
export function ExtractPropFromWidget(
    view: Widget,
    field: "primary" | "secondary" | "chips" = "primary"
) {
    if (IsItem(view)) {
        return view[field]
    }
    if (view && typeof view === "object") {
        for (const col of Object.keys(view)) {
            const w = (view as any)[col]
            if (IsItem(w)) {
                return w[field]
            }
        }
    }
    if (view && typeof view === "object") {
        for (const col of Object.keys(view)) {
            const w = (view as any)[col]
            if (typeof w === "string") return w
            if (typeof w === "number") return "" + w
        }
    }
    if (typeof view === "string") return view
    if (typeof view === "number") return "" + view
    return "" + JSON.stringify(view)
}
