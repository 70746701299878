import { OpaqueString } from "../Opaque"
import { TypeValidator } from "./TypeValidator"

/**
 * Represents a fully qualified URL, e.g. `https://www.foo.com/resource`
 * @shared
 **/
export type Url = OpaqueString<"Url">
TypeValidator(Url)
export function Url(url: string): Url {
    return url as any
}
