import React, { createContext } from "react"
import { usePreference } from "../../../reactor/Web/usePreference"
import { LocaleKey } from "../Locale"
import { useLocales } from "../../../studio/client"

export type CurrentLocaleContext = {
    locale: LocaleKey
    /** Provided if it is possible to change locales in this context. */
    setLocale?: (loc: LocaleKey) => void
}

export const CurrentLocaleContext = createContext<CurrentLocaleContext>({
    locale: "en" as any as LocaleKey,
})

export function CurrentLocaleProvider(props: { children: React.ReactNode }) {
    const locales = useLocales()
    const [locale, setLocale] = usePreference<LocaleKey>("editing-locale", "en" as any)

    if (locales.data && !locales.data.some((x) => x.key === locale)) {
        setLocale(locales.data[0].key)
    }

    return (
        <CurrentLocaleContext.Provider value={{ locale, setLocale }}>
            {props.children}
        </CurrentLocaleContext.Provider>
    )
}
