import React from "react"
import { Lazy } from "../packages/code-splitting/Lazy"
import { Route, useParams } from "react-router-dom"
import { SSRStatus } from "../packages/ssr/SSRStatus"
import { SharedWidgetView } from "../packages/shared-widget/SharedWidgetView"

/**
 * Lazy-loads the studio app, just the parts needed for the current route.
 */
export function Studio() {
    return (
        <>
            <Route path="shared-widgets/:urlSecret" element={<SharedWidgetView />} />
            <Route path="print/:collection/:document/:widgetKey" element={<PrintLazy />} />
            <Route path="/studio" element={<DatasetLazy />}>
                <Route index element={<PageLazy />} />
                <Route path="post/:endpoint/*" element={<PostLazy />} />
                <Route path="search" element={<SearchLazy />} />
                <Route path="diagnostics" element={<DiagnosticsLazy />} />
                <Route path="import" element={<ImportLazy />} />
                <Route path="console" element={<ConsoleLazy />} />
                <Route path="console/:id" element={<ConsoleLazy />} />
                <Route path=":page" element={<PageLazy />} />
                <Route path=":page/:doc/:slug" element={<PageLazy />} />
                <Route path=":page/:doc" element={<PageLazy />} />

                <Route path="*" element={<NotFoundView />} />
            </Route>
        </>
    )
}

function DatasetLazy() {
    return <Lazy target={async () => (await import("./Views/Dataset")).Dataset} />
}

function PageLazy() {
    return <Lazy target={async () => (await import("./Views/Dataset")).Page} />
}

function ConsoleLazy() {
    return <Lazy target={async () => (await import("./Views/Console")).ConsoleView} />
}

function PostLazy() {
    return <Lazy target={async () => (await import("./Views/PostView")).PostView} />
}

function SearchLazy() {
    return <Lazy target={async () => (await import("./Views/SearchResults")).SearchResults} />
}
function DiagnosticsLazy() {
    return <Lazy target={async () => (await import("./Views/Diagnostics")).Diagnostics} />
}
function ImportLazy() {
    return <Lazy target={async () => (await import("./Views/ImportView")).ImportView} />
}

function PrintLazy() {
    return <Lazy target={async () => (await import("./Views/TablePrint")).TablePrint} />
}

function NotFoundView() {
    SSRStatus.code = 404
    const path = useParams()["*"]
    return <div>There is no page at this location: {path}</div>
}
