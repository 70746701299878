import React from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import { LocalesContext } from "../../../packages/localization/client-side/useLocalesContext"
import { ModalProvider } from "../../../packages/modal/Modal"
import { PageView } from "./Page"
import "./sections/Accordion"
import "./sections/Hero"
import "./sections/Menu"
import "./sections/IconBar"
import "./sections/TextAndCallToAction"
import "./sections/TextAndMedia"
import "./sections/TextAndSticker"
import "./sections/ComeBackTicketPrices"
import "./sections/Image"
import "./sections/InfoBox"
import "./sections/JagSection"
import "./sections/PartyBooker"
import "./sections/PartyBooker2"
import "./sections/VenueInfo"
import "./sections/VenueExtraInfo"
import "./sections/VenueInfoMap"
import "./sections/VenueNews"
import "./sections/Footer"
import "./sections/Links"
import "./sections/ExplorePartyRooms"
import "./sections/PartyPackageBrowser"
import "./sections/MediaAndCallToAction"
import "./sections/CustomHTMLSection"
import "./sections/Jobs"
import "../../../packages/editing/sections/Placeholder"
import "../../../packages/editing/sections/HorizontalStack"
import { useUserPrivileges, useVenues } from "./client"
import { PartyConfirmationEmail } from "./sections/party-booker/ConfirmationEmail"
import { useEffect } from "react"
import { useLocales } from "../../../studio/client"
import { useRedirectTrailingSlash } from "../../../packages/ssr/useRedirectTrialingSlash"
import { ErrorScreen } from "./ErrorScreen"
import { LocaleKey } from "../../../packages/localization/Locale"
import { EditableSiteLazy } from "../../../packages/editing/EditableSiteLazy"
import { Studio } from "../../../studio/Studio"
import { MarkdownEditor } from "../../../packages/markdown-edit/MarkdownEdit"
import { CookieConsentView } from "../../../packages/cookie-consent/CookieConsentView"
import { useAnalyticsLogger } from "../../../packages/analytics/useAnalyticsLogger"

MarkdownEditor.customStyleMap = {
    BOLD: { fontWeight: 900, fontFamily: "GT-Haptik-Medium" },
}

export function App() {
    useAnalyticsLogger()

    const { data: privileges } = useUserPrivileges()
    const { data: locales } = useLocales()
    const { data: venues } = useVenues()

    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    useRedirectTrailingSlash()

    if (!locales || !venues) return null

    return (
        <ModalProvider>
            <LocalesContext.Provider value={locales}>
                <Routes>
                    {Studio()}
                    <Route
                        element={
                            <>
                                <EditableSiteLazy condition={!!privileges?.canEditWebsite} />
                                <CookieConsentView />
                            </>
                        }
                    >
                        <Route index element={<PageView />} />
                        <Route
                            path="/confirmation-email/:partyId"
                            element={<PartyConfirmationEmail />}
                        />
                        {locales.map((locale) => (
                            <React.Fragment key={locale.key.valueOf()}>
                                <Route
                                    path={`/${locale.key}/job-details/:jobId`}
                                    element={<PageView locale={locale.key} slug="job-details" />}
                                />
                                <Route
                                    path={`/${locale.key}/job-application/:jobId`}
                                    element={
                                        <PageView locale={locale.key} slug="job-application" />
                                    }
                                />
                                <Route
                                    path={`/${locale.key}`}
                                    element={<PageView locale={locale.key} />}
                                />
                                <Route
                                    path={`/${locale.key}/:venue`}
                                    element={<PageView locale={locale.key} />}
                                />
                                <Route
                                    path={`/${locale.key}/:venue/:slug`}
                                    element={<PageView locale={locale.key} />}
                                />
                                <Route
                                    path={`/${locale.key}/:venue/:slug/*`}
                                    element={<PageView locale={locale.key} />}
                                />
                            </React.Fragment>
                        ))}
                        <Route
                            path="*"
                            element={
                                <ErrorScreen
                                    error={{
                                        status: 404,
                                        type: "Not found",
                                        detail: "No page exists at this slug",
                                    }}
                                    locale={LocaleKey.en}
                                />
                            }
                        />
                    </Route>
                </Routes>
            </LocalesContext.Provider>
        </ModalProvider>
    )
}
