import React from "react"

export function LogoIcon() {
    return (
        <svg
            width="47"
            height="40"
            viewBox="0 0 47 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M46.5116 0.488281L26.7702 2.52031V0.488281L0 3.24318L7.21116 21.4386L0 39.6358L19.7433 37.6056V39.6358L46.5116 36.8827L39.3005 18.6855L46.5116 0.488281Z"
                fill="#721A9F"
            />
            <path
                d="M30.4443 14.6931L30.4759 8.45312L16.0443 9.93709L16.0312 31.6758L23.0731 30.9529L23.0601 24.3015L30.4443 23.5422L30.4461 17.3879L23.0601 18.149L23.062 15.4523L30.4443 14.6931Z"
                fill="white"
            />
        </svg>
    )
}
