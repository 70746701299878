import { server } from "../../server"
import { Url } from "./Primitives/Url"
import { Type, IsUnionType, IsFileType } from "./Type"

/** Represents a file that has been uploaded to the Reactor file storage service. Holds the
 * identifier needed to retrieve the file.
 *
 *  On the server, this is a Uuid to the file. The file transform step will automatically convert
 *  the Uuid to a full URL in all endpoint responses, so on the client, this holds the full URL.
 *
 *  By default, this accepts all mime types. To limit the mime type, provide a TMimeType parameter.
 *  The mime type of the file must start with the provided value.
 *
 *  Examples:
 *  * to accept PDF files only: `File<"application/pdf">`
 *  * to accept JPG files only: `File<"image/jpeg">`
 *  * to accept all image files: `File<"image">` (for this, you can also use the `Image` shorthand)
 *
 *  To accept multiple mime types, create a union: `Image | Video | File<"application/pdf">`
 *
 *  @icon file
 *  @shared
 */
export declare class File<TMimeTypes extends string = ""> extends String {
    /** This helps typescript distinguish different opaque string types. */
    protected readonly __file: TMimeTypes
    /** This object is already an opaque a string, but calling this makes method
     * makes typescript recognize it as a string. */
    toString(): string
}

/** Represents an image file uploaded to Reactor. Accepts all mime types starting with `image`.
 *
 *  Most endpoints automatically transform these into full URLs. The ImageToUrl and ImageToCSS
 *  functions may be used to re-compute the url with different image options
 *
 *  @icon file-image
 *  @shared
 */
export type Image = File<"image">

/** A video a video file uploaded to Reactor.
 * Accepts all mime types starting with `video`.
 *
 * @icon file-video
 * @shared
 */
export type Video = File<"video">

/** An audio file that is uploaded to Reactor.
 *  Accepts all mime types starting with "audio"
 *
 *  @icon file-audio
 *  @shared
 */
export type Audio = File<"audio">

/** A json file, where the content schema is unknown.
 *
 *  For files where the schema is known, e.g. Lottie files, prefer using a more specific type such
 *  as `LottieJson`.
 *  @shared
 */
export type Json = File<"application/json">

/**
 * A file that holds a SVG image.
 * @shared
 * @icon ui-image-03
 */
export type Svg = File<"image/svg+xml">

/** A json file which is known to hold a Lottie animation. */
export type LottieJson = File<"application/lottie+json">

export function GetFileTypeMimeTypes(type: Type): string[] {
    if (IsUnionType(type)) {
        return type.union.map(GetFileTypeMimeTypes).reduce((a, b) => [...a, ...b])
    }
    if (IsFileType(type)) {
        return [type.mimeType]
    }
    return []
}

/** Options for how to resize and format an image.
 *
 * The returned image will fill the area covered by width x height, potentially being larger than
 * specified in one direction. The aspect ratio will always be retained.
 */
export type ImageOptions = {
    /** The desired width of the image. The returned image may be wider. */
    width?: number
    /** The desired height of the image. The returned image may be taller. */
    height?: number
    format?: "jpeg" | "png"
}

/** Computes a URL for an image with options.
 *
 * Remarks for `options`:
 * * If `format` is not specified, it defaults to png.
 * * If both `width` and `height` are unspecified, the image will be returned in its original
 *   size.
 * * If exactly one of `width` or `height` are specified, the other dimension will be computed
 *   to retain aspect ratio.
 *
 * @param image The image for which an Url is requested
 * @param options Image options.
 * @returns An Url to the image
 */
export function ImageToUrl(
    image: Image | Url | string,
    options?: ImageOptions,
    /** Optimization.
     *
     * Computing the server URL of the request can be slow (due to context-lookup), in
     * performance-critical code the URL for the current request can be precomputed and passed in
     * here. */
    serverBaseUrl?: string
) {
    // This happens sometimes during WYSIWYG editing, when the image is not yet uploaded.
    if (!image) return ""

    // On the client, the image is already a full URL. We need to undo that to encode the new options.
    const url = UndoImageToUrl(image)
    const width = options?.width || ""
    const height = options?.height ? "x" + options.height : ""
    const ext = options?.format ? "." + options.format : ".png"

    serverBaseUrl ??= server()
    return (
        (url.startsWith(serverBaseUrl) ? url : `${serverBaseUrl}/api/images/${url}`) +
        `_${width}${height}${ext}`
    )
}

/** Removes any URL fluff from an image handle, returning only the Uuid part. */
export function UndoImageToUrl(
    /** An image handle that might be in a full URL form. */
    image: Image | Video | Url | string
): string {
    let str = image.valueOf()
    const lastSlash = str.lastIndexOf("/")
    if (lastSlash !== -1) {
        str = str.slice(lastSlash + 1)
    }

    const lastUnderscore = str.lastIndexOf("_")
    if (lastUnderscore !== -1) {
        str = str.slice(0, lastUnderscore)
    }
    return str
}

export function ImageToCSS(image: Image | Url | string, options?: ImageOptions) {
    let str = image.valueOf()
    if (str.toString().startsWith("url(")) {
        str = str.slice("url(".length, -1)
    }

    return "url(" + ImageToUrl(image, options) + ")"
}

/** Options for how to format a video.
 *
 * Placeholder for now, might support resizing/transcoding in the future.
 */
export type VideoOptions = {
    format?: "mp4"
}

/** Computes a URL for a video with options.
 *
 * @param video The video for which an Url is requested
 * @param options Video options.
 * @returns An Url to the video
 */
export function VideoToUrl(
    video: Video | Url | string,
    options?: VideoOptions,
    /** Optimization.
     *
     * Computing the server URL of the request can be slow (due to context-lookup), in
     * performance-critical code the URL for the current request can be precomputed and passed in
     * here. */
    serverBaseUrl?: string
) {
    // This happens sometimes during WYSIWYG editing, when the video is not yet uploaded.
    if (!video) return ""

    // On the client, the video is already a full URL. We need to undo that to encode the new options.
    const url = UndoImageToUrl(video)
    const ext = options?.format ? "." + options.format : ".mp4"

    serverBaseUrl ??= server()
    return (url.startsWith(serverBaseUrl) ? url : `${serverBaseUrl}/api/videos/${url}`) + `${ext}`
}

export function FileToUrl(
    file: File | Url | string,
    /** Optimization.
     *
     * Computing the server URL of the request can be slow (due to context-lookup), in
     * performance-critical code the URL for the current request can be precomputed and passed in
     * here. */
    serverBaseUrl?: string
) {
    serverBaseUrl ??= server()
    if (file.toString().startsWith(serverBaseUrl)) return file.toString()
    return `${serverBaseUrl}/api/files/${file.toString()}`
}
