import React from "react"
import { EditableText } from "../../../../../packages/editing/EditableText"
import { useNavigate } from "../../../../../packages/hooks/useNavigate"
import { ImageToUrl } from "../../../../../reactor/Types/File"
import { usePageContext } from "../../Page"
import { CallToAction } from "../TextAndCallToAction"
import { usePartyContext } from "./PartyContext"

export function ConfirmationPage() {
    const page = usePageContext()
    const { section, email } = usePartyContext()
    const navigate = useNavigate()
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflowX: "clip",
                padding: 32,
                animation: "fadeIn 2s",
            }}
        >
            <div
                style={{
                    width: "100%",
                    maxWidth: 512,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <h1>
                    <EditableText
                        obj={section}
                        prop="confirmationHeader"
                        isLocalized={true}
                        defaultText="Your party is booked!"
                    />
                </h1>

                <EditableText
                    obj={section}
                    style={{ marginBottom: 32 }}
                    prop="confirmationText"
                    isLocalized={true}
                    defaultText="A confirmation has been sent to your email address."
                    macros={{
                        email: <b>{email}</b>,
                    }}
                />
                <CallToAction
                    section={section}
                    prop="backToHomePage"
                    onClick={() => navigate(`/${page.locale.valueOf()}/${page.venue?.slug ?? ""}`)}
                />

                {page.sideMenuLargeLogo && (
                    <img
                        style={{ maxWidth: 512, marginTop: 128, paddingLeft: 80, paddingRight: 80 }}
                        src={ImageToUrl(page.sideMenuLargeLogo, { width: 512 })}
                    />
                )}
            </div>
        </div>
    )
}
