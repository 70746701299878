import { useState } from "react"
import { css } from "@emotion/react"
import { PlaceholderImageUrl } from "../../../../packages/editing/EditableImage"
import { ImageToUrl } from "../../../../reactor/Types/File"
import { Image } from "../../../../reactor/Types/File"
import { Section } from "../../../../packages/editing/Section"
import { responsiveCss } from "../css"
import { SectionWrapper } from "../views/SectionWrappper"

interface ImageSection extends SectionWrapper {
    image?: Image
    mask?: Image

    /**
     * How many percent of the image should be cropped on mobile.
     * @default 0
     */
    imageCropPercentageOnMobile?: number

    /**
     * How to position the image. Defaults to center.
     * @default "center"
     */
    imagePosition?: "left" | "center" | "right" | "hero"
}

Section(ImageSection)
function ImageSection(section: ImageSection) {
    const [, setRef] = useState<HTMLImageElement | null>(null)

    return (
        <SectionWrapper section={section}>
            <div css={css({ width: "100%" })}>
                <div
                    css={css(
                        {
                            overflow: "hidden",
                            display: "flex",
                            justifyContent:
                                section.imagePosition === "left"
                                    ? "flex-start"
                                    : section.imagePosition === "right"
                                      ? "flex-end"
                                      : "center",
                        },
                        !!section.imageCropPercentageOnMobile &&
                            responsiveCss("max", "xs", {
                                width: "100vw",
                                overflow: "hidden",
                                marginLeft: "calc(50% - 50vw)",
                            }),
                        section.imagePosition === "hero" && {
                            width: "100vw",
                            height: "60vh",
                            overflow: "hidden",
                            marginLeft: "calc(50% - 50vw)",
                        }
                    )}
                >
                    <img
                        ref={setRef}
                        src={
                            section.image
                                ? ImageToUrl(section.image, { width: 1200, format: "png" })
                                : PlaceholderImageUrl()
                        }
                        css={css(
                            { maxWidth: "100%" },
                            section.imagePosition === "hero" && { width: "100%" },
                            section.imageCropPercentageOnMobile &&
                                responsiveCss("max", "xs", {
                                    width: `calc(100vw + ${section.imageCropPercentageOnMobile}%)`,
                                    marginLeft: `calc(50% - 50vw - ${
                                        section.imageCropPercentageOnMobile / 2
                                    }%)`,
                                })
                        )}
                        style={{
                            height: "100%",
                            objectFit: "cover",
                            WebkitMaskImage:
                                section.mask &&
                                `url(${ImageToUrl(section.mask, { width: 1200, format: "png" })})`,
                            WebkitMaskRepeat: "no-repeat",
                            WebkitMaskPosition: "center",
                            WebkitMaskSize: "contain",
                        }}
                    />
                </div>
            </div>
        </SectionWrapper>
    )
}
