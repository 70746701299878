import React from "react"
import { useParams } from "react-router-dom"
import { StaticRouter } from "react-router-dom/server"
import { DirtyContext } from "../../../../../packages/editing/DirtyContext"
import {
    DummyEditableContext,
    EditableContext,
} from "../../../../../packages/editing/EditableContext"
import { EditableText } from "../../../../../packages/editing/EditableText"
import { LocalesContext } from "../../../../../packages/localization/client-side/useLocalesContext"
import type { Locale } from "../../../../../packages/localization/Locale"
import { Localized } from "../../../../../packages/localization/Localized"
import { Image } from "../../../../../reactor/Types/File"
import {
    PartyBooking,
    Venue,
    usePartyBooking,
    usePartyConfirmationEmailSettings,
} from "../../client"
import { colors } from "../../colors"
import { PartyConfirmationEmailProps } from "../../../model/PartyConfirmationEmail"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { CurrentLocaleContext } from "../../../../../packages/localization/client-side/CurrentLocaleContext"

export function PartyConfirmationEmail() {
    const { partyId } = useParams() as any
    const { data: party } = usePartyBooking(partyId ?? null)
    const { data: settings } = usePartyConfirmationEmailSettings() as any as {
        data: PartyConfirmationEmailProps
    }
    const localize = useLocalize()

    if (!party || !settings) return <></>

    const image = settings.headerImage ? localize(settings.headerImage) : undefined
    return RenderPartyConfirmationEmail(image, settings, party.booking, party.venue, localize)
}

export function RenderPartyConfirmationEmail(
    image: Image | undefined,
    settings: PartyConfirmationEmailProps,
    partyBooking: PartyBooking,
    partyVenue: Venue,
    localize: <T>(localized: Localized<T>) => T | undefined
) {
    return (
        <div
            style={{
                padding: 32,
                maxWidth: 768,
                borderTop: "20px solid " + colors.darkPurple,
                borderBottom: "20px solid " + colors.darkPurple,
            }}
        >
            {image && (
                <img
                    style={{ maxHeight: 300, maxWidth: "100%", objectPosition: "center" }}
                    src="https://funplays.fuseapps.no/static/funplays.png"
                />
            )}
            <h1>
                <EditableText
                    obj={settings}
                    prop="title"
                    isLocalized={true}
                    macros={{
                        // Remember to update the same table in the server-side code
                        birthdayChildName: partyBooking.request.birthdayChildName,
                        venueName: localize(partyVenue.name),
                    }}
                />
            </h1>
            <EditableText obj={settings} prop="subtext" isLocalized={true} isMarkdown={true} />
            <div style={{ marginTop: 32 }}>
                <b>
                    <EditableText
                        obj={settings}
                        prop="dateAndTime"
                        className="black"
                        isLocalized={true}
                    />
                </b>

                <div>
                    {
                        // TODO: Use the locale from the booking, when it is
                        // correct At time of writing, it would be "en" when it
                        // should be "NO" It is better in this domain to err in
                        // the direction of scandic languages
                        new Intl.DateTimeFormat("NO", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            timeZone: "Europe/Oslo",
                            minute: "numeric",
                        }).format(new Date(partyBooking.start.valueOf()))
                    }
                </div>
            </div>
            <div style={{ marginTop: 16 }}>
                <b>
                    <EditableText
                        obj={settings}
                        prop="numberOfChildren"
                        className="black"
                        isLocalized={true}
                    />
                </b>
                <div>{partyBooking.request.numberOfChildren.valueOf()}</div>
            </div>
            <div style={{ marginTop: 16 }}>
                <b>
                    <EditableText obj={settings} prop="room" className="black" isLocalized={true} />
                </b>
                <div>{localize(partyBooking.roomName)}</div>
            </div>
            {partyBooking.partyMealName && (
                <div style={{ marginTop: 16 }}>
                    <b>
                        <EditableText
                            obj={settings}
                            prop="meal"
                            className="black"
                            isLocalized={true}
                        />
                    </b>
                    <div>{localize(partyBooking.partyMealName)}</div>
                </div>
            )}
            {partyBooking.partyExtras.length > 0 ? (
                <div style={{ marginTop: 16 }}>
                    <b>
                        <EditableText
                            obj={settings}
                            prop="extras"
                            className="black"
                            isLocalized={true}
                        />
                    </b>
                    <div>
                        {partyBooking.partyExtras.map((pe) => (
                            <div>{localize(pe.partyExtraName)}</div>
                        ))}
                    </div>
                </div>
            ) : undefined}
            {partyBooking.request.additionalInfo && (
                <div style={{ marginTop: 16 }}>
                    <b>
                        <EditableText
                            obj={settings}
                            prop="additionalInfo"
                            className="black"
                            isLocalized={true}
                        />
                    </b>
                    <div>{partyBooking.request.additionalInfo}</div>
                </div>
            )}
            <div style={{ marginTop: 16 }}>
                <b>
                    <EditableText
                        obj={settings}
                        prop="totalPrice"
                        className="black"
                        isLocalized={true}
                    />
                </b>
                <div>{partyBooking.request.paymentTotal.valueOf()}</div>
            </div>
            <div style={{ marginTop: 16 }}>
                <b>
                    <EditableText
                        obj={settings}
                        prop="body"
                        isMarkdown={true}
                        isLocalized={true}
                        defaultText="body"
                        markdownMacros={{
                            venueName: localize(partyVenue.name) ?? "(no venue name)",
                        }}
                    />
                </b>
            </div>

            {partyVenue.emailFooter ? (
                <div style={{ marginTop: 32 }}>
                    {(localize(partyVenue.emailFooter) ?? "Funplays").split("\n").map((line) => (
                        <div>{line}</div>
                    ))}
                </div>
            ) : undefined}
        </div>
    )
}

export function RenderPartyConfirmationEmailStandalone(
    image: Image | undefined,
    settings: PartyConfirmationEmailProps,
    partyBooking: PartyBooking,
    partyVenue: Venue,
    localize: <T>(localized: Localized<T>) => T | undefined,
    locales: Locale[]
) {
    return (
        <LocalesContext.Provider value={locales}>
            <CurrentLocaleContext.Provider value={{ locale: partyBooking.request.locale }}>
                <StaticRouter location="">
                    <EditableContext.Provider value={DummyEditableContext}>
                        <DirtyContext.Provider
                            value={{
                                isDirty: false,
                                setDirty: () => {},
                                version: {},
                            }}
                        >
                            {RenderPartyConfirmationEmail(
                                image,
                                settings,
                                partyBooking,
                                partyVenue,
                                localize
                            )}
                        </DirtyContext.Provider>
                    </EditableContext.Provider>
                </StaticRouter>
            </CurrentLocaleContext.Provider>
        </LocalesContext.Provider>
    )
}
