import React from "react"

export function Logo() {
    return (
        <svg width="100%" viewBox="0 0 2431 622" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M240.72 331.659L241.262 225.178L0.271727 250.559L0 621.444L117.611 609.092L117.407 495.621L240.72 482.659V377.671L117.407 390.633V344.621L240.72 331.659Z"
                fill="white"
            />
            <path
                d="M462.847 201.9L462.644 433.525C462.779 459.314 449.817 473.226 425.589 476.823C410.591 479.063 392.81 471.869 392.81 445.198C392.81 416.355 393.014 209.162 393.014 209.162L275.946 221.446C275.946 221.446 275.81 381.947 275.81 457.685C275.81 533.423 325.827 596.673 429.932 584.593C522.636 573.871 580.321 515.642 580.253 430.132L580.457 189.413L462.915 201.765L462.847 201.9Z"
                fill="white"
            />
            <path
                d="M807.332 165.726L807.196 316.251L727.454 174.073L617.579 185.611L617.376 556.563L731.661 544.551L731.797 394.025L811.538 536.135L921.344 524.598L921.616 153.714L807.332 165.726Z"
                fill="white"
            />
            <path
                d="M1390.56 365.114L1390.77 104.375L1273.23 116.727L1273.02 487.611L1505.87 463.179L1505.8 353.034L1390.56 365.114Z"
                fill="white"
            />
            <path
                d="M2350.32 154.394C2350.32 154.394 2321.75 145.843 2296.84 138.513C2278.85 133.22 2268.27 127.587 2267.25 118.018C2266.23 108.449 2274.99 100.169 2290.46 98.5406C2305.93 96.9118 2317.06 103.563 2318.9 121.479L2426.05 110.213C2417.91 32.5076 2360.63 -6.99002 2283.94 1.01809C2203.46 9.50126 2149.3 61.4862 2149.23 130.505C2149.57 182.626 2177.94 213.98 2229.92 228.096L2288.22 244.858C2310.07 251.441 2313.06 258.839 2313.67 264.811C2314.48 272.344 2307.29 285.442 2282.93 288.021C2258.63 290.6 2244.65 273.905 2243.16 259.517L2130.64 271.326C2139.87 358.94 2208.68 398.506 2293.72 389.548C2376.31 380.861 2430.6 326.772 2430.74 254.563C2430.26 199.999 2403.59 169.731 2350.38 154.19L2350.32 154.394Z"
                fill="white"
            />
            <path
                d="M1750.18 66.5762L1645.06 77.6383L1520.66 461.621L1645.33 448.522L1656.53 407.26L1741.43 398.302L1752.56 437.189L1874.71 424.362L1750.25 66.5762H1750.18ZM1676.01 328.061L1700.37 250.288L1725.68 322.836L1676.01 328.061Z"
                fill="white"
            />
            <path
                d="M1106.81 134.241C1013.63 144.014 965.312 149.104 965.312 149.104L965.109 519.988L1081.02 507.772L1080.89 438.21L1111.15 435.02C1195.99 426.13 1251.84 361.454 1252.25 271.872C1252.65 182.357 1199.99 124.4 1106.81 134.173V134.241ZM1095.21 320.124C1079.8 320.124 1067.31 307.637 1067.31 292.232C1067.31 276.826 1079.8 264.339 1095.21 264.339C1110.61 264.339 1123.1 276.826 1123.1 292.232C1123.1 307.637 1110.61 320.124 1095.21 320.124Z"
                fill="white"
            />
            <path
                d="M2030.06 37.1903L1978.01 167.424L1922.76 48.456L1802.1 61.1468L1921.47 302.137L1921.2 419.544L2036.98 407.328L2037.25 298.336L2153.91 24.1602L2030.06 37.1903Z"
                fill="white"
            />
        </svg>
    )
}
