export function InsertImage(
    draftJs: typeof import("draft-js"),
    editorState: import("draft-js").EditorState,
    src: string,
    alt: string
) {
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity("IMAGE", "IMMUTABLE", {
        src,
        alt,
        // This is a workaround for a quirk in draftjs-md-converter, which
        // expects the image to have a fileName property, which in turn is
        // passet as "alt"
        fileName: alt,
    })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const newEditorState = draftJs.EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
    })

    return draftJs.AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")
}
