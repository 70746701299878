import { OpaqueInteger } from "../Opaque"

/**
 * A number that should be an integer (whole number).
 *
 * Note that this is stored as BIGINT in the database, but is converted to a
 * number in JavaScript. This means the only safe range is -2^53 to 2^53, or
 * Numner.MIN_SAFE_INTEGER to Number.MAX_SAFE_INTEGER.
 *
 * It does not support the full BIGINT range.
 */
export type Integer = OpaqueInteger<"Int">

/** Converts the provided number to an Integer.
 *
 *  Throws an error if the provided number is not an integer
 */
export function Integer(int: number): Integer {
    if ((int | 0) !== int) {
        throw new Error("Not an integer: " + int)
    }
    return int as any
}
