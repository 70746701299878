/** Injects some code into the end of the `<head>` tag of the current document
 *  being server-side rendered.
 *
 *  If no SSR transaction is active, an error is thrown.
 *
 *  If the same key is encountered multiple times during render, an error is thrown.
 * */
export function SSRHeader(
    /** Unique key of this header. */
    key: string,
    /** The HTML code to inject */
    header: string,
    /** What to do if duplicate keys are encountered for the same render */
    onDuplicateKeys: "ignore" | "error" = "ignore"
) {
    if (!ssrTransaction.macros) {
        throw new Error("No SSR transaction active - unable to add structured data.")
    }
    if (onDuplicateKeys === "error" ?? ssrTransaction.macros.has(key)) {
        throw new Error(`Duplicate SSR header with key '${key}'`)
    }

    ssrTransaction.macros?.set(key, (html) =>
        html.querySelector("head")?.insertAdjacentHTML("beforeend", header)
    )
}
