import { OpaqueString } from "../Opaque"
import { TypeValidator } from "./TypeValidator"

/**
 * @shared
 */
export type Markdown = OpaqueString<"Markdown">
TypeValidator(Markdown)
export function Markdown(md: string): Markdown {
    return md as any as Markdown
}
Markdown.trim = (md: Markdown): Markdown => {
    return md
        .split("\n")
        .map((x) => x.trim())
        .join("\n") as any
}

const regexMdLinks = /\[([^\[]+)\]\((.*)\)/gm
Markdown.transformLinks = (
    md: Markdown,
    transform: (alt: string, link: string) => { alt: string; link: string }
): Markdown => {
    return Markdown(
        md.replace(regexMdLinks, (substr, p1, p2) => {
            const { alt, link } = transform(p1, p2)
            return `[${alt}](${link})`
        })
    )
}
