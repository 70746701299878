import React from "react"
import { usePartyContext } from "./PartyContext"
import { usePartyExtrasByPackage } from "../../client"
import { colors } from "../../colors"
import { usePageContext } from "../../Page"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { useNavigate } from "react-router-dom"
import { EditableText } from "../../../../../packages/editing/EditableText"
import { Footer } from "./Footer"
import { Breadcrumbs } from "./Breadcrumbs"
import { EditablePosition } from "../../../../../packages/editing/EditablePosition"
import { ImageToCSS, ImageToUrl } from "../../../../../reactor/Types/File"

export function PartyExtrasPage() {
    const { locale, venue } = usePageContext()
    const { section, partyPackage, extras, setExtra, setExtras } = usePartyContext()
    const { data } = usePartyExtrasByPackage(venue?.id ?? null, partyPackage?.id ?? null)
    const localize = useLocalize()
    const navigate = useNavigate()

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflowX: "clip",
                paddingBottom: 48,
            }}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                {section.partyExtrasSticker && (
                    <div style={{ position: "relative" }}>
                        <EditablePosition
                            obj={section}
                            xProp="partyExtrasStickerOffsetX"
                            yProp="partyExtrasStickerOffsetY"
                        >
                            <img src={ImageToUrl(section.partyExtrasSticker, { width: 96 })} />
                        </EditablePosition>
                    </div>
                )}
                <h1>
                    <EditableText
                        obj={section}
                        prop="partyExtras"
                        isLocalized={true}
                        isMarkdown={true}
                        defaultText="Party Extras"
                    />
                </h1>
                <div>
                    <EditableText
                        obj={section}
                        prop="partyExtrasSubtext"
                        isLocalized={true}
                        isMarkdown={true}
                        defaultText="Add some extra flavour to your party"
                    />
                </div>
                <Breadcrumbs page="extras" />
                <div
                    style={{
                        marginTop: 32,
                        marginBottom: 48,
                        width: "100%",
                        maxWidth: 720,
                        backgroundColor: "white",
                        borderRadius: 8,
                        paddingLeft: 40,
                        paddingRight: 40,
                        paddingTop: 32,
                        paddingBottom: 32,
                    }}
                >
                    <h2
                        className="bold"
                        style={{
                            fontSize: 32,
                            fontFamily: "GT-Haptik-Black",
                            marginTop: 0,
                            marginBottom: 8,
                        }}
                    >
                        <EditableText
                            obj={section}
                            prop="partyExtrasSubheader"
                            isLocalized={true}
                            isMarkdown={true}
                            defaultText="Want something extra?"
                        />
                    </h2>
                    <div style={{ fontSize: 16, fontWeight: 400, marginBottom: 10 }}>
                        <EditableText
                            obj={section}
                            prop="partyExtrasSubheaderSubtext"
                            isLocalized={true}
                            isMarkdown={true}
                            defaultText="Add your wanted amount"
                        />
                    </div>
                    {data?.partyExtras.map((extra, i) => {
                        return (
                            <div
                                key={i}
                                style={{
                                    paddingTop: 22,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: colors.grey2,
                                        backgroundImage: extra.listImage
                                            ? ImageToCSS(extra.listImage, { width: 48, height: 48 })
                                            : undefined,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        width: 48,
                                        height: 48,
                                        borderRadius: 8,
                                        marginRight: 16,
                                    }}
                                ></div>
                                <div style={{ flex: 1 }}>
                                    <div>
                                        <span className="bold">{localize(extra.name)}</span>
                                        <span style={{ marginLeft: 8, fontSize: 12 }}>
                                            {extra.allergens
                                                .map((a) => localize(a).toUpperCase())
                                                .join(", ")}
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div>
                                            {extra.price
                                                ? new Intl.NumberFormat(locale.valueOf(), {
                                                      style: "currency",
                                                      maximumFractionDigits: 0,
                                                      currency: extra.currency,
                                                  }).format(extra.price.valueOf())
                                                : ""}
                                            {extra.price && extra.pricePerChild ? " + " : ""}
                                            {extra.pricePerChild
                                                ? new Intl.NumberFormat(locale.valueOf(), {
                                                      style: "currency",
                                                      maximumFractionDigits: 0,
                                                      currency: extra.currency,
                                                  }).format(extra.pricePerChild.valueOf()) +
                                                  " " +
                                                  localize(section.perChildText)
                                                : ""}
                                        </div>
                                        {extra.description ? (
                                            <>
                                                <div
                                                    style={{
                                                        marginLeft: 12,
                                                        paddingLeft: 12,
                                                        marginTop: 3,
                                                        height: 15,
                                                        borderLeft: `1px solid ${colors.darkPurple}`,
                                                    }}
                                                />
                                                <div style={{ marginRight: 12 }}>
                                                    {localize(extra.description)}
                                                </div>
                                            </>
                                        ) : undefined}
                                    </div>
                                </div>
                                {extra.maxPerBooking?.valueOf() === 1 ? (
                                    <Toggle
                                        value={extras ? extras[extra.id.valueOf()] ?? 0 : 0}
                                        setValue={(v) => setExtra(extra.id, v)}
                                    />
                                ) : (
                                    <NumberPicker
                                        value={extras ? extras[extra.id.valueOf()] ?? 0 : 0}
                                        setValue={(v) => setExtra(extra.id, v)}
                                        minValue={0}
                                        maxValue={extra.maxPerBooking?.valueOf() ?? 100}
                                    />
                                )}
                            </div>
                        )
                    })}
                </div>
                <Footer
                    onClick={() => {
                        if (!extras) setExtras({})
                        navigate("../contact-info")
                    }}
                />
            </div>
        </div>
    )
}

function NumberPicker({
    value,
    setValue,
    minValue,
    maxValue,
}: {
    value: number
    setValue: (value: number) => void
    minValue: number
    maxValue: number
}) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: "white",
                borderRadius: 8,
                fontSize: 24,
            }}
        >
            <div
                onClick={() => {
                    if (value > minValue) setValue(value - 1)
                }}
                style={{
                    userSelect: "none",
                    cursor: "pointer",
                    width: 40,
                    height: 40,
                    borderRadius: 8,
                    backgroundColor: value === minValue ? colors.grey2 : colors.purple,
                    color: value === minValue ? undefined : "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                -
            </div>
            <div style={{ width: 64, textAlign: "center" }}>{value}</div>
            <div
                onClick={() => {
                    if (value < maxValue) setValue(value + 1)
                }}
                style={{
                    userSelect: "none",
                    cursor: "pointer",
                    width: 40,
                    height: 40,
                    borderRadius: 8,
                    backgroundColor: value === maxValue ? colors.grey2 : colors.purple,
                    color: value === maxValue ? undefined : "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                +
            </div>
        </div>
    )
}

function Toggle({ value, setValue }: { value: number; setValue: (value: number) => void }) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: "white",
                borderRadius: 8,
                fontSize: 24,
            }}
        >
            <div
                onClick={() => {
                    setValue(value ? 0 : 1)
                }}
                style={{
                    userSelect: "none",
                    cursor: "pointer",
                    width: 40,
                    height: 40,
                    borderRadius: 8,
                    backgroundColor: value === 0 ? colors.grey2 : colors.purple,
                    color: value === 1 ? undefined : "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {value === 1 && (
                    <svg
                        width="15"
                        height="11"
                        viewBox="0 0 15 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M14.6861 0.322215C15.1046 0.751836 15.1046 1.44954 14.6861 1.87916L6.11567 10.6778C5.69719 11.1074 5.01758 11.1074 4.5991 10.6778L0.31386 6.27847C-0.10462 5.84885 -0.10462 5.15115 0.31386 4.72153C0.73234 4.29191 1.41195 4.29191 1.83043 4.72153L5.35906 8.34065L13.1729 0.322215C13.5914 -0.107405 14.271 -0.107405 14.6895 0.322215H14.6861Z"
                            fill="white"
                        />
                    </svg>
                )}
            </div>
        </div>
    )
}
