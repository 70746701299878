import { useNavigate } from "react-router-dom"
import { IsNode } from "../../reactor/AssertNode"

export function useRedirectTrailingSlash() {
    const navigate = useNavigate()

    if (
        !IsNode() &&
        window.location.pathname.length > 1 &&
        window.location.pathname.endsWith("/")
    ) {
        const newPathname = location.pathname.slice(0, -1)
        navigate(newPathname, { replace: true })
    }
}
