import React, { CSSProperties, useRef, useState } from "react"
import { EditableObject } from "../../../../../packages/editing/EditableObject"
import { EditablePosition } from "../../../../../packages/editing/EditablePosition"
import { EditableText } from "../../../../../packages/editing/EditableText"
import { useHover } from "../../../../../packages/hooks/useHover"
import { Localized } from "../../../../../packages/localization/Localized"
import { useContextualModal } from "../../../../../packages/modal/Modal"
import { ImageToUrl } from "../../../../../reactor/Types/File"
import { Markdown } from "../../../../../reactor/Types/Primitives"
import { JagFrame2x3 } from "../../assets/JagFrames"
import { GetPartyPackagesByVenueDto, PartyMeal, PartyPackageByVenue } from "../../client"
import { colors } from "../../colors"
import { usePageContext } from "../../Page"
import { CallToAction } from "../../sections/TextAndCallToAction"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { Animations } from "../Animations"

export function PackagePicker({
    packages,
    section,
    setPartyPackage,
    style,
}: {
    packages: GetPartyPackagesByVenueDto
    style?: CSSProperties
    section: {
        callUsToBookPackage: Localized<string>
        mealOptions?: Localized<string>
        packagePickerTitle: Localized<string>
        packagePickerSubtitle: Localized<Markdown>
        perChildText: Localized<string>
        choosePackageCallToAction?: Localized<string>
    }
    setPartyPackage?: (p: PartyPackageByVenue, mealOption?: PartyMeal) => void
}) {
    const [selectedIndex, setSelectedPackage] = useState(0)
    const [markedIndex, setMarkedIndex] = useState(-1)
    const selectedPackage = packages.partyPackages[selectedIndex]
    const { modal, showModal } = useContextualModal()
    const { locale } = usePageContext()
    const [mealsRef, setMealsRef] = useState<HTMLDivElement | null>(null)
    const callUsRef = useRef<HTMLDivElement | null>(null)
    const [cannotBookOnline, setCannotBookOnline] = useState(false)
    const localize = useLocalize()

    const showMeals =
        !cannotBookOnline &&
        selectedIndex === markedIndex &&
        selectedPackage.availableMeals.length > 0

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: 64,

                ...style,
            }}
        >
            <div
                style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <h2>
                    <EditableText
                        obj={section}
                        prop="packagePickerTitle"
                        defaultText="Party Package"
                        isLocalized={true}
                    />
                </h2>
                <EditableText
                    obj={section}
                    prop="packagePickerSubtitle"
                    defaultText="Select the package of your liking"
                    isLocalized={true}
                    isMarkdown={true}
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "90%",
                        marginTop: 32,
                        marginLeft: 16,
                        marginRight: 16,
                        backgroundColor: colors.grey3,
                        borderRadius: 8,
                        position: "relative",
                    }}
                >
                    {packages.partyPackages.map((p, i) => (
                        <EditableText
                            isLocalized={true}
                            obj={p}
                            prop="name"
                            onClick={() => {
                                setMarkedIndex(-1)
                                setCannotBookOnline(false)
                                setSelectedPackage(i)
                            }}
                            style={{
                                cursor: "pointer",
                                flex: 1,
                                textAlign: "center",
                                padding: 12,
                                zIndex: 2,
                                transition: "all 0.5s",
                                borderLeft:
                                    i > 0 && selectedIndex !== i - 1 && selectedIndex !== i
                                        ? "1px solid #ddd"
                                        : "1px solid #ddd0",
                            }}
                        />
                    ))}
                    <div
                        style={{
                            position: "absolute",
                            backgroundColor: "white",
                            borderRadius: 8,
                            transition: "all 0.2s",
                            left: `${selectedIndex * (100 / packages.partyPackages.length)}%`,
                            width: `${100 / packages.partyPackages.length}%`,
                            height: "100%",
                            padding: 12,
                            textAlign: "center",
                            zIndex: 1,
                            boxShadow: "2px 0px 8px rgba(42, 19, 78, 0.16)",
                        }}
                    ></div>
                </div>
                {modal}
                <EditableObject
                    style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyItems: "center",
                    }}
                    className="flex-column-reverse flex-md-row"
                    obj={selectedPackage}
                    typeName="GetPartyPackagesByVenuePartyPackagesDto"
                    actions={{
                        deleteThis() {
                            alert("Packages must be deleted from Studio")
                        },
                        async saveAsTemplate() {
                            const { SaveAsEditingTemplate } = await import(
                                "../../../../../packages/editing/EditingTemplatePicker"
                            )
                            await SaveAsEditingTemplate(
                                "GetPartyPackagesByVenuePartyPackagesDto",
                                selectedPackage,
                                showModal
                            )
                        },
                    }}
                >
                    <>
                        <EditableText
                            style={{
                                paddingTop: 32,
                                paddingLeft: 32,
                                flex: 1,
                                paddingRight: 16,
                                fontSize: 18,
                                width: 400,
                            }}
                            obj={selectedPackage}
                            isMarkdown={true}
                            isLocalized={true}
                            prop="detailedDescription"
                        />
                        <div
                            key={selectedPackage.id.valueOf()}
                            style={{
                                position: "relative",
                                width: 256,
                                height: 400,
                                marginTop: 32,
                            }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    color: "white",
                                    margin: "auto",
                                    top: 48,
                                    padding: 48,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <EditableText
                                    className="bold crazy-letters"
                                    style={{ fontSize: 34 }}
                                    obj={selectedPackage}
                                    prop="name"
                                    isLocalized={true}
                                    display={(s) => (
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            {Array.from(s.toUpperCase()).map((c) => (
                                                <div
                                                    style={{
                                                        transform: "rotate(10deg)",
                                                        marginRight: 1,
                                                    }}
                                                >
                                                    {c}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        fontSize: 30,
                                        marginTop: 24,
                                    }}
                                >
                                    <div className="bold" style={{ marginLeft: 8 }}>
                                        {new Intl.NumberFormat(locale.valueOf(), {
                                            style: "currency",
                                            maximumFractionDigits: 0,
                                            minimumFractionDigits: 0,
                                            currency: selectedPackage.currency,
                                        }).format(selectedPackage.pricePerChild.valueOf())}
                                    </div>
                                </div>
                                <EditableText
                                    style={{ marginBottom: 24 }}
                                    obj={section}
                                    prop="perChildText"
                                    defaultText="per child"
                                />
                                {section.choosePackageCallToAction && setPartyPackage && (
                                    <CallToAction
                                        section={section}
                                        marked={markedIndex === selectedIndex}
                                        prop="choosePackageCallToAction"
                                        onClick={() => {
                                            if (selectedPackage.cannotBookOnline) {
                                                setCannotBookOnline(true)
                                                setMarkedIndex(selectedIndex)
                                                setTimeout(
                                                    () =>
                                                        callUsRef.current?.scrollIntoView({
                                                            behavior: "smooth",
                                                            block: "center",
                                                        }),
                                                    100
                                                )
                                            } else if (selectedPackage.availableMeals.length) {
                                                if (selectedIndex === markedIndex)
                                                    setMarkedIndex(-1)
                                                else {
                                                    setMarkedIndex(selectedIndex)
                                                    mealsRef?.scrollIntoView({
                                                        behavior: "smooth",
                                                        block: "center",
                                                    })
                                                }
                                            } else {
                                                setPartyPackage(selectedPackage)
                                            }
                                        }}
                                    />
                                )}
                            </div>
                            <img
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                    zIndex: -1,
                                }}
                                src={JagFrame2x3(colors.darkPurple)}
                            />
                            {selectedPackage.sticker && (
                                <EditablePosition
                                    obj={selectedPackage}
                                    xProp="stickerX"
                                    yProp="stickerY"
                                >
                                    <img
                                        src={ImageToUrl(selectedPackage.sticker, { width: 96 })}
                                        style={{
                                            pointerEvents: "all",
                                            width: 96,
                                            height: 96,
                                            zIndex: 4,
                                            transform: "rotate(10deg)",
                                        }}
                                    />
                                </EditablePosition>
                            )}
                        </div>
                    </>
                </EditableObject>

                {cannotBookOnline && (
                    <div
                        ref={callUsRef}
                        style={{
                            maxWidth: 800,
                            height: 1000,
                            padding: 80,

                            textAlign: "center",
                        }}
                    >
                        <h1 style={{ color: colors.darkPurple }}>
                            <EditableText
                                obj={section}
                                prop="callUsToBookPackage"
                                defaultText="To book this package, you need to call or email us."
                            />
                        </h1>
                        <div style={{ fontSize: 24 }}>
                            {packages.venueContactInfo &&
                                localize(packages.venueContactInfo)
                                    ?.split("\n")
                                    .map((s) => <div>{s}</div>)}
                        </div>
                    </div>
                )}

                {setPartyPackage && (
                    <div ref={setMealsRef} style={{ visibility: showMeals ? "visible" : "hidden" }}>
                        <h2 style={{ textAlign: "center" }}>
                            <EditableText
                                obj={section}
                                prop="mealOptions"
                                defaultText="Meal options"
                            />
                        </h2>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "center",
                            }}
                        >
                            {selectedPackage.availableMeals.map((m, i) => (
                                <MealOption
                                    m={m}
                                    index={i}
                                    optionCount={selectedPackage.availableMeals.length}
                                    section={section}
                                    setPartyPackage={setPartyPackage}
                                    selectedPackage={selectedPackage}
                                    showMeals={showMeals}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
function MealOption({
    m,
    optionCount,
    section,
    setPartyPackage,
    selectedPackage,
    showMeals,
    index,
}: {
    m: PartyMeal
    optionCount: number
    section: {
        choosePackageCallToAction?: Localized<string>
    }
    setPartyPackage?: (p: PartyPackageByVenue, mealOption?: PartyMeal) => void
    selectedPackage: PartyPackageByVenue
    showMeals: boolean
    index: number
}) {
    const { hoverProps } = useHover()
    const localize = useLocalize()
    return (
        <div
            {...hoverProps}
            style={{
                borderRadius: 8,
                ...Animations.presentOptions(showMeals, index, optionCount),
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: 180,
                maxWidth: "45%",
                margin: 8,
                paddingBottom: 16,
                border: `1px solid ${colors.grey3}`,
            }}
        >
            <img
                style={{
                    width: "100%",
                    height: 120,
                    objectFit: "cover",
                    objectPosition: "center",
                    borderRadius: 7,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                }}
                src={m.image ? ImageToUrl(m.image, { width: 180 }) : undefined}
            />
            <div style={{ padding: 16, height: 96, textAlign: "center" }}>{localize(m.name)}</div>
            {section.choosePackageCallToAction && setPartyPackage && (
                <CallToAction
                    section={section}
                    prop="choosePackageCallToAction"
                    onClick={() => setPartyPackage(selectedPackage, m)}
                />
            )}
        </div>
    )
}
