import React from "react"
import { useNavigate } from "react-router-dom"
import { usePartyPackagesByVenue } from "../../client"
import { usePageContext } from "../../Page"
import { PackagePicker } from "./PackagePicker"
import { usePartyContext } from "./PartyContext"

export function PartyPackagePage() {
    const { venue } = usePageContext()
    const { setPartyPackage, section } = usePartyContext()
    const packages = usePartyPackagesByVenue(venue?.id ?? null, undefined, undefined)
    const navigate = useNavigate()
    return packages.data ? (
        <PackagePicker
            packages={packages.data}
            section={section}
            setPartyPackage={(partyPackage, mealOption) => {
                setPartyPackage(partyPackage, mealOption)
                if (partyPackage.disallowPartyExtras) {
                    navigate("../contact-info")
                } else {
                    navigate("../extras")
                }
            }}
        />
    ) : null
}
