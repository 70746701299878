import React from "react"
import { Color } from "../../../reactor/Types/Color"
import { Image, ImageToCSS } from "../../../reactor/Types/File"
import { EditableSections } from "../EditableSections"
import { Section } from "../Section"

Section(HorizontalStack)
/**
 * Displays a horizontal stack of sections.
 * @icon ellipsis-h
 */
function HorizontalStack(section: {
    /**
     * @default '[{ "type": "Placeholder", "id": "%UUID%" }, { "type": "Placeholder", "id": "%UUID%" }, { "type": "Placeholder", "id": "%UUID%" }]'
     * @expand
     */
    sections: Section[]

    /** How to lay out the sections */
    mode: "spaced" | "flush" | "fluid"

    /** How much space to put between the sections */
    gutterSize?: 0 | 1 | 2 | 3 | 4 | 5

    backgroundImage?: Image
    backgroundColor?: Color
}) {
    const { mode = "spaced" } = section
    const sections = (
        <EditableSections
            itemClassName="col-md"
            itemStyle={
                mode === "spaced"
                    ? () => ({
                          backgroundColor: section.backgroundColor,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                      })
                    : () => ({ flex: 1 })
            }
            innerItemStyle={mode === "flush" ? () => ({ flex: 1 }) : undefined}
            sections={section.sections}
            direction="row"
        />
    )
    const gutter = section.gutterSize ?? 5

    if (mode === "flush" || mode === "fluid") {
        return (
            <div
                style={{
                    backgroundColor: section.backgroundColor,
                    backgroundImage: section.backgroundImage
                        ? ImageToCSS(section.backgroundImage)
                        : undefined,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    padding: 32,
                }}
            >
                <div className={mode === "flush" ? "container" : "container-fluid"}>
                    <div
                        className="py-5"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {sections}
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div
                style={{
                    backgroundImage: section.backgroundImage
                        ? ImageToCSS(section.backgroundImage)
                        : undefined,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundColor: section.backgroundColor,
                    padding: 32,
                }}
            >
                <div className="container">
                    <div className={`row py-5 justify-content-center gx-${gutter}`}>{sections}</div>
                </div>
            </div>
        )
    }
}
