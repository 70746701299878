import React, { createContext, useContext } from "react"
import { useLocales as useLocalesClient } from "../../../studio/client"
import { Locale } from "../Locale"

export const LocalesContext = createContext<Locale[] | "loading" | "not set">("not set")

/**
 * Returns the list of locales defined in Reactor Studio on the client side.
 *
 * This is preferable over using `useLocales` from `client.ts`, since it will
 * avoid redundant requests.
 */
export function useLocalesContext() {
    const locales = useContext(LocalesContext)
    if (locales === "not set") {
        throw new Error(
            "Missing LocalesProvider - Did you forget to wrap your app in <LocalesProvider>?"
        )
    }
    if (locales === "loading") {
        return undefined
    }
    return locales
}

export function LocalesProvider({ children }: { children: React.ReactNode }) {
    const { data: locales } = useLocalesClient()
    return (
        <LocalesContext.Provider value={locales ?? "loading"}>{children}</LocalesContext.Provider>
    )
}
