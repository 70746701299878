import React from "react"
import { useDirtyContext } from "../../../../packages/editing/DirtyContext"
import { useEditableContext } from "../../../../packages/editing/EditableContext"
import { PlaceholderImageUrl } from "../../../../packages/editing/EditableImage"
import { EditableObject } from "../../../../packages/editing/EditableObject"
import { EditablePosition } from "../../../../packages/editing/EditablePosition"
import { GetType } from "../../../../reactor/ReflectionInfo"
import { ImageToUrl } from "../../../../reactor/Types/File"
import { Uuid } from "../../../../reactor/Types/Primitives/Uuid"
import { Image } from "../../../../reactor/Types/File"
import { useSelectionContext } from "../../../../packages/editing/SelectionContext"

export type Sticker = {
    readonly id: Uuid<"Sticker">
    x: number
    y: number
    image?: Image
}

export function Stickers({ stickers }: { stickers: Sticker[] }) {
    const { setDirty } = useDirtyContext()
    const { setSelectedObject } = useSelectionContext()
    const { editing } = useEditableContext()

    return (
        <>
            {stickers.map((sticker, i) => (
                <EditableObject
                    key={sticker.id.valueOf()}
                    obj={sticker}
                    typeName={"Sticker"}
                    actions={{
                        deleteThis: () => {
                            stickers.splice(i, 1)
                            setDirty()
                        },
                        saveAsTemplate: undefined,
                    }}
                >
                    <EditablePosition obj={sticker} xProp="x" yProp="y">
                        <img
                            onClick={() =>
                                editing ? setSelectedObject(sticker, GetType("Sticker")) : undefined
                            }
                            style={{ maxWidth: 100, maxHeight: 100 }}
                            src={
                                sticker.image
                                    ? ImageToUrl(sticker.image, { width: 100 })
                                    : PlaceholderImageUrl()
                            }
                        />
                    </EditablePosition>
                </EditableObject>
            ))}
        </>
    )
}
