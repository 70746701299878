import React from "react"
import { Localized } from "../../../../packages/localization/Localized"
import { Markdown } from "../../../../reactor/Types/Primitives"
import { usePartyPackagesByVenue } from "../client"
import { usePageContext } from "../Page"
import { PackagePicker } from "./party-booker/PackagePicker"
import { Section } from "../../../../packages/editing/Section"

Section(PartyPackageBrowser)
function PartyPackageBrowser(section: {
    packagePickerTitle: Localized<string>
    packagePickerSubtitle: Localized<Markdown>
    callUsToBookPackage: Localized<string>

    perChildText: Localized<string>
}) {
    const { venue } = usePageContext()
    if (!venue) return <></>

    const { data: packages } = usePartyPackagesByVenue(venue?.id ?? null, undefined, undefined)
    if (!packages) return <></>

    return (
        <PackagePicker
            style={{ marginTop: 0, marginBottom: 64 }}
            packages={packages}
            section={section}
        />
    )
}
