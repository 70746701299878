import React from "react"
import { EditableText } from "../../../../packages/editing/EditableText"
import { Localized } from "../../../../packages/localization/Localized"
import { GroupBy } from "../../../../reactor/Helpers"
import { usePageContext } from "../Page"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"

export function PleaseChooseAVenue({
    section,
}: {
    section: { pleaseChooseAVenue?: Localized<string> }
}) {
    const { venues, page } = usePageContext()
    const localize = useLocalize()

    const countries = GroupBy(venues, (v) => v.country)

    return (
        <div style={{ padding: 64, width: "100%", maxWidth: 1200, margin: "0 auto" }}>
            <h2>
                <EditableText
                    obj={section}
                    prop="pleaseChooseAVenue"
                    defaultText="Please choose a venue"
                />
            </h2>
            {countries.map((c) => (
                <div>
                    <h3>{c.key}</h3>
                    <ul>
                        {c.values.map((v) => (
                            <li>
                                <a href={`/${v.locale}/${v.slug}/${page.slugs[0] ?? ""}`}>
                                    {localize(v.name)}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    )
}
