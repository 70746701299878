import React from "react"
import { useNavigate } from "react-router-dom"
import { MealPicker } from "./MealPicker"
import { usePartyContext } from "./PartyContext"

export function PartyMealPage() {
    const { partyPackage, setMealOption, mealOption, section } = usePartyContext()
    const navigate = useNavigate()
    return partyPackage ? (
        <MealPicker
            partyPackage={partyPackage}
            section={section}
            setMealOption={(mealOption2) => {
                setMealOption(mealOption2)
                if (partyPackage?.disallowPartyExtras) {
                    navigate("../contact-info")
                } else {
                    navigate("../extras")
                }
            }}
            mealOption={mealOption}
        />
    ) : null
}
