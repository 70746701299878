import ReactMarkdown, { ReactMarkdownPropsBase } from "react-markdown"
import { Markdown, Uuid } from "../../reactor"
import React from "react"
import { Localized } from "../localization/Localized"
import { useLocalize } from "../localization/client-side/useLocalize"
import { useMarkdownSnippet } from "../../studio/client"

/**
 * Displays a Markdown text, downloading referenced snippets as needed.
 * @param param0
 * @returns
 */
export function MarkdownView({
    value: markdown,
    renderers: additionalRenderers = {},
}: {
    value: Markdown | string | Localized<Markdown | string> | undefined
    renderers?: ReactMarkdownPropsBase["renderers"]
}) {
    const localize = useLocalize()
    if (markdown && typeof markdown === "object") {
        markdown = localize(markdown as any)
    }

    return (
        <ReactMarkdown renderers={{ ...renderers, ...additionalRenderers }}>
            {markdown as any}
        </ReactMarkdown>
    )
}

// Make images in Markdown not render outside their container
const renderers = {
    image: ({ alt, src, title }: { alt?: string; src?: string; title?: string }) => {
        if (alt && alt.startsWith("#MARKDOWN_SNIPPET")) {
            const id = src as any as Uuid<"MarkdownSnippet">
            return <MarkdownSnippetView id={id} />
        }

        if (alt && alt.startsWith("#IFRAME")) {
            // Styles are encoded like this: #IFRAME{"width":100,"height":400}
            const iframeStyle = alt.length > 7 ? JSON.parse(alt.slice(7)) : {}

            return (
                <iframe
                    src={src}
                    style={{ width: "100%", maxWidth: 480, height: 500, ...iframeStyle }}
                />
            )
        }

        return <img alt={alt} src={src} title={title} style={{ maxWidth: "100%" }} />
    },
    link(props: any) {
        const href = props.href
        if (href.startsWith("new-tab/")) {
            return (
                <a href={"https://" + href.substr(8)} target="_blank">
                    {props.children}
                </a>
            )
        }

        return <a {...props} />
    },
}

function MarkdownSnippetView({ id }: { id: Uuid<"MarkdownSnippet"> }) {
    const { data: snippet } = useMarkdownSnippet(id)
    return <MarkdownView value={snippet?.hydratedSnippet} />
}
