import React from "react"
import { GetParent } from "../Ancestry"
import { useDirtyContext } from "../DirtyContext"
import { useEditableContext } from "../EditableContext"
import { PickEditingTemplate } from "../EditingTemplatePicker"
import { useContextualModal } from "../../modal/Modal"
import { SSRHook } from "../../ssr/SSRHook"
import { Section } from "../Section"

Section(Placeholder)
/** A box that represents a placeholder for other content.
 *
 *  Displays a "Replace" button when editing, which allows the user to replace
 *  the placeholder with another section. Also displays a "Delete" button, which
 *  removes the placeholder from the page.
 */
function Placeholder(section: {}) {
    const { editing } = useEditableContext()
    const { setDirty } = useDirtyContext()
    const { modal, showModal } = useContextualModal<Section | undefined>()

    return (
        <div
            style={{
                minWidth: 200,
                minHeight: editing ? 200 : undefined,
                borderRadius: 32,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                border: editing ? "2px dashed #aaf" : undefined,
                color: "#aaf",
            }}
        >
            {editing ? (
                <div
                    onClick={async () => {
                        const parent = GetParent(
                            Array.from(SSRHook.editableResources),
                            section
                        )?.obj

                        const key =
                            parent &&
                            typeof parent === "object" &&
                            Object.keys(parent).find((k) => parent[k] === section)
                        if (key) {
                            const newSection = await PickEditingTemplate("Section", showModal)
                            if (newSection) {
                                parent[key] = newSection
                                setDirty()
                            }
                        }
                    }}
                    style={{
                        cursor: "pointer",
                        borderRadius: 8,
                        padding: 16,
                        backgroundColor: "#ddf2",
                    }}
                >
                    {modal}
                    Replace
                </div>
            ) : undefined}

            {editing ? (
                <div
                    onClick={async () => {
                        const parent = GetParent(
                            Array.from(SSRHook.editableResources),
                            section
                        )?.obj

                        const key =
                            parent &&
                            typeof parent === "object" &&
                            Object.keys(parent).find((k) => parent[k] === section)
                        if (key) {
                            if (parent instanceof Array) {
                                parent.splice(parent.indexOf(section), 1)
                            } else {
                                parent[key] = undefined
                            }
                            setDirty()
                        }
                        setDirty()
                    }}
                    style={{
                        cursor: "pointer",
                        marginLeft: 32,
                        borderRadius: 8,
                        padding: 16,
                        backgroundColor: "#ddf2",
                    }}
                >
                    Delete
                </div>
            ) : undefined}
        </div>
    )
}
