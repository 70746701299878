import React from "react"
import { Link } from "react-router-dom"
import { EditableText } from "../../../../packages/editing/EditableText"
import { useHover } from "../../../../packages/hooks/useHover"
import type { FooterLink } from "../../api/web/MenuAPI"
import { ChevronRight } from "../assets/Chevrons"
import { colors } from "../colors"
import { Section } from "../../../../packages/editing/Section"

Section(LinksSection)
function LinksSection(section: {
    /**
     * @expand
     */
    links: FooterLink[]
}) {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div
                    className="col"
                    style={{
                        maxWidth: 720,
                        paddingTop: 32,
                        paddingBottom: 64,
                        fontSize: 22,
                    }}
                >
                    {section.links.map((x) => (
                        <LinkRow link={x} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export function LinkRow(props: { link: FooterLink }) {
    const { hover, hoverProps } = useHover()

    return (
        <Link to={props.link.link}>
            <div
                {...hoverProps}
                className="bold"
                style={{
                    cursor: "pointer",
                    paddingTop: 32,
                    paddingBottom: 8,
                    borderBottom: `1px solid ${colors.grey2}`,
                    display: "flex",
                    flexDirection: "row",
                    justifyItems: "center",
                }}
            >
                <div
                    style={{
                        flex: 1,
                        transform: hover ? "scale(1.05)" : undefined,
                        transition: "all 0.2s",
                        transformOrigin: "left",
                        color: hover ? colors.pinkFlirt : colors.darkPurple,
                    }}
                >
                    <EditableText obj={props.link} prop="text" isLocalized={true} />
                </div>
                <ChevronRight fill={hover ? colors.pinkFlirt : undefined} />
            </div>
        </Link>
    )
}
