import React from "react"
import { useNavigate } from "react-router-dom"
import { usePartyPackagesByVenue } from "../../client"
import { usePageContext } from "../../Page"
import { PackagePicker } from "./PackagePicker"
import { usePartyContext } from "./PartyContext"
import { LocalDate } from "../../../../../reactor/Types/Primitives/LocalDateTime"

export function PartyPackagePage() {
    const { venue, timeZone } = usePageContext()
    const { setPartyPackage, partyPackage, section, date, time } = usePartyContext()
    const packages = usePartyPackagesByVenue(
        venue?.id ?? null,
        date ? LocalDate.fromDate(date, timeZone).valueOf() : null,
        time
    )
    const navigate = useNavigate()
    return packages.data ? (
        <PackagePicker
            packages={packages.data}
            section={section}
            setPartyPackage={(partyPackage2) => {
                setPartyPackage(partyPackage2)
                if (partyPackage2.availableMeals.length > 0) {
                    navigate("../meal")
                } else if (partyPackage2.disallowPartyExtras) {
                    navigate("../contact-info")
                } else {
                    navigate("../extras")
                }
            }}
            partyPackage={partyPackage}
        />
    ) : null
}
