import React from "react"
import { Widget } from "./Widget"
import { ColorStyles } from "../ui"
import { Property } from "../../reactor"
import { IsModal } from "./WidgetTypeHelpers"

type WidgetProps<T> = {
    value: T

    isRoot?: boolean

    /** If true, this widget is part of the selected master item in a
     * master-detail view. This means the widget should display a compact
     * version of itself, as the details will be in the detail view.
     *
     * For example, ItemWidget will not display its chips when this is true.
     * */
    hideDetails?: boolean

    /** If specified, this widget is at the root of a table cell, and this is the row/column index.
     * */
    tableCell?: { row: number; column: number }

    /**
     * If this widget is currently being displayed in place of a property
     * editor, this is the object that contains the property. Information
     * about the property is in `property`.
     */
    obj?: any

    /** If this widget is currently being displayed in place of a property
     * editor, this is information about the property on `obj`.
     */
    property?: Property
}

type WidgetFunc<T extends Widget> = (props: WidgetProps<T>) => JSX.Element | undefined

const widgets: WidgetFunc<any>[] = []

/** Renders a widget based on the provided props.
 *
 *  Will loop over the registered widgets to find one that can consume the provided props, and
 *  render that widget.
 */
export function WidgetView<T>(props: WidgetProps<T>): JSX.Element {
    if (props.value === undefined) return <></>

    // Modals are not rendered in the normal widget tree, so we don't want to
    // display them here.
    if (props.value && IsModal(props.value as any)) return <></>

    for (const widget of widgets) {
        const res = widget(props)
        if (res) return res
    }

    try {
        return <div style={{ color: "orangered" }}>{JSON.stringify(props)}</div>
    } catch {
        return (
            <div style={{ color: "orangered" }}>(There was a problem displaying this widget)</div>
        )
    }
}
export function RegisterWidget<T extends Widget>(type: string | undefined, widget: WidgetFunc<T>) {
    if (type) {
        widgets.push((props) => {
            if (props.value === null) return
            if (props.value instanceof Array) return
            if (typeof props.value !== "object") return
            if (!("type" in props.value)) return

            if (props.value.type !== type) return
            return widget(props)
        })
    } else {
        widgets.push(widget)
    }
}

RegisterWidget(undefined, ({ value, tableCell }) => {
    if (typeof value === "string") {
        if (tableCell)
            return (
                <div
                    style={{
                        fontSize: 14,
                        color: tableCell ? ColorStyles.gray[500] : undefined,
                    }}
                >
                    {value}
                </div>
            )
        else return <div style={{ marginLeft: 16, marginRight: 16 }}>{value}</div>
    }
})
RegisterWidget(undefined, ({ value, tableCell }) => {
    if (typeof value === "number") {
        if (tableCell)
            return (
                <div
                    style={{
                        fontSize: 14,
                        color: tableCell ? ColorStyles.gray[500] : undefined,
                    }}
                >
                    {value}
                </div>
            )
        else return <div style={{ marginLeft: 16, marginRight: 16 }}>{value.toString()}</div>
    }
})

RegisterWidget(undefined, ({ value }) => {
    if (value === null) return <code>null</code>
})
RegisterWidget(undefined, (props) => {
    if (props.value === undefined) return <code>undefined</code>
})
