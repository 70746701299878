import { useEffect } from "react"

/** Displays the generic browser warning dialog before navigating away from the page. */
export function useConfirmNavigation(shouldShowPrompt: boolean) {
    useEffect(() => {
        function handler(e: BeforeUnloadEvent) {
            if (shouldShowPrompt) {
                e.preventDefault()
                e.returnValue =
                    "Leaving the page will discard unsaved changes. Do you want to continue?"
                return true
            }
        }
        if (shouldShowPrompt) useConfirmNavigation.active++
        window.addEventListener("beforeunload", handler)
        return () => {
            if (shouldShowPrompt) useConfirmNavigation.active--
            window.removeEventListener("beforeunload", handler)
        }
    }, [shouldShowPrompt])
}
/** The number of active hooks that want the user to confirm navigation before navigating to a new
 * context that will lose the editing state. If nonzero, a prompt should be displayed to confirm any
 * such navigation. */
useConfirmNavigation.active = 0
