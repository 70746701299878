import { useEffect, useState } from "react"
import { IsNode } from "../../../reactor/AssertNode"
import { defaults } from "../../../studio/client"
import { LocaleKey } from "../Locale"

const defaultLocale = LocaleKey.en
let currentLocale: LocaleKey | "default" = "default"
const listeners: ((locale: LocaleKey) => void)[] = []

if (!IsNode()) {
    // setTimeout to avoid touching `default` before it's initialized
    setTimeout(() => {
        const setting = window.localStorage.getItem("studio-locale")
        if (setting !== null) {
            setStudioLocaleKey(LocaleKey(setting))
        } else {
            setStudioLocaleKey(defaultLocale)
        }
    }, 0)
}

/** Returns the current client-side Studio locale, and subscribes to changes to it. */
export function useStudioLocale(
    /** Called when the locale changes. Since locale is passed as header, not an
     * explicit request parameter, some resources may require manual
     * invalidation to refresh correctly. */
    callback?: (locale: LocaleKey) => void
) {
    const [locale, setLocale] = useState(currentLocale)
    useEffect(() => {
        listeners.push((newLocale) => {
            setLocale(newLocale)
            if (callback) callback(newLocale)
        })
        return () => {
            const i = listeners.indexOf(setLocale)
            if (i >= 0) listeners.splice(i, 1)
        }
    }, [])
    if (locale === "default") return defaultLocale
    return locale
}

/** Sets the current client-side Studio locale key.
 *
 *  This is stored in local storage, so it will persist across sessions.
 */
export function setStudioLocaleKey(locale: LocaleKey) {
    if (!IsNode()) {
        window.localStorage.setItem("studio-locale", locale.valueOf())
        currentLocale = locale
        defaults.headers["Accept-Language"] = locale.valueOf()
    }

    for (const listener of listeners) listener(locale)
}

/** Returns the current locale key as a string.
 *  To subscribe to changes, use `useLocale`.
 */
export function getStudioLocale(): string {
    return getStudioLocaleKey().valueOf()
}

/** Returns the current locale key.
 * To subscribe to changes, use `useLocale`.
 */
export function getStudioLocaleKey(): LocaleKey {
    if (currentLocale === "default") return defaultLocale
    else return currentLocale
}
