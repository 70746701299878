import React from "react"
import { useState, useEffect } from "react"

/**
 * Uses code splitting to import a component on demand, instead of including it
 * in the main bundle.
 */
export function Lazy(props: {
    /**
     * Whether to load the target component. Defaults to true.
     */
    load?: boolean
    target: () => Promise<React.FunctionComponent<any>>
    /**
     * The fallback component to render while the target is loading or not
     * loaded.
     */
    fallback?: React.FunctionComponent
}) {
    const [comp, setComponent] = useState<{ comp: React.FunctionComponent<any> } | undefined>()

    useEffect(() => {
        if (props.load !== false) {
            const res = props.target()
            void res.then((comp) => {
                setComponent({ comp })
            })
        }
    }, [props.load])

    if (comp) {
        return <comp.comp />
    } else if (props.fallback) {
        return <props.fallback />
    } else {
        return <div>Loading...</div>
    }
}
