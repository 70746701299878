import React, { useState } from "react"
import { CSSProperties, useEffect, useRef } from "react"
import { Uuid } from "../../reactor/Types/Primitives/Uuid"
import { server } from "../../server"
import lottie from "lottie-web"

export function Lottie({ file, style }: { file: Uuid<"File">; style?: CSSProperties }) {
    const [animationData, setData] = useState<any>()

    useEffect(() => {
        async function go() {
            const res = await fetch(`${server()}/api/files/${file}`)
            if (res.status !== 200) throw new Error()
            return setData(await res.json())
        }
        void go()
    }, [file])

    const element = useRef<HTMLDivElement>(null)
    const lottieInstance = useRef<any>()

    useEffect(() => {
        if (element.current && animationData) {
            lottieInstance.current = lottie.loadAnimation({
                animationData,
                container: element.current,
            })
        }
    }, [animationData])

    return (
        <div>
            <div style={style} ref={element} />
        </div>
    )
}
