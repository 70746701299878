import {
    GetTypeAlias,
    IsAnyType,
    IsArrayType,
    IsBooleanType,
    IsDateType,
    IsFileType,
    IsIntersectionType,
    IsNullType,
    IsNumberType,
    IsObjectType,
    IsStringType,
    IsUndefinedType,
    IsUnionType,
    IsUnknownType,
    IsVoidType,
    Type,
    TypeToString,
    GetTypeValidator,
} from "./Type"

const cache = new WeakMap<Type & object, (value: any) => boolean>()
const noValidateCache = new WeakMap<Type & object, (value: any) => boolean>()

/** Fast type checker using generated functions (which in turn will be JIT-ed by
 *  the JavaScript VM if used often) stored in a cache for each type.
 *
 *  Determines whether the value matches the type, does not provide any
 *  diagnostics. To get a diagnostic in case of error, use `MatchesType` (which
 *  uses `MatchesTypeJIT` internally to get a fast result in case of successful
 *  match).
 *
 *  Produces a JITed function for each type, stored in a cache.
 */
export function MatchesTypeJIT(type: Type & object, value: any, validate = true): boolean {
    if (validate) {
        let jit = cache.get(type)
        if (!jit) {
            jit = JIT(type, true)
            cache.set(type, jit)
        }
        return jit(value)
    } else {
        let jit = noValidateCache.get(type)
        if (!jit) {
            jit = JIT(type, false)
            noValidateCache.set(type, jit)
        }
        return jit(value)
    }
}

function JIT(type: Type, validate: boolean): (value: any) => boolean {
    const args: any[] = []
    const argNames: string[] = []
    function registerArg(value: any, argName: string) {
        args.push(value)
        argNames.push(argName)
        return argName
    }

    let varCount = 0
    function variable() {
        varCount++
        return "v" + varCount
    }

    const inlineFuncs = new Map<string, string>()

    function exp(t: Type, p: string, ignoreValidator = false, ignoreAlias = false): string {
        const alias = GetTypeAlias(t)
        if (!ignoreAlias && alias) {
            if (!inlineFuncs.has(alias)) {
                inlineFuncs.set(alias, "(temp)") // prevent infinite recursion
                inlineFuncs.set(
                    alias,
                    `function ${alias}(v) { return ${exp(t, "v", ignoreValidator, true)} }`
                )
            }
            return `${alias}(${p})`
        }

        if (validate && !ignoreValidator && typeof t === "object" && "validator" in t) {
            const arg = registerArg(GetTypeValidator(t), t.alias + "_validator")
            return `( ${exp(t, p, true, ignoreAlias)} && try_validate(${p},${arg}))`
        }
        if (IsFileType(t)) {
            // Check that the value is a valid UUID file handle
            return `((typeof ${p}) === 'string' && /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i.test(${p}))`
        }
        if (IsStringType(t)) {
            if (t === "string" || t.string === null) {
                return `((typeof ${p}) === 'string')`
            } else {
                return `(${p} === ${JSON.stringify(t.string)})`
            }
        }
        if (IsBooleanType(t)) {
            if (t === "boolean" || t.boolean === null) {
                return `((typeof ${p}) === 'boolean')`
            } else {
                return `(${p} === ${JSON.stringify(t.boolean)})`
            }
        }
        if (IsNumberType(t)) {
            if (t === "number") {
                return `((typeof ${p}) === 'number')`
            }
            const isInteger = `((${p}|0) === ${p})`
            if (t === "integer") {
                return `((typeof ${p}) === 'number' && ${isInteger})`
            }

            let ee =
                t.number === null
                    ? `((typeof ${p}) === 'number')`
                    : `(${p} === ${JSON.stringify(t.number)})`

            if (t.minValue !== undefined) ee = `( ${ee} && ${p} >= ${t.minValue})`
            if (t.maxValue !== undefined) ee = `( ${ee} && ${p} <= ${t.maxValue})`
            if (t.integer) ee = `( ${ee} && ${isInteger} )`

            return ee
        }
        if (IsIntersectionType(t)) {
            return "(" + t.intersection.map((x) => exp(x, p)).reduce(and) + ")"
        }
        if (IsUnionType(t)) {
            return t.union.length
                ? `(${t.union.map((x) => exp(x, p)).reduce(or)})`
                : // An empty union has no hits, since the rule is that
                  // the type must match at least one of the union types.
                  `false`
        }
        if (IsObjectType(t)) {
            const props = t.props.map((prop) =>
                prop.optional
                    ? `((${p + `[${JSON.stringify(prop.name)}]`} === undefined) || ${exp(
                          prop.type,
                          p + `[${JSON.stringify(prop.name)}]`
                      )})`
                    : `('${prop.name}' in ${p} && ${exp(
                          prop.type,
                          p + `[${JSON.stringify(prop.name)}]`
                      )})`
            )

            return `(${p} !== null && (typeof ${p}) === 'object'${
                props.length ? " && " + props.reduce(and) : ""
            })`
        }
        if (IsUndefinedType(t)) {
            return `(${p} === undefined)`
        }
        if (IsNullType(t)) {
            return `(${p} === null)`
        }
        if (IsAnyType(t) || IsUnknownType(t)) {
            return `true`
        }
        if (IsDateType(t)) {
            return `(${p} instanceof Date)`
        }
        if (IsArrayType(t)) {
            const v = variable()
            return `((${p} instanceof Array) && ${p}.every(${v} => ${exp(t.array, v)}))`
        }
        if (IsVoidType(t)) {
            return `(${p} === undefined)`
        }

        throw new Error("Unhandled type in MatchesTypeJIT: " + TypeToString(t))
    }

    const res = exp(type, "v")
    const e = `(function(${argNames.join(",")})
{
    function try_validate(v, validator) {
        try {
            validator(v)
        }
        catch(e) {
            return false
        }
        return true
    }
    ${Array.from(inlineFuncs.values()).join("\n")}
    return function(v) { return ${res} }
})`
    // eslint-disable-next-line no-eval
    return eval(e)(...args)
}

function and(a: string, b: string) {
    return a + " && " + b
}
function or(a: string, b: string) {
    return a + " || " + b
}
