import { useState } from "react"
import { css } from "@emotion/react"
import moment from "moment"
import { EditableText } from "../../../../packages/editing/EditableText"
import { Localized } from "../../../../packages/localization/Localized"
import { OpeningInfo } from "../client"
import { usePageContext } from "../Page"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { PleaseChooseAVenue } from "./PleaseChooseAVenue"
import { VenueSingleTicketPriceCalendar } from "./VenueSingleTicketPriceCalendar"
import { Section } from "../../../../packages/editing/Section"
import { SplitViewImage, SplitView } from "../SplitView"
import { Image, ImageToUrl } from "../../../../reactor/Types/File"
import { Collapsible } from "../views/Collapsible"
import { Chevron } from "../assets/Chevrons"
import { colors } from "../colors"
import { OpeningSpecialDate } from "../../model/OpeningCalendar"

Section(VenueInfo)
function VenueInfo(section: {
    /**
     * Image to show to show on the right side of the opening hours. Will be hidden on mobile to keep focus on content.
     */
    image?: Image

    /**
     * @default '{"en": "Closed", "no": "Stengt", "sv": "Stängt", "dk": "Lukket"}'
     */
    closed: Localized<string>

    /**
     * @default '{"en": "Open today", "no": "Åpent i dag", "sv": "Öppet i dag", "dk": "Åbent i dag"}'
     */
    todaysOpeningHours: Localized<string>

    /**
     * @default '{"en": "Regular opening hours", "no": "Våre åpningstider", "sv": "Våra öppettider", "dk": "Vores åbningstider"}'
     */
    regularOpeningHours: Localized<string>

    specialDays: Localized<string>

    pleaseChooseAVenue: Localized<string>

    /**
     * @default '{"en": "Phone number", "no": "Telefon", "sv": "Telefon", "dk": "Telefon"}'
     */
    phoneNumber: Localized<string>

    /**
     * @default '{"en": "Email", "no": "E-post"}'
     */
    email: Localized<string>

    /**
     * @default '{"en": "Address", "no": "Adresse", "sv": "Adress", "dk": "Adresse"}'
     */
    address: Localized<string>
    /**
     * @default '{"en": "Contact us", "no":"Kontakt oss", "sv": "Kontakta oss", "dk": "Kontakt os"}'
     */
    contactUs: Localized<string>
}) {
    const { venue } = usePageContext()

    const localize = useLocalize()
    if (!venue) return <PleaseChooseAVenue section={section} />

    const openingCalendar = venue?.openingCalendar ?? ({} as OpeningInfo)

    const anyExceptions =
        openingCalendar.mondays ||
        openingCalendar.tuesdays ||
        openingCalendar.wednesdays ||
        openingCalendar.thursdays ||
        openingCalendar.fridays ||
        openingCalendar.saturdays ||
        openingCalendar.sundays

    const [collapsed, setCollapsed] = useState(true)

    return (
        <>
            <SplitView
                columns={[
                    {
                        render: () => (
                            <div
                                css={css`
                                    display: flex;
                                    flex-direction: column;
                                `}
                            >
                                <div css={css({ marginBottom: 32 })}>
                                    <h1
                                        css={css`
                                            line-height: normal;
                                            margin-bottom: 8px;
                                            margin-top: 0;
                                        `}
                                    >
                                        {localize(venue.name)}
                                    </h1>
                                    <strong
                                        css={css`
                                            color: ${colors.purple};
                                        `}
                                    >
                                        {localize(section.todaysOpeningHours)}:{" "}
                                        {venue.openToday === "Closed"
                                            ? localize(section.closed)
                                            : `${venue.openToday.opening} - ${venue.openToday.closing}`}
                                    </strong>
                                </div>
                                <div css={css({ marginBottom: 32 })}>
                                    <div>
                                        <h4>
                                            <EditableText
                                                obj={section}
                                                prop="regularOpeningHours"
                                                defaultText="Regular opening hours"
                                            />
                                        </h4>
                                        {openingCalendar.mondays && (
                                            <OpeningHoursLine
                                                label={localize(weekdaysLong[0])}
                                                value={OpeningHoursText(
                                                    openingCalendar.mondays,
                                                    localize
                                                )}
                                            />
                                        )}
                                        {openingCalendar.tuesdays && (
                                            <OpeningHoursLine
                                                label={localize(weekdaysLong[1])}
                                                value={OpeningHoursText(
                                                    openingCalendar.tuesdays,
                                                    localize
                                                )}
                                            />
                                        )}
                                        {openingCalendar.wednesdays && (
                                            <OpeningHoursLine
                                                label={localize(weekdaysLong[2])}
                                                value={OpeningHoursText(
                                                    openingCalendar.wednesdays,
                                                    localize
                                                )}
                                            />
                                        )}
                                        {openingCalendar.thursdays && (
                                            <OpeningHoursLine
                                                label={localize(weekdaysLong[3])}
                                                value={OpeningHoursText(
                                                    openingCalendar.thursdays,
                                                    localize
                                                )}
                                            />
                                        )}
                                        {openingCalendar.fridays && (
                                            <OpeningHoursLine
                                                label={localize(weekdaysLong[4])}
                                                value={OpeningHoursText(
                                                    openingCalendar.fridays,
                                                    localize
                                                )}
                                            />
                                        )}
                                        {openingCalendar.saturdays && (
                                            <OpeningHoursLine
                                                label={localize(weekdaysLong[5])}
                                                value={OpeningHoursText(
                                                    openingCalendar.saturdays,
                                                    localize
                                                )}
                                            />
                                        )}
                                        {openingCalendar.sundays && (
                                            <OpeningHoursLine
                                                label={localize(weekdaysLong[6])}
                                                value={OpeningHoursText(
                                                    openingCalendar.sundays,
                                                    localize
                                                )}
                                            ></OpeningHoursLine>
                                        )}
                                        <OpeningHoursLine
                                            label={
                                                anyExceptions
                                                    ? localize(allOtherDays)
                                                    : localize(allDays)
                                            }
                                            value={OpeningHoursText(
                                                openingCalendar.defaultDay,
                                                localize
                                            )}
                                        />
                                    </div>
                                </div>
                                <div css={css({ marginBottom: 32 })}>
                                    {openingCalendar.specialDates.length === 0 ? null : (
                                        <div
                                            css={css`
                                                display: flex;
                                                flex-direction: column;
                                            `}
                                        >
                                            <h4
                                                role="button"
                                                aria-label={`${localize(show)} ${localize(
                                                    section.specialDays
                                                )}`}
                                                onClick={() => setCollapsed(!collapsed)}
                                                css={css`
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: space-between;
                                                `}
                                            >
                                                <EditableText
                                                    obj={section}
                                                    prop="specialDays"
                                                    defaultText="Special days"
                                                />
                                                <Chevron
                                                    direction={collapsed ? "down" : "up"}
                                                    color="darkPurple"
                                                />
                                            </h4>
                                            <Collapsible collapsed={collapsed}>
                                                {openingCalendar.specialDates.map((sd) => (
                                                    <OpeningHoursLine
                                                        key={`${sd.month}${
                                                            sd.dayOfMonth
                                                        }${sd.years.join("")}`}
                                                        label={
                                                            sd.description
                                                                ? `${localize(sd.description)}${
                                                                      sd.end
                                                                          ? ` (${formatSpecialDate(
                                                                                sd
                                                                            )})`
                                                                          : ""
                                                                  }`
                                                                : `${sd.dayOfMonth} / ${sd.month} ${
                                                                      sd.years.length
                                                                          ? sd.years.join(",")
                                                                          : ""
                                                                  }`
                                                        }
                                                        value={OpeningHoursText(
                                                            sd.openingInfo,
                                                            localize
                                                        )}
                                                    />
                                                ))}
                                            </Collapsible>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <h4>
                                            <EditableText
                                                obj={section}
                                                prop="contactUs"
                                                defaultText=""
                                            />
                                        </h4>
                                        <div>
                                            <EditableText
                                                obj={section}
                                                prop="address"
                                                defaultText=""
                                                style={{ display: "inline" }}
                                            />
                                            : {venue.streetAddress},{" "}
                                            <span
                                                css={css`
                                                    white-space: nowrap;
                                                `}
                                            >
                                                {venue.postalCode}
                                            </span>
                                        </div>
                                        <div>
                                            <EditableText
                                                obj={section}
                                                prop="phoneNumber"
                                                defaultText=""
                                                style={{ display: "inline" }}
                                            />
                                            : {venue.phoneNumber}
                                        </div>
                                        <div>
                                            <EditableText
                                                obj={section}
                                                prop="email"
                                                defaultText=""
                                                style={{ display: "inline" }}
                                            />
                                            : <a href={`mailto:${venue.email}`}>{venue.email}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ),
                    },
                    {
                        hideOnMobile: true,
                        render: () =>
                            section.image ? (
                                <SplitViewImage
                                    align="right"
                                    fullwidthOnMobile={true}
                                    cropPercentageOnMobile={20}
                                    src={ImageToUrl(section.image, { width: 600 })}
                                />
                            ) : (
                                <></>
                            ),
                    },
                ]}
            />
            <VenueSingleTicketPriceCalendar venue={venue} />
        </>
    )
}

export function OpeningHoursText(
    oi: OpeningInfo,
    localize: (localized: Localized<string>) => string | undefined
) {
    if (oi === "Closed") {
        return localize({
            en: "Closed",
            de: "Geschlossen",
            da: "Lukket",
            no: "Stengt",
            sv: "Stängt",
        })
    } else {
        return `${oi.opening} - ${oi.closing}`
    }
}

export function OpeningHoursLine({ label, value }: { label: string; value?: string }) {
    return (
        <div
            css={css`
                display: flex;
                justify-content: space-between;
            `}
        >
            <div>{label}:</div> <div>{value}</div>
        </div>
    )
}

const weekdaysLong: Localized<string>[] = [
    { en: "Monday", no: "Mandag", sv: "Måndag", da: "Mandag", de: "Montag" },
    { en: "Tuesday", no: "Tirsdag", sv: "Tisdag", da: "Tirsdag", de: "Dienstag" },
    { en: "Wednesday", no: "Onsdag", sv: "Onsdag", da: "Onsdag", de: "Mittwoch" },
    { en: "Thursday", no: "Torsdag", sv: "Torsdag", da: "Torsdag", de: "Donnerstag" },
    { en: "Friday", no: "Fredag", sv: "Fredag", da: "Fredag", de: "Freitag" },
    { en: "Saturday", no: "Lørdag", sv: "Lördag", da: "Lørdag", de: "Samstag" },
    { en: "Sunday", no: "Søndag", sv: "Söndag", da: "Søndag", de: "Sonntag" },
]

const allOtherDays: Localized<string> = {
    en: "All other days",
    no: "Alle andre dager",
    sv: "Alla andra dagar",
    da: "Alle andre dage",
    de: "Alle anderen Tage",
}

const allDays: Localized<string> = {
    en: "All days",
    no: "Alle dager",
    sv: "Alla dagar",
    da: "Alle dage",
    de: "Alle Tage",
}

const show: Localized<string> = {
    en: "Show",
    no: "Vis",
    sv: "Visa",
    da: "Vis",
    de: "Zeigen",
}

/**
 * Helper function to format a string representation of special date with optional end date
 */
function formatSpecialDate(sd: OpeningSpecialDate) {
    const outputFormat = "DD.MM"

    return `${moment(`${sd.month} ${sd.dayOfMonth}`).format(outputFormat)}${
        sd.end ? ` - ${moment(`${sd.end.month} ${sd.end.dayOfMonth}`).format(outputFormat)}` : ""
    }`
}
