import { IsNode } from "../../reactor/AssertNode"

let light = true
export const SidebarTheme = {
    get light() {
        return light
    },
    set light(value) {
        if (!IsNode() && value) {
            document.head.insertAdjacentHTML(
                "beforeend",
                `<style>::-webkit-calendar-picker-indicator {
            filter: invert(1);
        }</style>`
            )
        }
        light = value
    },
    /** A CSS background string.
     *
     *  Any combination of CSS backgrounds is supported, for example:
     *
     *  `"linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url('/static/bg.png") no-repeat center center/cover"`
     */
    background: undefined as string | undefined,
}
