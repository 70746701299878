import React from "react"
import { EditablePosition } from "../../../../../packages/editing/EditablePosition"
import { EditableText } from "../../../../../packages/editing/EditableText"
import { ImageToUrl } from "../../../../../reactor/Types/File"
import { colors } from "../../colors"
import { DatePicker } from "./DatePicker"
import { usePartyContext } from "./PartyContext"
import { usePageContext } from "../../Page"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { VenuePicker } from "../Menu"
import { CallToAction } from "../TextAndCallToAction"
import { useNavigate } from "react-router-dom"

export function StartPage() {
    const { page, venue } = usePageContext()
    const localize = useLocalize<string>()
    const { section, venues, minimumGuests, guestCount, setGuestCount, date, setDate } =
        usePartyContext()
    const navigate = useNavigate()

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflowX: "clip",
                marginBottom: 64,
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 32,
                    marginRight: 32,
                    position: "relative",
                }}
            >
                {section.headerSticker && (
                    <EditablePosition
                        obj={section}
                        xProp="headerStickerOffsetX"
                        yProp="headerStickerOffsetY"
                    >
                        <img
                            style={{ width: 64, height: 64 }}
                            src={ImageToUrl(section.headerSticker, { width: 64 })}
                        />
                    </EditablePosition>
                )}
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyItems: "center",
                        alignItems: "center",
                    }}
                >
                    <h1>
                        <EditableText
                            obj={section}
                            prop="headerText"
                            defaultText="It's Party Time!"
                            isLocalized={true}
                        />
                    </h1>
                    <div style={{ position: "relative" }}>
                        <EditableText
                            obj={section}
                            prop="headerSubtext"
                            defaultText="We look forward to welcoming you!"
                            isLocalized={true}
                        />
                    </div>
                </div>
                <div
                    style={{
                        margin: 32,
                        paddingLeft: 64,
                        paddingRight: 64,
                        paddingTop: 16,
                        paddingBottom: 16,
                        borderRadius: 32,
                        backgroundColor: colors.darkPurple,
                        color: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyItems: "center",
                        alignItems: "center",
                    }}
                >
                    <VenuePicker
                        section={section}
                        prefix={localize(section.buttonPrefix)}
                        slug={page.slugs[0] ?? "party"}
                        options={venues}
                        current={venues.find((v) => v.id === venue?.id)}
                    />
                </div>
                {venue && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                position: "relative",
                                marginLeft: 32,
                                marginRight: 32,
                                marginBottom: 24,
                                flexDirection: "column",
                            }}
                        >
                            <h3>
                                <EditableText
                                    obj={section}
                                    prop="guestsText"
                                    defaultText="Guests"
                                    isLocalized={true}
                                />
                            </h3>
                            <div style={{ color: colors.grey5 }}>
                                <EditableText
                                    obj={section}
                                    prop="guestsExplanation"
                                    defaultText="Number of children"
                                    isLocalized={true}
                                />
                            </div>

                            {section.guestsSticker && (
                                <EditablePosition
                                    obj={section}
                                    xProp="guestsStickerOffsetX"
                                    yProp="guestsStickerOffsetY"
                                >
                                    <img
                                        style={{ width: 64, height: 64 }}
                                        src={ImageToUrl(section.guestsSticker, { width: 64 })}
                                    />
                                </EditablePosition>
                            )}
                        </div>

                        <div
                            style={{
                                backgroundColor: "white",
                                textAlign: "center",
                                fontSize: 28,
                                marginLeft: 32,
                                marginRight: 32,
                                borderRadius: 64,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <div
                                onClick={() =>
                                    setGuestCount(Math.max(guestCount - 1, minimumGuests))
                                }
                                style={{
                                    width: 48,
                                    height: 48,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                    borderRadius: 48,
                                    backgroundColor:
                                        guestCount === minimumGuests ? colors.grey5 : colors.purple,
                                    color: "white",
                                    cursor: "pointer",
                                }}
                            >
                                -
                            </div>
                            <div className="bold" style={{ flex: 1 }}>
                                {guestCount}
                            </div>
                            <div
                                onClick={() => setGuestCount(guestCount + 1)}
                                style={{
                                    width: 48,
                                    height: 48,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                    borderRadius: 48,
                                    backgroundColor: colors.purple,
                                    color: "white",
                                    cursor: "pointer",
                                }}
                            >
                                +
                            </div>
                        </div>
                        <DatePicker date={date} setDate={setDate} />
                        <div style={{ alignSelf: "center", marginTop: 32 }}>
                            <CallToAction
                                disabled={!date}
                                disabledReason={!date ? "Please select a date first" : undefined}
                                section={section}
                                prop="callToActionOne"
                                onClick={() => navigate("./room-and-time")}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
