import { Uuid } from "../../reactor/Types/Primitives/Uuid"

/** Abstraction over model-specific section types.
 *
 *  The model can declare custom section types by defining functions in .tsx
 *  files that take a single `section` argument.
 *
 *  @shared
 */
export type Section = {
    /**
     * The type of the section.
     *
     * This corresponds to the component function name.
     */
    readonly type: string
    /**
     * The ID of the section.
     * @hide
     */
    readonly id: Uuid<any>

    /** This prevents additional properties from being stripped when saving to the database */
    [additionalProperties: string]: any
}

/**
 * A function component that renders a section.
 */
export type SectionFunction = (
    section: any,
    /** The index of this section, in the total list of sections */
    index: number,
    /**
     * The total number of sections.
     */
    totalCount: number
) => JSX.Element

/**
 * Registers a website section type.
 *
 * Use the type `Section` to represent any of the registered section types. This
 * avoids having to declare a union type of all section types.
 *
 * @param type The type of section to render.
 * @param renderer A function component that renders the section.
 */
export function Section<T extends Section>(
    /** The name of the renderer function.
     *
     * This must be provided if the renderer function is exported, as top-level
     * exported functions get renamed by Parcel's scope hoisting feature. */
    name: string,
    renderer: SectionFunction
): void
export function Section<T extends Section>(renderer: SectionFunction): void
export function Section<T extends Section>(
    arg0: string | SectionFunction,
    arg1?: SectionFunction
): void {
    const renderer =
        typeof arg1 === "function" ? arg1 : typeof arg0 === "function" ? arg0 : undefined
    if (!renderer) throw new Error("Section renderer must be a function")

    let name = typeof arg0 === "string" ? arg0 : renderer.name

    if (!renderer) {
        throw new Error("Section renderer must be a function")
    }

    if (name.includes("$")) {
        // This name has been changed by Parcel's scope hoisting. We need to
        // extract the original name.
        const parts = name.split("$")

        if (parts.includes("export")) {
            throw new Error(
                "Exported section function must specify name (the name is lost due to Parcel's scope hoisting)"
            )
        }

        name = parts[parts.length - 1]
    }

    const hadSection = Section.types.has(name)
    Section.types.set(name, renderer as any)

    const invalidateFuncs = Section.invalidators.get(name)

    if (hadSection && invalidateFuncs) {
        setTimeout(() => {
            for (const invalidate of invalidateFuncs) {
                invalidate()
            }
        }, 1)
    }
}
Section.types = new Map<string, SectionFunction>()
/** Hot reload callbacks.
 *
 *  Since section types are not components directly, their wrappers need to be
 *  invalidated. This is done by calling the invalidate function when a section
 *  type is re-registered.
 */
Section.invalidators = new Map<string, (() => void)[]>()
