import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { postAnalyticsEvent, useAnalyticsEventStatus } from "../../studio/client"

/**
 * Adds implicit logging of page views to the Reactor analytics system.
 */
export function useAnalyticsLogger() {
    const location = useLocation()
    // This should be resolved at SSR time so in practice, if analytics is
    // disabled, no API calls will be made by this hook.
    const status = useAnalyticsEventStatus("web")
    const [disabled, setDisabled] = useState(status.data ? status.data.disabled : false)

    useEffect(() => {
        async function postIt() {
            const path = location.pathname.split("/").filter((p) => p !== "")
            const res = await postAnalyticsEvent("web", { entity: path.slice(0, 4) })
            if ("disabled" in res && res.disabled) {
                // Analytics is disabled, so we should disable the logger
                setDisabled(true)
            }
        }
        if (!disabled) void postIt()
    }, [location.pathname, disabled])
}
