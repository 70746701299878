import React from "react"
import { useNavigate } from "react-router-dom"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { usePartyContext } from "./PartyContext"
import { Localized } from "../../../../../packages/localization/Localized"

type Page = "start" | "room-time" | "package" | "extras" | "contact-info" | "summary"

export function Breadcrumbs({ page }: { page: Page }) {
    const { section } = usePartyContext()
    const pages: Page[] = ["start", "room-time", "package", "extras", "contact-info", "summary"]
    const index = pages.indexOf(page)
    return (
        <div
            style={{
                marginTop: 32,
                marginBottom: 16,
                textAlign: "center",
            }}
        >
            <Breadcrumb
                title={section.breadcrumbs.date}
                to={page === "start" ? "./" : "../"}
                current={page === "start"}
                enabled={index >= pages.indexOf("start")}
            />
            <Arrow />
            <Breadcrumb
                title={section.breadcrumbs.roomAndTime}
                to={"../room-and-time"}
                current={page === "room-time"}
                enabled={index >= pages.indexOf("room-time")}
            />
            <Arrow />
            <Breadcrumb
                title={section.breadcrumbs.partyPackage}
                to={"../package"}
                current={page === "package"}
                enabled={index >= pages.indexOf("package")}
            />
            <Arrow />
            <Breadcrumb
                title={section.breadcrumbs.addons}
                to={"../extras"}
                current={page === "extras"}
                enabled={index >= pages.indexOf("extras")}
            />
            <Arrow />
            <Breadcrumb
                title={section.breadcrumbs.contactInfo}
                to={"../contact-info"}
                current={page === "contact-info"}
                enabled={index >= pages.indexOf("contact-info")}
            />
            <Arrow />
            <Breadcrumb
                title={section.breadcrumbs.summary}
                to={"../summary"}
                current={page === "summary"}
                enabled={index >= pages.indexOf("summary")}
            />
        </div>
    )
}

function Breadcrumb({
    title,
    to,
    current,
    enabled,
}: {
    title: Localized<string>
    to: string
    current?: boolean
    enabled?: boolean
}) {
    const localize = useLocalize()
    const navigate = useNavigate()
    return (
        <div
            onClick={enabled ? () => navigate(to) : undefined}
            style={{
                marginLeft: 7,
                marginRight: 7,
                color: current ? "black" : "grey",
                cursor: enabled ? "pointer" : undefined,
                display: "inline",
            }}
        >
            {current ? <strong>{localize(title)}</strong> : localize(title)}
        </div>
    )
}

function Arrow() {
    return (
        <svg
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_4792_7392)">
                <path
                    d="M13 7.50043C13 7.75109 12.9023 8.00186 12.707 8.19288L6.70703 14.0704C6.31641 14.4531 5.68359 14.4531 5.29297 14.0704C4.90234 13.6878 4.90234 13.0679 5.29297 12.6852L10.5875 7.50043L5.29375 2.31472C4.90312 1.93207 4.90312 1.31217 5.29375 0.929515C5.68437 0.546862 6.31719 0.546862 6.70781 0.929515L12.7078 6.80707C12.9031 6.99839 13 7.24941 13 7.50043Z"
                    fill="#968BA7"
                />
            </g>
            <defs>
                <clipPath id="clip0_4792_7392">
                    <rect
                        width="13.7143"
                        height="16"
                        fill="white"
                        transform="matrix(0 -1 1 0 0 14.3574)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
