import { css } from "@emotion/react"
import { Section } from "../../../../packages/editing/Section"
import { usePageContext } from "../Page"
import { ImageToUrl } from "../../../../reactor"
import { MapDirectionsButton } from "../assets/MapDirectionsButton"

/**
 * Section to use on venue info page to show a map of where the venue is. The map has to be
 * uploaded as an image to the venue info in Studio.
 */
Section(VenueInfoMap)
function VenueInfoMap(section: {}) {
    const { venue } = usePageContext()

    // Assume this won't be used outside of a venue context
    if (!venue) return <></>

    return !!venue.mapImage ? (
        <div
            css={css`
                overflow: hidden;
                height: 100vw;
                position: relative;
                margin-top: 40px;

                @media (min-width: 480px) {
                    height: 475px;
                    margin-top: 60px;
                }
            `}
        >
            <img
                src={ImageToUrl(venue.mapImage, { width: 1440 })}
                css={css`
                    object-fit: cover;
                    min-width: 100%;
                    height: 100%;
                    max-width: 100%;
                `}
            />
            {!!venue.mapLocationUrl && (
                <a href={venue.mapLocationUrl.toString()} target="_blank">
                    <MapDirectionsButton
                        css={css`
                            position: absolute;
                            bottom: 24px;
                            right: 24px;
                        `}
                    />
                </a>
            )}
        </div>
    ) : (
        <></>
    )
}
