import type { Button } from "./Button"
import type { Item } from "./Item"
import type { Modal } from "./Modal"
import type { Table } from "./Table"
import type { LazyWidgetMap, Widget } from "./Widget"
import type { WidgetBase } from "./WidgetBase"

export function IsTableRow(w: Widget | LazyWidgetMap): w is LazyWidgetMap {
    return w !== null && !(w instanceof Array) && typeof w === "object" && !("type" in w)
}
export function IsTable(w: Widget): w is Table {
    return w !== null && typeof w === "object" && "type" in w && w.type === "Table"
}
export function IsItem(w: Widget): w is Item {
    return !!(w && typeof w === "object" && "type" in w && w.type === "Item")
}
export function IsButton(widget: Widget): widget is Button {
    return !!(widget && typeof widget === "object" && "type" in widget && widget.type === "Button")
}
export function IsModal(widget: Widget): widget is Modal {
    return !!(widget && typeof widget === "object" && "type" in widget && widget.type === "Modal")
}
export function IsWidgetBase(widget: any): widget is Widget & WidgetBase {
    return widget && typeof widget === "object"
}
