import { GetReflectionInfo, SubstituteAndDiscriminate } from "./ReflectionInfo"
import { Property, Type, GetTypeAlias } from "./Types/Type"

export function GetIconForPropertyValue(value: any, property: Property): string | undefined {
    const icon = property.tags?.icon
    if (icon) return icon

    return GetIconForTypedValue(value, property.type)
}

export function GetIconForTypedValue(value: any, type: Type): string | undefined {
    const discriminatedType = SubstituteAndDiscriminate(value, type)

    if (typeof discriminatedType === "object") {
        const icon = discriminatedType.tags?.icon
        if (icon) return icon
    }
    if (typeof type === "object") {
        const icon = type.tags?.icon
        if (icon) return icon

        if (type.reference) {
            const refIcon = GetReflectionInfo()?.collections.find(
                (c) => GetTypeAlias(c.type.typeArgs[0]) === type.reference?.typeName
            )?.icon
            if (refIcon) return refIcon
        }
    }
}
