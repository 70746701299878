import { useState } from "react"
import { DeserializeGraph } from "../reactor/Graph"
import {
    GetReflectionInfo,
    IsReflectionInfoReady,
    ReflectionInfo,
    SetReflectionInfo,
} from "../reactor/ReflectionInfo"

let reflectionPromise: Promise<string> | undefined
export function whenReflectionReady(callback: (reflectionInfo: ReflectionInfo) => void) {
    if (IsReflectionInfoReady()) {
        callback(GetReflectionInfo())
        return
    }
    if (!reflectionPromise) {
        async function loadReflection() {
            const { getReflection } = await import("./client")

            // Could have been initialized while we were waiting for the import
            if (!reflectionPromise) {
                reflectionPromise = getReflection()
            }
            const ref = await reflectionPromise
            const info = DeserializeGraph<ReflectionInfo>(ref as any)
            SetReflectionInfo(info)
            callback(info)
        }
        void loadReflection()
    } else {
        void reflectionPromise.then(() => callback(GetReflectionInfo()))
    }
}

/** Returns the reflection info when available.
 *
 *  If reflection info has not been downloaded by a previous component, this hook will download it.
 */
export function useReflectionReady(
    /** Whether the caller needs the reflection info now, or just want to
     * reserve the hook for later.
     *
     * Passing `false` will not download the reflection info yet.
     * */
    needItYet: boolean
) {
    const [ready, setReady] = useState(IsReflectionInfoReady())
    if (!ready && needItYet) {
        whenReflectionReady(() => setReady(true))
    }

    if (!IsReflectionInfoReady()) return undefined
    return GetReflectionInfo()
}
