import { TSQFunction } from "../../reactor/TSQ/TSQFunction"
import type { Widget } from "./Widget"
import type { WidgetBase } from "./WidgetBase"

/**
 * @shared
 */
export type Empty = WidgetBase & { type: "Empty"; widget: Widget; variant?: EmptyVariant }
export type EmptyVariant = "Error"

TSQFunction(Empty)
/** A widget that represents an empty state. */
export function Empty(widget?: Widget, variant?: EmptyVariant): Empty {
    return { type: "Empty", widget: widget ?? "Empty", variant }
}
