import { css } from "@emotion/react"
import { colors } from "./colors"
import type { Color } from "./colors"

type RenderFunc = () => JSX.Element

type Column = {
    /** Whether the content of this column should */
    bleed?: boolean

    /** Whether the column and its content should be hidden on mobile */
    hideOnMobile?: boolean

    /** What to render in this column */
    render: RenderFunc
}

/**
 * Component rendering a split view with customizable responsive behavior
 */
export function SplitView({
    backgroundColor,
    columns,
    reverseOnMobile,
}: {
    /**
     * Array of the columns in this split view. If no options are required for a column, a render
     * function is sufficient, otherwise an object with options and can be used.
     */
    columns: (Column | RenderFunc)[]

    /**
     * Useful for two column layouts where image should be to the left on desktop but
     * bottom of mobile or vice versa.
     */
    reverseOnMobile?: boolean

    /**
     * Background color of the view. Will render text as white for contrast.
     */
    backgroundColor?: Color
}) {
    return (
        <div
            css={css`
                display: grid;
                gap: 50px;
                padding: 40px 18px;
                width: 100%;
                max-width: 1200px;
                margin: 0 auto;
                grid-auto-rows: max-content;
                grid-template-areas: ${columns
                    .map((col, index) =>
                        typeof col !== "function" && col.hideOnMobile ? `` : `"col${index}"`
                    )
                    [reverseOnMobile ? "reverse" : "slice"]()
                    .join(" ")};

                @media (min-width: 480px) {
                    padding: 80px 40px;
                    grid-template-areas: "${columns.map((_, index) => `col${index}`).join(" ")}";
                    grid-template-columns: ${columns.map(() => `1fr`).join(" ")};
                }

                @media (min-width: 1200px) {
                    padding: 80px 0;
                }

                ${!!backgroundColor && `background-color: ${colors[backgroundColor]}; color: #fff;`}
            `}
        >
            {columns.map((col, index) => (
                <div
                    key={index}
                    css={css`
                        grid-area: ${`col${index}`};

                        @media (max-width: 479px) {
                            ${typeof col !== "function" && col.hideOnMobile && "display: none;"}
                        }
                    `}
                >
                    {typeof col === "function" ? col() : col.render()}
                </div>
            ))}
        </div>
    )
}

export function SplitViewImage({
    src,
    fullwidthOnMobile = false,
    cropPercentageOnMobile = 0,
    align,
}: {
    src: string
    fullwidthOnMobile?: boolean
    cropPercentageOnMobile?: number
    align: "left" | "right" | "center"
}) {
    return (
        <div
            css={css`
                ${fullwidthOnMobile &&
                `
                    @media (max-width: 479px) {
                        width: 100vw;
                        overflow: hidden;
                        margin-left: calc(50% - 50vw);
                    }

                    @media (min-width: 480px) {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        ${!!align && `justify-content: ${align};`}
                        align-items: flex-start;
                    }
                `}
            `}
        >
            <img
                src={src}
                css={css`
                    ${fullwidthOnMobile &&
                    `
                        @media (max-width: 479px) {
                            width: calc(100vw + ${cropPercentageOnMobile}%);
                            margin-left: calc(50% - 50vw - ${cropPercentageOnMobile / 2}%); 
                        }

                        @media (min-width: 480px) {
                            width: 85%;
                        }
                    `}
                `}
            />
        </div>
    )
}
