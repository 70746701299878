import React, { useState } from "react"
import { Localized } from "../../../../packages/localization/Localized"
import { Image } from "../../../../reactor/Types/File"
import { DateString, Email, Markdown, PhoneNumber } from "../../../../reactor/Types/Primitives"
import { PartyRoomByVenue, PartyMeal, PartyPackageByVenue } from "../client"
import { PartyContext } from "./party-booker/PartyContext"
import { StartPage } from "./party-booker/StartPage"
import { RoomAndTimePage } from "./party-booker/RoomAndTimePage"
import { PartyPackagePage } from "./party-booker/PartyPackagePage"
import { PartyExtrasPage } from "./party-booker/PartyExtrasPage"
import { ContactInfoPage } from "./party-booker/ContactInfoPage"
import { SummaryPage } from "./party-booker/SummaryPage"
import { MinuteOfDay } from "../../primitives/TimeSlot"
import { ConfirmationPage } from "./party-booker/ConfirmationPage"
import { usePageContext } from "../Page"
import { Route, Routes } from "react-router-dom"
import { PaymentPage } from "./party-booker/PaymentPage"
import { Section } from "../../../../packages/editing/Section"

export type PartyBooker = {
    /**
     * @default '{"en": "It's Party Time!"}'
     */
    headerText: Localized<string>
    /**
     * @default '{"en":"We look forward to welcoming you"}'
     */
    headerSubtext: Localized<string>

    chooseAVenue: Localized<string>

    /**
     * @default '{"en":"Place"}'
     */
    buttonPrefix: Localized<string>

    headerSticker?: Image
    headerStickerOffsetX: number
    headerStickerOffsetY: number

    /**
     * @default '{"en":"Guests"}'
     */
    guestsText: Localized<string>
    /**
     * @default '{"en":"Number of children"}'
     */
    guestsExplanation: Localized<string>

    guestsSticker?: Image
    guestsStickerOffsetX: number
    guestsStickerOffsetY: number

    /** The text on the button to proceed to next step after selecting location,
     * date and number of guests. */
    callToActionOne: Localized<string>

    /**
     * Text after the price per child, e.g. "per child"
     * @tab Package Selection
     *
     */
    perChildText: Localized<string>

    packagePickerTitle: Localized<string>
    packagePickerSubtitle: Localized<Markdown>

    /**
     * Text on the call to action to select the currently viewed package.
     */
    choosePackageCallToAction: Localized<string>

    callUsToBookPackage: Localized<string>

    mealOptions: Localized<string>

    whoAreWeCelebrating: Localized<string>
    yourInformation: Localized<string>

    partyExtras: Localized<string>
    partyExtrasSubtext: Localized<Markdown>

    /**
     * Text on the call to action to confirm party extras.
     */
    extrasPageCallToAction: Localized<string>

    /**
     * Label for the name of the birthday child textbox.
     */
    nameOfTheBirthdayChild: Localized<string>

    summaryThanks: Localized<string>
    backToHomePage: Localized<string>

    /**
     * Label for the birthday child input box.
     */
    birthDate: Localized<string>

    /**
     * Labels for the contact information fields.
     */
    informationFields: {
        firstName: Localized<string>
        lastName: Localized<string>
        email: Localized<string>
        phoneNumber: Localized<string>
        notes: Localized<string>
    }

    contactInfoCallToAction: Localized<string>

    summaryPage: {
        header: Localized<string>
        subtext: Localized<string>
        birthdayChild: Localized<string>
        dateAndTime: Localized<string>
        roomAndLocation: Localized<string>
        numberOfChildren: Localized<string>
        partyPackage: Localized<string>
        mealOption: Localized<string>
        extras: Localized<string>
        notes: Localized<string>
        iAcceptTermsAndConditions: Localized<string>
        callToAction: Localized<string>
        includingVAT: Localized<string>
    }

    iHaveVerifiedTheInformation: Localized<Markdown>
    iHaveReadAndAcceptTOC: Localized<Markdown>

    bookNowCallToAction: Localized<string>

    confirmationHeader: Localized<string>
    confirmationText: Localized<Markdown>

    payment?: Localized<string>
}

Section("PartyBooker", PartyBooker)
export function PartyBooker(section: PartyBooker) {
    const minimumGuests = 6

    const page = usePageContext()

    const [guestCount, setGuestCount] = useState(minimumGuests)
    const [date, setDate] = useState<Date | undefined>()
    const [partyPackage, setPartyPackage] = useState<PartyPackageByVenue | undefined>()
    const [mealOption, setMealOption] = useState<PartyMeal | undefined>()
    const [room, setRoom] = useState<PartyRoomByVenue | undefined>()
    const [roomName, setRoomName] = useState<string | undefined>()
    const [time, setTime] = useState<MinuteOfDay | undefined>()
    const [extras, setExtras] = useState<{ [id: string]: number } | undefined>()
    const [nameOfTheBirthdayChild, setNameOfTheBirthdayChild] = useState("")
    const [birthDate, setBirthDate] = useState<DateString | undefined>()

    // Contact info of parent
    const [firstName, setFirstName] = useState<string | undefined>()
    const [lastName, setLastName] = useState<string | undefined>()
    const [email, setEmail] = useState<Email | undefined>()
    const [phoneNumber, setPhoneNumber] = useState<PhoneNumber | undefined>()
    const [notes, setNotes] = useState<string | undefined>()

    const [acceptedTOC, setAcceptedTOC] = useState(false)

    return (
        <PartyContext.Provider
            value={{
                section,
                minimumGuests,
                guestCount,
                setGuestCount,
                date,
                setDate,
                partyPackage,
                mealOption,
                setPartyPackage(pk, mealOption) {
                    setPartyPackage(pk)
                    setMealOption(mealOption)
                },
                room,
                roomName,
                time,
                extras,
                setExtras,
                setExtra(id, quantity) {
                    setExtras((prev) => ({ ...prev, [id.valueOf()]: quantity }))
                },
                setRoomAndTime(room, name, time) {
                    setRoom(room)
                    setRoomName(name)
                    setTime(time)
                },
                birthDate,
                setBirthDate,
                venues: page.venues,
                nameOfTheBirthdayChild,
                setNameOfTheBirthdayChild,
                firstName,
                setFirstName,
                lastName,
                setLastName,
                email,
                setEmail,
                phoneNumber,
                setPhoneNumber,
                notes,
                setNotes,
                acceptedTOC,
                setAcceptedTOC,
            }}
        >
            <Routes>
                <Route path="/" element={<StartPage />} />
                <Route path="room-and-time" element={<RoomAndTimePage />} />
                <Route path="package" element={<PartyPackagePage />} />
                <Route path="extras" element={<PartyExtrasPage />} />
                <Route path="contact-info" element={<ContactInfoPage />} />
                <Route path="summary" element={<SummaryPage />} />
                <Route path="payments/:adyenOrder/:adyenOrderId" element={<PaymentPage />} />
                <Route
                    path="confirmation/:adyenOrder/:adyenOrderId"
                    element={<ConfirmationPage />}
                />
            </Routes>
        </PartyContext.Provider>
    )
}
