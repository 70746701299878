export type EditableObjectActions = {
    /** If deletion is supported for the selected object, this callback deletes
     * it */
    deleteThis?: () => void

    /** If saving as template is supported for the selected object, this
     * callback spawns the modal for saving. */
    saveAsTemplate?: () => void
}

const actionHooks = new WeakMap<object, EditableObjectActions>()

export function SetEditableActions(obj: any, actions: EditableObjectActions) {
    actionHooks.set(obj, actions)
}

export function GetEditableActions(obj: any): EditableObjectActions | undefined {
    return actionHooks.get(obj)
}
