import {
    NavigateFunction,
    NavigateOptions,
    To,
    useNavigate as useRouterNavigate,
} from "react-router-dom"
import { useConfirmNavigation } from "./useConfirmNavigation"

/** Returns a function that can be used to navigate to a new route.
 *
 * This should be used instead of `react-router-dom`'s equivalent function, as this one supports
 * `useConfirmNavigation`.
 *
 *  Support for intercepting navigation was removed in `react-router-dom` v6, not to be added back
 *  it seems. https://github.com/remix-run/react-router/issues/8139
 *
 * */
export function useNavigate(): NavigateFunction {
    const routerNavigate = useRouterNavigate()

    return function (to: To | number, options?: NavigateOptions) {
        if (useConfirmNavigation.active) {
            if (
                !window.confirm(
                    "Leaving the page will discard unsaved changes. Do you want to continue?"
                )
            ) {
                return
            }
        }
        // Call the right overload for "number"
        if (typeof to === "number") routerNavigate(to)
        else routerNavigate(to, options)
    }
}
