import React from "react"
/* Importing `schema-dts` makes typescript intellisense EXTREMELY slow, since it
 is a hunormous library. So unfortunately we have to re-declare the types directly
 below. However, leaving this here so that it can be commented back in
 temporarily during development to discover useful types and props to add to
 our set of helpers.  */
//import { Organization as OrganizationSchema, WebSite as WebSiteSchema } from "schema-dts"

/** Contains React components for injecting strongly Schema.org typed strucutred
 * data into React trees. This will include them correctly in SSR, as well as
 * when navigating around. */
export namespace StructuredData {
    type OrganizationSchema = {
        name: string
        url: string
        logo: string
    }
    type WebSiteSchema = {
        name: string
        url: string
        alternateName?: string
    }

    /**
     * Provides info wanted by e.g. google to recongnize the logo of an
     * organization, for use in search results.
     *
     * See https://developers.google.com/search/docs/appearance/structured-data/logo
     */
    export function Organization(props: Partial<OrganizationSchema & object>) {
        return JsonLD("Organization", props)
    }

    export function WebSite(props: Partial<WebSiteSchema & object>) {
        return JsonLD("WebSite", props)
    }

    function JsonLD(type: string, obj: object) {
        return (
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(
                        {
                            "@context": "https://schema.org",
                            "@type": type,
                            ...obj,
                        },
                        undefined,
                        4
                    ),
                }}
            />
        )
    }
}
