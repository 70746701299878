import React, { CSSProperties, useEffect, useState } from "react"
import { server } from "../../server"

export function Icon({
    icon,
    width,
    height,
    color,
    size,
    style,
}: {
    icon: string
    width?: number
    height?: number
    color?: string
    size?: number
    style?: CSSProperties
}) {
    width ??= size
    height ??= size
    if (icon.startsWith("ui-")) {
        const iconUrl = `${server()}/ui-icons/${icon.slice(3)}${
            color ? `?stroke=${encodeURIComponent(color)}` : ""
        }`

        // Untitled UI icons
        return <Svg url={iconUrl} width={width} height={height} color={color} style={style} />
    }

    return <i className={"fas fa-" + icon} style={{ width, height, color, ...style }} />
}

const iconCache = new Map<string, string | ((data: string) => void)[]>()

function Svg({
    url,
    width,
    height,
    style,
    color,
}: {
    url: string
    width?: number
    height?: number
    style?: CSSProperties
    color?: string
}) {
    const [data, setData] = useState(iconCache.get(url))

    useEffect(() => {
        if (!iconCache.has(url)) {
            const listeners = [] as ((d: string) => void)[]
            iconCache.set(url, listeners)
            listeners.push(setData)
            void fetch(url)
                .then((res) => res.text())
                .then((d) => {
                    iconCache.set(url, d)
                    listeners.forEach((listener) => listener(d))
                })
        }
        const cacheEntry = iconCache.get(url)
        if (cacheEntry instanceof Array) {
            if (!cacheEntry.includes(setData)) cacheEntry.push(setData)
        } else {
            setData(cacheEntry)
        }
    }, [color, url, style])

    return typeof data === "string" ? (
        <svg
            viewBox="0 0 24 24"
            width={width}
            height={height}
            fill="none"
            dangerouslySetInnerHTML={{ __html: data }}
            style={style}
        />
    ) : null
}
