import { IsNode } from "../../reactor/AssertNode"
import { LocaleKey } from "../localization/Locale"

export function useAlternateLinks(alternates?: { href: string; hreflang: LocaleKey }[]) {
    if (!alternates) return
    if (IsNode()) {
        if (ssrTransaction.macros) {
            ssrTransaction.macros.set("link-rel-alternate", (html) =>
                html
                    .querySelector("root")
                    ?.insertAdjacentHTML(
                        "beforeend",
                        alternates
                            .map(
                                ({ href, hreflang }) =>
                                    `<link rel="alternate" href="${href}" hreflang="${hreflang}">`
                            )
                            .join("\n")
                    )
            )
        }
    } else {
        // Get all link rel="alternate" tags from the document's head
        const alternateLinks = document.head.querySelectorAll('link[rel="alternate"]')

        // Check if we need to make any updates
        const seen = new Set<string>()
        alternateLinks.forEach((link) => {
            const href = link.getAttribute("href")
            const hreflang = link.getAttribute("hreflang")
            if (alternates.some((x) => x.href === href && x.hreflang.valueOf() === hreflang)) {
                seen.add(`${href}:${hreflang}`)
            }
        })
        if (seen.size === alternates.length) return

        // Remove existing alternate links
        alternateLinks.forEach((link) => {
            document.head.removeChild(link)
        })

        // Inject new alternate links into the document's head
        alternates.forEach(({ href, hreflang }) => {
            const newLink = document.createElement("link")
            newLink.setAttribute("rel", "alternate")
            newLink.setAttribute("href", href.valueOf())
            newLink.setAttribute("hreflang", hreflang.valueOf())
            document.head.appendChild(newLink)
        })
    }
}
