import React from "react"

/**
 * Builds a React fragment by replacing {{key}} with values[key] in the given text.
 */
export function MustacheReact(text: string, values: Record<string, React.ReactNode>) {
    return (
        <>
            {typeof text === "string"
                ? text.split("{{").flatMap((part) => {
                      if (part.includes("}}")) {
                          const [key, ...rest] = part.split("}}")
                          return [values[key], ...rest]
                      } else {
                          return <React.Fragment key={part}>{part}</React.Fragment>
                      }
                  })
                : undefined}
        </>
    )
}

/**
 * Builds a string by replacing {{key}} with values[key] in the given text.
 */
export function MustacheString(
    text: string,
    values: Record<string, string>,
    start = "{{",
    end = "}}"
) {
    return text
        .split(start)
        .flatMap((part) => {
            if (part.includes(end)) {
                const [key, ...rest] = part.split(end)
                return values[key] + rest.join(end)
            } else {
                return part
            }
        })
        .join("")
}
