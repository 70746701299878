import { useParams } from "react-router-dom"

/** Returns a single parameter from the URL path.
 *
 *  Throws an error if the parameter is not present.
 */
export function useParam(pathParam: string): string {
    const params = useParams()
    const value = params[pathParam]
    if (typeof value !== "string") throw new Error("Path parameter not found: " + pathParam)
    return value
}
