import React from "react"
import { ClientSideLocalize } from "../../../packages/localization/client-side/Dictionary"
import { Locale } from "../../../packages/localization/Locale"
import { ProblemJson } from "../../../packages/ssr/ProblemJson"
import { SSRStatus } from "../../../packages/ssr/SSRStatus"
import { server } from "../../../server"
import { LogoIcon } from "./assets/LogoIcon"

export function ErrorScreen({ error }: { error: ProblemJson; locale: Locale["key"] }) {
    SSRStatus.code = error.status ?? 500
    return (
        <div
            style={{
                padding: "10%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <img
                    src={server() + "/static/funplays-shark.png"}
                    style={{ width: "30vw", maxWidth: 500 }}
                />
                <h1 className="display-1">{error.status}</h1>
            </div>
            <h1>{ClientSideLocalize(friendlyError(error.status ?? 500))}</h1>
            <div style={{ marginBottom: 128 }}>{error.detail}</div>

            <LogoIcon />
        </div>
    )
}

function friendlyError(status: number) {
    switch (status) {
        case 401:
            return {
                en: "Oh noes! You need to be logged in to do that.",
                sv: "Åh nej! Du måste vara inloggad för att göra det.",
                no: "Å nei! Du må være innlogget for å gjøre det.",
                da: "Åh nej! Du skal være logget ind for at gøre det.",
                de: "Oh nein! Sie müssen angemeldet sein, um das zu tun.",
            }
        case 403:
            return {
                en: "Oh noes! You are not allowed to do that.",
                sv: "Åh nej! Du får inte göra det.",
                no: "Å nei! Du får ikke lov til å gjøre det.",
                da: "Åh nej! Du må ikke gøre det.",
                de: "Oh nein! Sie dürfen das nicht tun.",
            }
        case 404:
            return {
                en: "Oh noes! Something is missing.",
                sv: "Åh nej! Något saknas.",
                no: "Å nei! Noe mangler.",
                da: "Åh nej! Noget mangler.",
                de: "Oh nein! Etwas fehlt.",
            }
        case 500:
            return {
                en: "Oh noes! Something went wrong.",
                sv: "Åh nej! Något gick fel.",
                no: "Å nei! Noe gikk galt.",
                da: "Åh nej! Noget gik galt.",
                de: "Oh nein! Etwas ist schief gelaufen.",
            }
        default:
            return {
                en: "Oh noes! Something is wrong.",
                sv: "Åh nej! Något är fel.",
                no: "Å nei! Noe er galt.",
                da: "Åh nej! Noget er galt.",
                de: "Oh nein! Etwas ist falsch.",
            }
    }
}
