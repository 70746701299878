import { OpaqueString } from "./Opaque"
import { TypeValidator } from "./Primitives/TypeValidator"

export type Color = OpaqueString<"Color"> & string
TypeValidator(Color)
export function Color(color: string): Color {
    return color as any
}
/**
 * Returns the luminance of a hex color in the range [0, 255].
 *
 * Uses the formula from https://www.w3.org/TR/WCAG20/#relativeluminancedef
 */
Color.getLuminance = (hex: string) => {
    const rgb = parseInt(hex, 16)
    const r = (rgb >> 16) & 0xff
    const g = (rgb >> 8) & 0xff
    const b = rgb & 0xff
    return 0.2126 * r + 0.7152 * g + 0.0722 * b
}
/**
 * Returns whether a hex color is dark (less than 50% luminance).
 *
 * This can e.g. be used to determine whether to use a light or dark text color
 * on top of a background color.
 */
Color.isDark = (hex: string) => Color.getLuminance(hex) < 128
