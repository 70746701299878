import React from "react"
import { colors } from "../../colors"
import { Col, Row } from "react-bootstrap"
import { useHover } from "../../../../../packages/hooks/useHover"
import { ContextModal, useContextualModal } from "../../../../../packages/modal/Modal"
import { Field } from "./Field"
import { capitalizeFirstLetter } from "../../../../../reactor/Helpers"
import {
    useCurrentLocale,
    useLocalize,
} from "../../../../../packages/localization/client-side/useLocalize"
import { usePageContext } from "../../Page"
import { usePartyContext } from "./PartyContext"
import { GetPageVenue1Dto, PartyPrice, usePartyPrice } from "../../client"
import { NonNegativeInteger } from "../../../../../reactor/Types/Primitives/NonNegativeNumber"
import { Uuid } from "../../../../../reactor/Types/Primitives/Uuid"
import { Localized } from "../../../../../packages/localization/Localized"
import moment from "moment"

export function Footer({
    callToAction,
    disabled,
    onClick,
}: {
    callToAction?: Localized<string>
    disabled?: boolean
    onClick: () => void
}) {
    const width = 960
    const height = 96
    const { modal, showModal } = useContextualModal()
    const { section } = usePartyContext()
    return (
        <>
            {modal}
            <div style={{ height }} />
            <div className="fixed-bottom">
                <div
                    style={{
                        height,
                        backgroundColor: colors.darkPurple,
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Row style={{ width, margin: "0 auto" }}>
                        <Col
                            style={{
                                alignItems: "flex-start",
                                justifyContent: "center",
                                flexDirection: "column",
                            }}
                        >
                            <PriceColumn
                                showModal={showModal}
                                priceDetails={section.footer.priceDetails}
                            />
                        </Col>
                        <Col
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                            }}
                        >
                            <NextButton
                                callToAction={callToAction ?? section.footer.nextText}
                                disabled={disabled}
                                onClick={onClick}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

function NextButton({
    callToAction,
    disabled,
    onClick,
}: {
    callToAction: Localized<string>
    disabled?: boolean
    onClick: () => void
}) {
    const localize = useLocalize()
    const { hover, hoverProps } = useHover()
    return (
        <div
            {...hoverProps}
            onClick={disabled ? undefined : onClick}
            style={{
                userSelect: "none",
                cursor: disabled ? undefined : "pointer",
                paddingTop: 12,
                paddingBottom: 12,
                paddingLeft: 40,
                paddingRight: 40,
                transform: hover ? "scale(1.05) rotate(2deg)" : undefined,
                transition: "transform 0.2s",
                backgroundColor: disabled ? colors.grey5 : colors.pinkFlirt,
                color: "white",
                fontSize: 20,
                borderRadius: 30,
                fontFamily: "GT-Haptik-Black",
                textTransform: "uppercase",
            }}
        >
            {localize(callToAction)}
        </div>
    )
}

function PriceColumn({
    showModal,
    priceDetails,
}: {
    showModal: ContextModal<unknown>
    priceDetails: Localized<string>
}) {
    const { venue } = usePageContext()
    const locale = useCurrentLocale()
    const localize = useLocalize()

    const { guestCount, extras, partyPackage, date, time, room } = usePartyContext()
    const partyExtras: { id: Uuid<"PartyExtra">; quantity: NonNegativeInteger }[] = []

    for (const id in extras) {
        partyExtras.push({ id: id as any, quantity: NonNegativeInteger(extras[id]) })
    }

    if (!venue) return <div>No venue active</div>

    const { data } = usePartyPrice({
        venueId: venue.id,
        numberOfChildren: NonNegativeInteger(guestCount),
        partyExtras,
        partyPackage: partyPackage?.id,
        date: moment(date).format("YYYY-MM-DD"),
        time,
        roomId: room?.id,
    })

    if (!data) return <div>Unable to calculate price</div>
    const currency = data.currency

    return (
        <div>
            <div style={{ fontSize: 22, fontFamily: "GT-Haptik-Black" }}>
                {formatPrice(data.totalWithVat.valueOf())}
            </div>
            <div
                style={{ fontSize: 16, fontWeight: 400, cursor: "pointer" }}
                onClick={() =>
                    showModal((close) => (
                        <PriceDetails
                            close={close}
                            formatPrice={formatPrice}
                            data={data}
                            venue={venue}
                        />
                    ))
                }
            >
                {`+ ${localize(priceDetails)}`}
            </div>
        </div>
    )

    function formatPrice(price: number) {
        return new Intl.NumberFormat(locale.valueOf(), {
            currency,
            style: "currency",
            maximumFractionDigits: 0,
        })
            .format(price)
            .replace(",", " ")
    }
}

function PriceDetails({
    close,
    formatPrice,
    data,
    venue,
}: {
    close: (result: unknown) => void
    formatPrice: (price: number) => string
    data: PartyPrice
    venue: GetPageVenue1Dto
}) {
    const localize = useLocalize()
    const locale = useCurrentLocale()
    const { section, mealOption, date, time, roomName } = usePartyContext()

    return (
        <div
            style={{
                position: "relative",
                width: 655,
                backgroundColor: colors.backgroundGray,
                borderRadius: 8,
                paddingLeft: 48,
                paddingRight: 48,
            }}
        >
            <div
                onClick={() => close(true)}
                style={{
                    position: "absolute",
                    right: 18,
                    top: 18,
                    width: 40,
                    height: 40,
                    backgroundColor: "#E0DDE5",
                    borderRadius: 20,
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <X />
            </div>
            <h2
                style={{
                    paddingTop: 38,
                    marginBottom: 24,
                    fontSize: 30,
                }}
            >
                {localize(section.footer.priceDetails)}
            </h2>
            <Field section={section.summaryPage} prop="dateAndTime">
                <OrderLine
                    primary={
                        capitalizeFirstLetter(
                            new Intl.DateTimeFormat(locale.valueOf(), {
                                weekday: "long",
                                year: "numeric",
                                day: "2-digit",
                                month: "long",
                            }).format(date)
                        ) + (time !== undefined ? ", " + time : "")
                    }
                />
            </Field>
            {roomName && (
                <Field section={section.summaryPage} prop="roomAndLocation">
                    <OrderLine primary={`${roomName} - ${localize(venue.name)}`} />
                </Field>
            )}
            {!data.partyPackage && (
                <Field section={section.summaryPage} prop="numberOfChildren">
                    <OrderLine
                        primary={`${data.numberOfChildren}`}
                        secondary={`x ${formatPrice(data.pricePerChild.valueOf())}`}
                        price={formatPrice(
                            data.numberOfChildren.valueOf() * data.pricePerChild.valueOf()
                        )}
                    />
                </Field>
            )}
            {data.partyPackage && (
                <Field section={section.summaryPage} prop="packageAndAddons">
                    <OrderLine
                        primary={`${localize(data.partyPackage.name)}`}
                        secondary={`${data.numberOfChildren} x ${formatPrice(data.pricePerChild.valueOf())}`}
                        price={formatPrice(data.partyPackageTotal.valueOf())}
                    />
                    {mealOption && <OrderLine primary={localize(mealOption.name)} />}
                    {data?.partyExtras.map((extra, i) => (
                        <OrderLine
                            key={i}
                            primary={localize(extra.name) ?? "??"}
                            secondary={`x ${extra.quantity}`}
                            price={formatPrice(
                                (extra.price.valueOf() +
                                    extra.pricePerChild.valueOf() *
                                        data.numberOfChildren.valueOf()) *
                                    extra.quantity.valueOf()
                            )}
                        />
                    ))}
                </Field>
            )}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    marginTop: 0,
                    paddingTop: 8,
                    paddingBottom: 16,
                    marginBottom: 0,
                }}
            >
                <h2 style={{ flex: 1, fontSize: 30 }}>Total</h2>
                <h2 style={{ fontSize: 30 }}>{formatPrice(data.totalWithVat.valueOf())}</h2>
            </div>
        </div>
    )
}

function OrderLine({
    primary,
    secondary,
    price,
}: {
    primary: string
    secondary?: string
    price?: string
}) {
    return (
        <div
            style={{
                padding: 8,
                paddingLeft: 16,
                paddingRight: 16,
                width: "100%",
                borderRadius: 8,
                backgroundColor: "white",
                marginTop: 8,
                display: "flex",
                flexDirection: "row",
                minHeight: 48,
                alignItems: "center",
            }}
        >
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", flex: 1 }}>
                {primary}
                <div style={{ marginLeft: 8, fontSize: 14, color: colors.grey5 }}> {secondary}</div>
            </div>
            {price}
        </div>
    )
}

function X() {
    return (
        <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_5156_1701)">
                <path
                    d="M9.70625 11.2945C10.0969 11.6852 10.0969 12.318 9.70625 12.7086C9.5125 12.9039 9.25625 13.0008 9 13.0008C8.74375 13.0008 8.48812 12.9031 8.29313 12.7078L5 9.41641L1.70719 12.707C1.51188 12.9039 1.25594 13.0008 1 13.0008C0.744063 13.0008 0.488438 12.9039 0.292969 12.707C-0.0976562 12.3164 -0.0976562 11.6836 0.292969 11.293L3.58672 7.99922L0.292969 4.70703C-0.0976562 4.31641 -0.0976562 3.68359 0.292969 3.29297C0.683594 2.90234 1.31641 2.90234 1.70703 3.29297L5 6.58828L8.29375 3.29453C8.68437 2.90391 9.31719 2.90391 9.70781 3.29453C10.0984 3.68516 10.0984 4.31797 9.70781 4.70859L6.41406 8.00234L9.70625 11.2945Z"
                    fill="#2A134E"
                />
            </g>
            <defs>
                <clipPath id="clip0_5156_1701">
                    <rect width="10" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
