import { GetType, SubstituteAndDiscriminate } from "../../reactor/ReflectionInfo"
import { Type, GetTypeProps, IsArrayType, GetTypeAlias } from "../../reactor/Types/Type"
import { EditableResource } from "./EditableContext"

type Parent = { name: string; obj: any; type: Type; putType?: Type }

export function GetParent(resources: EditableResource[], obj: any): Parent | undefined {
    function search(name: string | number, type: Type, n: any, putType?: Type): any {
        type = SubstituteAndDiscriminate(n, type)

        if (typeof name === "number") {
            const titleProp = GetTypeProps(type).find((p) => p.tags?.title)
            if (titleProp) {
                const title = n[titleProp.name]
                if (typeof title === "string") {
                    name = title
                }
            }
        }
        if (typeof name === "number") {
            name = GetTypeAlias(type) ?? name.toString()
        }

        if (n instanceof Array) {
            if (!IsArrayType(type)) {
                return
            }

            for (let i = 0; i < n.length; i++) {
                if (n[i] === obj) return { name, obj: n, type, putType }
                const parent = search(i, type.array, n[i])
                if (parent) return parent
            }
        } else if (typeof n === "object") {
            const props = GetTypeProps(type)
            for (const key in n) {
                if (n[key] === obj) return { name, obj: n, type, putType }
                const prop = props.find((p) => p.name === key)
                if (!prop) continue
                const parent = search(prop.name, prop.type, n[key])
                if (parent) return parent
            }
        }
    }
    for (const resource of resources) {
        const result = search(
            resource.endpoint,
            GetType(resource.dtoName),
            resource.data,
            GetType(resource.putDtoName)
        )
        if (result) return result
    }
}

export function GetAncestors(resources: EditableResource[], obj: any): Parent[] {
    const ancestors: Parent[] = []
    let current = obj
    while (current) {
        const res = GetParent(resources, current)
        if (res) ancestors.unshift(res)
        current = res?.obj
    }
    return ancestors
}
