import React, { useCallback, useState } from "react"
import { Section } from "../../../../packages/editing/Section"
import { useJobs, Job, postJobApplication } from "../client"
import { Localized } from "../../../../packages/localization/Localized"
import { EditableText } from "../../../../packages/editing/EditableText"
import { ColorStyles } from "../../../../packages/ui"
import { Collapse } from "react-bootstrap"
import { EditableObject } from "../../../../packages/editing/EditableObject"
import { CallToAction } from "./TextAndCallToAction"
import { Email, Markdown, PhoneNumber, Url } from "../../../../reactor/Types/Primitives"
import { Field, TextField } from "./party-booker/Field"
import { useDropzone } from "react-dropzone"
import { colors } from "../colors"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useCurrentLocale } from "../../../../packages/localization/client-side/useLocalize"
import { ClientSideLocalize } from "../../../../packages/localization/client-side/Dictionary"
import { Image, ImageToUrl } from "../../../../reactor/Types/File"

Section(Jobs)
function Jobs(section: {
    /**
     * @default '{"en": "Available positions", "no": "Ledige stillinger"}'
     */
    availablePositions: Localized<string>

    /**
     * @default '{"en": "Apply for position", "no": "Søk på stillingen"}'
     */
    applyForPosition: Localized<string>
}) {
    const { data } = useJobs()

    return (
        <div className="container" style={{ marginTop: 64, marginBottom: 64, maxWidth: 600 }}>
            <h2>
                <EditableText obj={section} prop="availablePositions" />
            </h2>
            {data?.map((job) => <JobRow key={job.id.valueOf()} job={job} section={section} />)}
        </div>
    )
}

function JobRow({ job, section }: { job: Job; section: { applyForPosition: Localized<string> } }) {
    const locale = useCurrentLocale()
    const [expanded, setExpanded] = useState(false)
    return (
        <EditableObject obj={job} typeName="Job" actions={{}}>
            <div style={{ marginBottom: 8 }}>
                <div
                    onClick={() => setExpanded(!expanded)}
                    className="black"
                    style={{
                        fontSize: 22,
                        width: "100%",
                        borderBottom: `1px solid ${ColorStyles.gray[300]}`,
                        paddingBottom: 8,
                        marginBottom: 8,
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                    key={job.id.valueOf()}
                >
                    <EditableText style={{ flex: 1 }} obj={job} prop="title" />
                    <Chevron direction={expanded ? "up" : "down"} />
                </div>
                <Collapse in={expanded}>
                    <div style={{ marginBottom: 128 }}>
                        <EditableText
                            isMarkdown={true}
                            obj={job}
                            prop="description"
                            style={{ marginBottom: 64, marginTop: 16 }}
                        />
                        <Link to={`/${locale}/job-details/${job.id}`}>
                            <CallToAction section={section} prop="applyForPosition" />
                        </Link>
                    </div>
                </Collapse>
            </div>
        </EditableObject>
    )
}

function Chevron({ direction }: { direction: "up" | "down" }) {
    return (
        <svg
            width="20"
            height="12"
            viewBox="0 0 20 12"
            fill="none"
            style={{
                transform: `rotate(${direction === "up" ? 180 : 0}deg)`,
                transition: "transform 0.2s",
            }}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.99998 11.6875C10.3655 11.6875 10.7312 11.5471 11.0098 11.2664L19.5812 2.64136C20.1393 2.07983 20.1393 1.17017 19.5812 0.608643C19.0232 0.0471191 18.1192 0.0471191 17.5611 0.608643L9.99998 8.21953L2.43748 0.609765C1.87944 0.0482419 0.975428 0.0482419 0.417391 0.609765C-0.140644 1.17129 -0.140644 2.08096 0.417391 2.64248L8.98882 11.2675C9.26784 11.5482 9.63391 11.6875 9.99998 11.6875Z"
                fill="#2A134E"
            />
        </svg>
    )
}

function useJob() {
    const { jobId } = useParams()
    const { data: jobs } = useJobs()
    const job = jobs?.find((j) => j.id.valueOf() === jobId)
    return { job, jobs, jobId }
}

Section(JobApplicationForm)
function JobApplicationForm(section: {
    applicationFormSticker?: Image
    filesSticker?: Image

    /**
     * @default '{"en": "Application form", "no": "Søknadsskjema"}'
     */
    applicationForm: Localized<string>
    /**
     * @default '{"en": "First name", "no": "Fornavn"}'
     */
    firstName: Localized<string>
    /**
     * @default '{"en": "Last name", "no": "Etternavn"}'
     */
    lastName: Localized<string>
    /**
     * @default '{"en": "Email", "no": "E-post"}'
     */
    email: Localized<string>
    /**
     * @default '{"en": "Phone number", "no": "Telefonnummer"}'
     */
    phoneNumber: Localized<PhoneNumber>

    /**
     * @default '{"en": "LinkedIn URL", "no": "LinkedIN-URL"}'
     */
    linkedInUrl: Localized<string>

    /**
     * @default '{"en": "Message", "no": "Melding"}'
     */
    message: Localized<string>

    /**
     * @default '{"en": "Files", "no": "Filer"}'
     */
    files: Localized<string>

    /**
     * @default '{"en": "Please upload a CV and cover letter", "no": "Vennligst last opp CV og søknadsbrev"}'
     */
    filesToUpload: Localized<Markdown>

    /**
     * @default '{"en": "No files attached yet", "no": "Ingen filer er vedlagt ennå"}'
     */
    noFilesAttachedYet: Localized<string>

    /**
     * @default '{"en": "You can also drag-and-drop files here to upload.", "no": "Du kan også dra og slippe filer her for å laste opp."}'
     */
    youCanAlsoDragAndDrop: Localized<string>

    /**
     * @default '{"en": "Send application", "no": "Send søknad"}'
     */
    sendApplication: Localized<string>

    /**
     * @default '{"en": "FUNPLAYS.COM" }'
     */
    goBackToFunplays: Localized<string>

    /**
     * @default '{"en": "Thank you for your application!", "no": "Takk! Din søknad er mottatt"}'
     */
    thankYouForYourApplication: Localized<string>

    /**
     * @default '{"en": "We will get back to you as soon as possible.", "no": "Vi vil komme tilbake til deg så snart som mulig."}'
     */
    whatHappensNow: Localized<Markdown>
}) {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [linkedInUrl, setLinkedInUrl] = useState("")
    const [message, setMessage] = useState("")
    const [status, setStatus] = useState<"draft" | "sending" | "sent">("draft")
    const { jobId } = useJob()

    const [files, setFiles] = useState<File[]>([])

    const canSend =
        status === "draft" &&
        firstName &&
        lastName &&
        email &&
        phoneNumber &&
        message &&
        files.length > 0

    async function sendApplication() {
        if (status !== "draft") {
            alert("Already sent!")
            return
        }
        if (!jobId) alert("Job ID is missing")

        const formData = new FormData()
        formData.append("firstName", firstName)
        formData.append("lastName", lastName)
        formData.append("email", email)
        formData.append("phoneNumber", phoneNumber)
        formData.append("linkedInUrl", linkedInUrl)
        formData.append("message", message)
        for (const file of files) {
            formData.append("files", file)
        }
        try {
            setStatus("sending")
            await postJobApplication(
                jobId as any,
                {
                    nonce: "apply-" + Math.random(),
                    firstName,
                    lastName,
                    email: Email(email),
                    phone: PhoneNumber(phoneNumber),
                    linkedinLink: Url(linkedInUrl),
                    message,
                },
                files
            )
            setStatus("sent")
        } catch (e: any) {
            setStatus("draft")
            if ("detail" in e) alert(e.detail)
            else if ("message" in e) alert(e.message)
            else alert(JSON.stringify(e))
        }
    }

    function backToFunplaysDotCom() {
        window.location.href = "https://funplays.com"
    }

    function validateFile(file: File) {
        // Accept PDF, word, excel, powerpoint, images
        if (
            ![
                "application/pdf",
                "application/msword",
                "application/vnd.ms-excel",
                "application/vnd.ms-powerpoint",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                "text/plain",
                "image/png",
                "image/jpeg",
            ].includes(file.type)
        ) {
            alert(`File type ${file.type} is not supported`)
            return false
        }

        // Reject files larger than 5MB
        if (file.size > 5 * 1024 * 1024) {
            alert(`File is too large (${(file.size / 1024 / 1024).toFixed(2)}MB), max is 5MB`)
            return false
        }

        return true
    }

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            for (const file of acceptedFiles) {
                if (!validateFile(file)) return
            }

            setFiles([...files, ...acceptedFiles])
        },
        [files]
    )
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true })

    if (!jobId) {
        return <div>Application form can only be used on job page</div>
    }

    if (status === "sent") {
        return (
            <div className="container" style={{ maxWidth: 600, marginTop: 48, marginBottom: 48 }}>
                <h2>
                    <EditableText obj={section} prop="thankYouForYourApplication" />
                </h2>
                <EditableText obj={section} prop="whatHappensNow" style={{ marginBottom: 64 }} />
                <CallToAction
                    section={section}
                    prop="goBackToFunplays"
                    onClick={backToFunplaysDotCom}
                />
            </div>
        )
    }

    return (
        <div
            className="container"
            style={{ marginBottom: 128, marginTop: 48, maxWidth: 600, position: "relative" }}
        >
            <h3>
                <EditableText
                    obj={section}
                    prop="applicationForm"
                    style={{ display: "inline-block" }}
                />
            </h3>
            {section.applicationFormSticker && (
                <img
                    src={ImageToUrl(section.applicationFormSticker)}
                    style={{ maxWidth: 64, position: "absolute", right: 20, top: 20 }}
                />
            )}

            <TextField
                section={section}
                prop="firstName"
                value={firstName}
                valueChanged={setFirstName}
            />
            <TextField
                section={section}
                prop="lastName"
                value={lastName}
                valueChanged={setLastName}
            />
            <TextField section={section} prop="email" value={email} valueChanged={setEmail} />
            <TextField
                section={section}
                prop="phoneNumber"
                value={phoneNumber}
                valueChanged={setPhoneNumber}
            />
            <TextField
                section={section}
                prop="linkedInUrl"
                value={linkedInUrl}
                valueChanged={setLinkedInUrl}
            />
            <Field section={section} prop="message">
                <textarea
                    style={{
                        marginTop: 8,
                        marginBottom: 8,
                        border: "none",
                        borderRadius: 8,
                        height: 120,
                        padding: 16,
                        width: "100%",
                    }}
                    id="name"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </Field>
            <div style={{ position: "relative" }}>
                <h3>
                    <EditableText obj={section} prop="files" />
                </h3>
                {section.filesSticker && (
                    <img
                        src={ImageToUrl(section.filesSticker)}
                        style={{ maxWidth: 65, position: "absolute", right: 20, top: -10 }}
                    />
                )}
            </div>

            <EditableText obj={section} prop="filesToUpload" isMarkdown={true} />
            <div
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                {...(getRootProps() as any)}
                style={{
                    minHeight: 60,
                    maxHeight: 400,
                    padding: 16,
                    marginBottom: 32,
                    backgroundColor: ColorStyles.gray[50],
                }}
            >
                {files.map((file, i) => (
                    <div
                        style={{
                            padding: 8,
                            backgroundColor: "white",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <i className="fas fa-file" style={{ marginRight: 16, marginLeft: 16 }} />
                        <div style={{ flex: 1 }}>{file.name}</div>
                        <i
                            onClick={() => setFiles(files.filter((_, j) => i !== j))}
                            className="fas fa-trash"
                            style={{
                                marginRight: 16,
                                color: colors.pinkFlirt,
                                cursor: "pointer",
                            }}
                        />
                    </div>
                ))}
                {files.length === 0 ? (
                    <EditableText
                        obj={section}
                        prop="noFilesAttachedYet"
                        style={{
                            color: ColorStyles.gray[500],
                        }}
                    />
                ) : undefined}
                {isDragActive ? (
                    "Release file here to attach"
                ) : (
                    <div>
                        <input
                            type="file"
                            style={{ marginTop: "1rem", marginBottom: "1rem" }}
                            onChange={(e) => {
                                const file = e.target.files && e.target.files[0]
                                if (!file || !validateFile(file)) return

                                setFiles([...files, file])
                                e.target.value = ""
                            }}
                        />
                        <EditableText obj={section} prop="youCanAlsoDragAndDrop" />
                    </div>
                )}
                <input {...getInputProps()} />
            </div>
            <CallToAction
                section={section}
                prop="sendApplication"
                disabled={!canSend}
                onClick={sendApplication}
            />
        </div>
    )
}

Section(JobDescription)
function JobDescription(section: { defaultHeaderSticker?: Image }) {
    const { job } = useJob()
    if (!job) return <></>

    return (
        <div
            style={{
                backgroundColor: colors.grey2,
                paddingTop: 64,
                paddingBottom: 64,
            }}
        >
            <div className="container" style={{ maxWidth: 600 }}>
                {section.defaultHeaderSticker && (
                    <img
                        src={ImageToUrl(section.defaultHeaderSticker, { width: 120 })}
                        style={{ maxWidth: 120 }}
                    />
                )}
                <h2>{ClientSideLocalize(job.title)}</h2>
                <div>
                    <EditableText obj={job} prop="description" isMarkdown={true} />
                </div>
            </div>
        </div>
    )
}

Section(JobDetails)
function JobDetails(section: { callToAction: Localized<string> }) {
    const { job, jobId } = useJob()
    const navigate = useNavigate()
    const locale = useCurrentLocale()
    if (!job) return <></>
    return (
        <div className="container" style={{ maxWidth: 600, marginTop: 48, marginBottom: 48 }}>
            <EditableText obj={job} prop="details" isMarkdown={true} style={{ marginBottom: 48 }} />
            <CallToAction
                section={section}
                prop="callToAction"
                onClick={() => navigate(`/${locale}/job-application/${jobId}`)}
            />
        </div>
    )
}
