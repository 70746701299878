import { useEffect, useState } from "react"
import { IsNode } from "../AssertNode"

/** A hook similar to `useState` that is persisted in local storage.
 *
 *  Responds to changes globally in the app, if multiple components use the same
 *  `settingName`.
 *
 *  Note that in SSR mode, this hook will always return the default value.
 *  Consider early-outing in SSR mode instead of relying on this behavior to avoid
 *  hydration mismatches.
 */
export function usePreference<T>(
    settingName: string,
    defaultValue: T,
    /**
     * The storage to use. Defaults to `localStorage`.
     */
    storageClass?: typeof localStorage | typeof sessionStorage
): [value: T, setValue: (value: T) => void] {
    if (IsNode()) {
        return useState(defaultValue)
    }

    const storage = storageClass ?? localStorage
    const key = `user-preference[${settingName}]`
    const item = storage.getItem(key)
    let value = defaultValue
    if (typeof item === "string") {
        try {
            value = JSON.parse(item)
        } catch (e) {
            // Invalid JSON, ignore and use default value
        }
    }
    const [version, setVersion] = useState({}) // Used to invalidate

    if (JSON.stringify(value) === JSON.stringify(defaultValue)) {
        storage.removeItem(key)
    }

    function setValue(v: T) {
        if (JSON.stringify(v) !== storage.getItem(key)) {
            if (JSON.stringify(v) === JSON.stringify(defaultValue)) {
                storage.removeItem(key)
            } else {
                storage.setItem(key, JSON.stringify(v))
            }
            setVersion({})
        }
    }

    useEffect(() => {
        function onStorage(e: StorageEvent) {
            if (e.key === key) {
                if (e.newValue !== JSON.stringify(value)) {
                    setVersion({})
                }
            }
        }
        window.addEventListener("storage", onStorage)
        return () => window.removeEventListener("storage", onStorage)
    }, [key, value])

    return [value, setValue]
}

/**
 * A persisted state for the current session.
 *
 * The data will be cleared when the browser tab is closed.
 *
 * This is identical to `usePreference` with `sessionStorage` as the storage class.
 *
 */
export function useSessionState<T>(key: string, defaultValue: T) {
    return usePreference<T>(key, defaultValue, IsNode() ? undefined : sessionStorage)
}
