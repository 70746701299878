export const JagFrame2x1 = (fill: string) =>
    `data:image/svg+xml;utf8,${encodeURIComponent(`<svg width="1674" height="749" viewBox="0 0 1674 749" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1673.97 0.713867L1147.66 56.0613V0.713867L621.318 56.0613V0.713867L0.647461 65.9758L133.025 407.314L0.647461 748.652L526.957 693.339V748.652L1053.3 693.339V748.652L1673.97 683.425L1541.59 342.087L1673.97 0.713867Z" fill="${fill}"/>
</svg>`)}`

export const JagFrame3x1 = (fill: string) =>
    `data:image/svg+xml;utf8,${encodeURIComponent(`<svg width="2200" height="749" viewBox="0 0 2200 749" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2199.73 0.713867L1673.42 56.0613V0.713867L1147.08 56.0613V0.713867L620.768 56.0613V0.713867L0.0625 65.9758L132.44 407.314L0.0625 748.652L526.406 693.339V748.652L1052.72 693.339V748.652L1579.06 693.339V748.652L2199.73 683.425L2067.35 342.087L2199.73 0.713867Z" fill="${fill}"/>
</svg>`)}`

export const JagFrame4x1 = (fill: string) =>
    `data:image/svg+xml;utf8,${encodeURIComponent(`<svg width="2726" height="749" viewBox="0 0 2726 749" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2725.98 0.713867L2199.67 56.0613V0.713867L1673.32 56.0613V0.713867L1147.01 56.0613V0.713867L620.671 56.0613V0.713867L0 65.9758L132.377 407.314L0 748.652L526.309 693.339V748.652L1052.65 693.339V748.652L1578.96 693.339V748.652L2105.31 693.339V748.652L2725.98 683.425L2593.6 342.087L2725.98 0.713867Z" fill="${fill}"/>
</svg>`)}`

export const JagFrame2x2 = (fill: string) =>
    `data:image/svg+xml;utf8,${encodeURIComponent(`<svg width="1674" height="1432" viewBox="0 0 1674 1432" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1541.21 341.714L1673.58 0.375977L1147.24 55.7234V0.375977L620.929 55.7234V0.375977L0.223633 65.6379L132.601 406.976L0.223633 748.314L132.601 1089.65L0.223633 1431.02L526.568 1375.68V1431.02L1052.88 1375.68V1431.02L1673.58 1365.76L1541.21 1024.42L1673.58 683.087L1541.21 341.714Z" fill="${fill}"/>
</svg>`)}`

export const JagFrame2x3 = (fill: string) =>
    `data:image/svg+xml;utf8,${encodeURIComponent(`<svg width="1674" height="2114" viewBox="0 0 1674 2114" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1541.61 341.376L1673.99 0.0380859L1147.68 55.3507V0.0380859L621.338 55.3507V0.0380859L0.667969 65.3L133.045 406.638L0.667969 747.976L133.045 1089.31L0.667969 1430.65L133.045 1772.02L0.667969 2113.36L526.977 2058.01V2113.36L1053.32 2058.01V2113.36L1673.99 2048.1L1541.61 1706.76L1673.99 1365.42L1541.61 1024.09L1673.99 682.714L1541.61 341.376Z" fill="${fill}"/>
</svg>`)}`

export const JagFrame2x4 = (fill: string) =>
    `data:image/svg+xml;utf8,${encodeURIComponent(`<svg width="1674" height="2796" viewBox="0 0 1674 2796" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1541.02 341.338L1673.4 0L1147.09 55.3126V0L620.747 55.3126V0L0.0771484 65.2619L132.453 406.6L0.0771484 747.938L132.453 1089.28L0.0771484 1430.61L132.453 1771.99L0.0771484 2113.32L132.453 2454.66L0.0771484 2796L526.42 2740.69V2796L1052.73 2740.69V2796L1673.4 2730.77L1541.02 2389.4L1673.4 2048.06L1541.02 1706.72L1673.4 1365.39L1541.02 1024.05L1673.4 682.676L1541.02 341.338Z" fill="${fill}"/>
</svg>`)}`
