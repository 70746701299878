import React from "react"
import { usePartyContext } from "./PartyContext"
import { usePartyExtrasByVenue } from "../../client"
import { colors } from "../../colors"
import { CallToAction } from "../TextAndCallToAction"
import { usePageContext } from "../../Page"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { useNavigate } from "react-router-dom"
import { EditableText } from "../../../../../packages/editing/EditableText"

export function PartyExtrasPage() {
    const { locale, venue } = usePageContext()
    const { section, extras, setExtra, setExtras } = usePartyContext()
    const { data } = usePartyExtrasByVenue(venue?.id ?? null)
    const localize = useLocalize()
    const navigate = useNavigate()

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflowX: "clip",
                paddingBottom: 48,
            }}
        >
            <div
                style={{
                    width: "100%",
                    maxWidth: 768,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <h1>
                    <EditableText
                        obj={section}
                        prop="partyExtras"
                        isLocalized={true}
                        isMarkdown={true}
                        defaultText="Party Extras"
                    />
                </h1>
                <div>
                    <EditableText
                        obj={section}
                        prop="partyExtrasSubtext"
                        isLocalized={true}
                        isMarkdown={true}
                        defaultText="Add some extra flavour to your party"
                    />
                </div>

                <div style={{ marginTop: 32, marginBottom: 48, width: "100%" }}>
                    {data?.partyExtras.map((extra, i) => {
                        return (
                            <div
                                style={{
                                    borderTop: i > 0 ? `1px solid ${colors.grey2}` : "none",
                                    padding: 16,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <div style={{ flex: 1 }}>
                                    <div>
                                        <span className="bold">{localize(extra.name)}</span>
                                        <span style={{ marginLeft: 8, fontSize: 12 }}>
                                            {extra.allergens
                                                .map((a) => localize(a) ?? "ALLERGEN".toUpperCase())
                                                .join(", ")}
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div>
                                            {extra.price
                                                ? new Intl.NumberFormat(locale.valueOf(), {
                                                      style: "currency",
                                                      maximumFractionDigits: 0,
                                                      currency: extra.currency,
                                                  }).format(extra.price.valueOf())
                                                : ""}
                                            {extra.price && extra.pricePerChild ? " + " : ""}

                                            {extra.pricePerChild
                                                ? new Intl.NumberFormat(locale.valueOf(), {
                                                      style: "currency",
                                                      maximumFractionDigits: 0,
                                                      currency: extra.currency,
                                                  }).format(extra.pricePerChild.valueOf()) +
                                                  " " +
                                                  localize(section.perChildText)
                                                : ""}
                                        </div>

                                        {extra.description ? (
                                            <>
                                                <div
                                                    style={{
                                                        marginLeft: 12,
                                                        paddingLeft: 12,
                                                        marginTop: 3,
                                                        height: 15,
                                                        borderLeft: `1px solid ${colors.darkPurple}`,
                                                    }}
                                                />
                                                <div style={{ marginRight: 12 }}>
                                                    {localize(extra.description)}
                                                </div>
                                            </>
                                        ) : undefined}
                                    </div>
                                </div>
                                <NumberPicker
                                    value={extras ? extras[extra.id.valueOf()] ?? 0 : 0}
                                    setValue={(v) => setExtra(extra.id, v)}
                                    minValue={0}
                                    maxValue={extra.maxPerBooking?.valueOf() ?? 100}
                                />
                            </div>
                        )
                    })}
                </div>
                <CallToAction
                    onClick={() => {
                        if (!extras) setExtras({})
                        navigate("../contact-info")
                    }}
                    section={section}
                    prop="extrasPageCallToAction"
                />
            </div>
        </div>
    )
}

function NumberPicker({
    value,
    setValue,
    minValue,
    maxValue,
}: {
    value: number
    setValue: (value: number) => void
    minValue: number
    maxValue: number
}) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: "white",
                borderRadius: 8,
                fontSize: 24,
            }}
        >
            <div
                onClick={() => {
                    if (value > minValue) setValue(value - 1)
                }}
                style={{
                    userSelect: "none",
                    cursor: "pointer",
                    width: 40,
                    height: 40,
                    borderRadius: 8,
                    backgroundColor: value === minValue ? colors.grey2 : colors.darkPurple,
                    color: value === minValue ? undefined : "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                -
            </div>
            <div style={{ width: 64, textAlign: "center" }}>{value}</div>
            <div
                onClick={() => {
                    if (value < maxValue) setValue(value + 1)
                }}
                style={{
                    userSelect: "none",
                    cursor: "pointer",
                    width: 40,
                    height: 40,
                    borderRadius: 8,
                    backgroundColor: value === maxValue ? colors.grey2 : colors.darkPurple,
                    color: value === maxValue ? undefined : "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                +
            </div>
        </div>
    )
}
