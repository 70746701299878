import React, { createContext, useContext, useEffect, useRef } from "react"
import { useParams } from "react-router-dom"
import { EditableSections } from "../../../packages/editing/EditableSections"
import { LocaleKey } from "../../../packages/localization/Locale"
import { useAlternateLinks } from "../../../packages/ssr/useAlternateLinks"
import { useCanonicalLink } from "../../../packages/ssr/useCanonicalLink"
import { usePageTitle } from "../../../packages/ssr/usePageTitle"
import { StructuredData } from "../../../packages/structured-data"
import { GetPageDto, usePage } from "./client"
import { ErrorScreen } from "./ErrorScreen"
import { useLocalize } from "../../../packages/localization/client-side/useLocalize"
import { CurrentLocaleContext } from "../../../packages/localization/client-side/CurrentLocaleContext"

export function PageView(props: { locale?: LocaleKey; slug?: string }) {
    const { venue, slug = props.slug } = useParams()
    const { data, error } = usePage(props.locale, venue, slug)
    const { locale: currentLocale, setLocale } = useContext(CurrentLocaleContext)
    const firstRender = useRef(true)
    const localize = useLocalize()

    usePageTitle((data && localize(data?.page.title, data?.locale)) || "Funplays")
    useAlternateLinks(data && data.alternates)
    useCanonicalLink(data && data.canonical)

    useEffect(() => {
        if (data?.locale && setLocale) {
            setLocale(data.locale)
            firstRender.current = false
        }
    }, [data?.locale])

    if (error) {
        return <ErrorScreen error={error} locale={(props.locale ?? data?.locale) as any} />
    }

    if (!data) return <></>

    return (
        <>
            <StructuredData.Organization
                name="Funplays"
                url="https://www.funplays.com"
                logo="https://funplays.com/static/funplays-small.png"
            />
            <StructuredData.WebSite
                name="Funplays"
                url="https://www.funplays.com"
                alternateName="FP"
            />
            <CurrentLocaleContext.Provider
                value={{
                    get locale() {
                        // Since the locale is set asynchronously based on what the
                        // page prefers, we need to wait for the first render before
                        // we can return the locale from the context
                        if (firstRender.current) return data.locale
                        return currentLocale
                    },
                    setLocale,
                }}
            >
                <PageContext.Provider value={data}>
                    <EditableSections sections={data.page.sections} />
                </PageContext.Provider>
            </CurrentLocaleContext.Provider>
        </>
    )
}

export const PageContext = createContext<GetPageDto | undefined>(undefined)
export function usePageContext() {
    const pc = React.useContext(PageContext)
    if (!pc) throw new Error("No page context!")
    return pc
}
