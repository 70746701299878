import { type SerializedStyles, css } from "@emotion/react"
import { type CSSInterpolation } from "@emotion/serialize"

/**
 * Each breakpoint is from the property value and up to the next, e.g. xs
 * screen is from 0-480px, and xl screen is from 1200 and up.
 */
const screenSizes = {
    xs: 0,
    sm: 480,
    md: 720,
    lg: 1024,
    xl: 1200,
}

type ScreenSize = keyof typeof screenSizes
type LimitType = "min" | "max"

/**
 * Helper function that returns a media query for a given screen size and limit type.
 *
 * @param limitType The type of limit to apply
 * @param screenSize The screen size to apply the styles to
 * @returns A media query string
 */
export function mediaQuery(limitType: LimitType, screenSize: ScreenSize): string {
    switch (limitType) {
        case "min":
            return `@media (min-width: ${screenSizes[screenSize]}px)`
        case "max":
            const sizesKeysArr = Object.keys(screenSizes) as ScreenSize[]
            const sizeIndex = sizesKeysArr.indexOf(screenSize)

            if (sizeIndex + 1 === sizesKeysArr.length) {
                return "@media"
            }

            const nextSize = sizesKeysArr[sizeIndex + 1]

            return `@media (max-width: ${screenSizes[nextSize] - 1}px)`
        default:
            return ""
    }
}

/**
 * Helper function that returns a media query and styles for a given screen size.
 *
 * @param limitType The type of limit to apply
 * @param screenSize The screen size to apply the styles to
 * @param styles The styles to apply to the given screen size limit
 * @returns `SerializedStyles` to be used in an Emotion `css` prop
 */
export function responsiveCss(
    limitType: LimitType,
    screenSize: ScreenSize,
    styles: CSSInterpolation
): SerializedStyles {
    return css({ [mediaQuery(limitType, screenSize)]: styles })
}

const baseReset = css({
    margin: 0,
    padding: 0,
    border: 0,
    fontSize: "100%",
    font: "inherit",
    verticalAlign: "baseline",
})

/**
 * Helper function that returns reset styles for a given HTML element, beyond the base reset
 * styles, but also removes default styles.
 *
 * @param element The HTML element to return reset styles for
 * @returns `SerializedStyles` to be used in an Emotion `css` prop
 */
export function resetStyles(element: keyof JSX.IntrinsicElements): SerializedStyles {
    switch (element) {
        case "button":
            return css(
                baseReset,
                css({
                    background: "none",
                    border: "none",
                    padding: 0,
                    font: "inherit",
                    cursor: "pointer",
                    outline: "inherit",
                })
            )
        case "ol":
        case "ul":
            return css(baseReset, css({ listStyle: "none" }))
        default:
            return baseReset
    }
}
