import React, { useEffect } from "react"
import { useHover } from "../../../../packages/hooks/useHover"
import { Localized } from "../../../../packages/localization/Localized"
import { FileToUrl, ImageToUrl } from "../../../../reactor/Types/File"
import { usePartyRoomsByVenueShowcase } from "../client"
import { colors } from "../colors"
import { usePageContext } from "../Page"
import { SlideAnimation } from "./Animations"
import { PleaseChooseAVenue } from "./PleaseChooseAVenue"
import { Section } from "../../../../packages/editing/Section"

Section(ExplorePartyRooms)
function ExplorePartyRooms(section: {
    marginTop?: number
    marginBottom?: number
    pleaseChooseAVenue?: Localized<string>
}) {
    const { venue } = usePageContext()

    const [roomIndex, setRoomIndex] = React.useState(0)
    const { data: rooms } = usePartyRoomsByVenueShowcase(venue?.id || null)
    const { hover: leftHover, hoverProps: leftHoverProps } = useHover()
    const { hover: rightHover, hoverProps: rightHoverProps } = useHover()
    const [manual, setManual] = React.useState(false)
    useEffect(() => {
        if (manual) return
        const newIndex = (roomIndex + 1) % (rooms?.rooms.length ?? 0)
        const timeout = setTimeout(
            () => {
                setRoomIndex(newIndex)
            },
            rooms?.rooms[roomIndex].video ? 10000 : 3000
        )

        return () => clearTimeout(timeout)
    }, [roomIndex, rooms?.rooms.length, manual])

    if (!venue) return <PleaseChooseAVenue section={section} />

    return (
        <div
            style={{
                position: "relative",
                marginTop: section.marginTop,
                marginBottom: section.marginBottom,
            }}
        >
            <div
                {...leftHoverProps}
                style={{
                    position: "absolute",
                    left: 0,
                    width: "30%",
                    top: 0,
                    bottom: 0,
                    zIndex: 10,
                    cursor: "pointer",
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontFamily: "GT-Haptik-Black-Rotalic",
                }}
                onClick={() => {
                    setManual(true)
                    setRoomIndex((i) => (i === 0 ? (rooms?.rooms.length || 0) - 1 : i - 1))
                }}
            >
                <div
                    style={{
                        pointerEvents: "none",
                        fontSize: 64,
                        margin: 32,
                        opacity: leftHover ? 1 : 0,
                        transform: leftHover
                            ? "translateX(0) rotate(0deg)"
                            : "translateX(-120px) rotate(-12deg)",
                        transition: "all 0.2s ease",
                    }}
                >
                    &lt;&lt;
                </div>
            </div>

            <div
                {...rightHoverProps}
                style={{
                    position: "absolute",
                    right: 0,
                    width: "30%",
                    textAlign: "right",
                    top: 0,
                    bottom: 0,
                    zIndex: 10,
                    cursor: "pointer",
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontFamily: "GT-Haptik-Black-Rotalic",
                }}
                onClick={() => {
                    setManual(true)
                    setRoomIndex((i) => (i + 1) % (rooms?.rooms.length || 0))
                }}
            >
                <div
                    style={{
                        pointerEvents: "none",
                        fontSize: 64,
                        margin: 32,
                        opacity: rightHover ? 1 : 0,
                        transform: rightHover
                            ? "translateX(0) rotate(0deg)"
                            : "translateX(-120px) rotate(-12deg)",
                        transition: "all 0.2s ease",
                    }}
                >
                    &gt;&gt;
                </div>
            </div>

            <div
                style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                }}
            >
                <div
                    style={{
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderRadius: 32,
                        marginBottom: -18,
                        padding: 8,
                        paddingLeft: 16,
                        paddingRight: 16,
                        boxShadow: "0px 0px 50px rgba(97, 80, 124, 0.08)",
                    }}
                >
                    {rooms?.rooms.map((room, i) => (
                        <div
                            onClick={() => {
                                setRoomIndex(i)
                                setManual(true)
                            }}
                            style={{
                                cursor: "pointer",
                                width: 10,
                                height: 10,
                                margin: 6,
                                borderRadius: 10,
                                backgroundColor: i === roomIndex ? colors.purple : colors.grey2,
                            }}
                        />
                    ))}
                    <div
                        onClick={() => {
                            setRoomIndex((i) => (i + 1) % (rooms?.rooms.length || 0))
                            setManual(false)
                        }}
                        style={{
                            cursor: "pointer",
                            fontSize: 12,
                            marginLeft: 12,
                            marginTop: 2,
                            backgroundColor: colors.grey3,
                            position: "relative",
                            width: 20,
                            height: 20,
                            borderRadius: 16,
                            color: "white",
                            display: manual ? "block" : "none",
                        }}
                    >
                        <div style={{ position: "absolute", top: 1, left: 6 }}>▶</div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    backgroundColor: colors.darkPurple,
                    height: 650,
                    maxHeight: "80%",

                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "clip",
                    perspective: 1000,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyItems: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 2,
                        color: "white",
                    }}
                >
                    <h1
                        style={{
                            color: "white",
                            textShadow: "0px 1px 4px rgba(0, 0, 0, 0.65)",
                            textAlign: "center",
                            marginBottom: 8,
                        }}
                    >
                        {rooms?.rooms[roomIndex].name}
                    </h1>
                </div>

                {rooms?.rooms.map((room, i) =>
                    room.video ? (
                        <video
                            autoPlay
                            muted
                            loop
                            playsInline
                            style={{
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                objectFit: "cover",
                                ...SlideAnimation(roomIndex, i, rooms.rooms.length),
                            }}
                            src={FileToUrl(room.video as any)}
                        />
                    ) : room.image ? (
                        <img
                            style={{
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                objectFit: "cover",
                                ...SlideAnimation(roomIndex, i, rooms.rooms.length),
                            }}
                            src={ImageToUrl(room.image, { width: 1440 })}
                        />
                    ) : undefined
                )}
            </div>
        </div>
    )
}
