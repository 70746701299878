/** Returns true if the current runtime environment is Node.js.
 *  Returns false if browser or some other non-node environment
 */
export function IsNode() {
    if (typeof process === "object") {
        if (typeof process.versions === "object") {
            if (typeof process.versions.node !== "undefined") {
                return true
            }
        }
    }
    return false
}

export function AssertNode() {
    if (!IsNode()) {
        throw new Error(
            "This code is only meant for a Node.js environment. Did you accidentally import it?"
        )
    }
}
